/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/jsx-fragments */
import React from 'react';
import {
  FormControl,
  FormHelperText,
  useTheme,
  Typography,
} from '@mui/material';
import styled from '@emotion/styled';
import '../../styles/website_fonts.css';
import InfoIcon from '@mui/icons-material/Info';
import IQLabelTooltip from '../../tooltip/IQLabelTooltip/IQLabelTooltip';
import IQFormLabel from '../../input/IQFormLabel/IQFormLabel';

export interface IQFormLayoutProps {
  /**
   * Label for the input field
   */
  labelText: string;
  fontLabelWeight?: string;

  /**
   * Descriptive text displayed as a note between the input and the labelText
   */
  supportText?: string,

  /**
   * Whether to use the standard error text message. Set to false if
   * using a custom error message
   */
  showError?: boolean;

  /**
  * Descriptive text displayed as a note below the input
  */
  subDescription?: string;

  /**
  * Tooltip text, if present a standard ? icon will be used with tooltip functionality.
  */
  tooltipText?: string;

  /**
   * Error message
   */
  errorMessage?: string;

  /**
   * Whether this is full width
   */
  fullWidth?: boolean;

  tooltipPlacement?: 'bottom' | 'right' | 'top' | 'left';

  tooltipArrow?: boolean;

  tooltipPaddingBottom?: number;

  spaceBottomMultiplier?: number;

  /**
   * This will specify the layout that we want to use.
   * This feature right now, only supports the default layout
   */
  variant?: string;

  required?: boolean,
  textId?: string

  children?: any;
}

type StateProps = {
  hasError?: boolean
};

const FormErrorHelper = styled(FormHelperText)(({ theme }) => ({
  color: theme.palette.error.main,
  display: 'flex',
  gap: '5px',
  alignItems: 'center',
}));

const SubDescriptionText = styled(Typography) <StateProps>`
  padding-top: ${({ theme }) => theme.spacing(1)};
  font-family: 'Unify Sans';
  font-size: 12px;
  line-height: 16px;
  color: ${(props) => (props.hasError ? props.theme.palette.error.main : props.theme.palette.text.primary)};
`;

/**
 * Layout wrapper component to be used for most standard inputs
 *
 * See above IQFormLayoutProps for param info
 */
export const IQFormLayout = ({
  textId,
  labelText,
  fontLabelWeight,
  supportText,
  showError = true,
  errorMessage = null,
  subDescription,
  tooltipPlacement = 'bottom',
  tooltipText = null,
  fullWidth = false,
  variant = 'default',
  required = false,
  tooltipArrow = true,
  tooltipPaddingBottom,
  spaceBottomMultiplier,
  children,
}: IQFormLayoutProps) => {
  const theme = useTheme();
  const hasError = (errorMessage != null && errorMessage !== '');

  return (
    <React.Fragment>
      {variant === 'default'
        && (
          <React.Fragment>
            <FormControl fullWidth={fullWidth}>
              {tooltipText && (
              <IQLabelTooltip
                hasError={hasError}
                tooltipText={tooltipText}
                labelText={labelText}
                theme={theme}
                tooltipPlacement={tooltipPlacement}
                required={required}
                arrow={tooltipArrow}
                paddingBottom={tooltipPaddingBottom}
              />
              )}
              {!tooltipText && labelText && (
                <IQFormLabel
                  hasError={hasError}
                  theme={theme}
                  fontLabelWeight={fontLabelWeight}
                  spaceBottomMultiplier={spaceBottomMultiplier}
                  id={textId}
                >
                  {labelText}
                </IQFormLabel>
              )}
              {supportText && (
                <IQFormLabel
                  theme={theme}
                  fontLabelWeight="400"
                  lineHeight="20px"
                >
                  {supportText}
                </IQFormLabel>
              )}
              {children}
              {showError && (
                <FormErrorHelper
                  sx={{
                    marginLeft: 0,
                  }}
                  role="alert"
                >
                  <InfoIcon sx={{ fontSize: '16px' }} />
                  {' '}
                  {errorMessage}
                </FormErrorHelper>
              )}
            </FormControl>
            {
             subDescription
              && <SubDescriptionText hasError={hasError}>{subDescription}</SubDescriptionText>
            }
          </React.Fragment>
        )}
    </React.Fragment>
  );
};

export default IQFormLayout;
