import React, { FC } from 'react';
import styled from '@emotion/styled';
import { Typography, Button } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { ValidationProvider } from '../../providers/ValidationProvider';
import IQButtonLink from '../../buttons/IQButtonLink/IQButtonLink';
import IQFormTextArea from '../../form/IQFormTextArea/IQFormTextArea';
import IQUnexpectedErrorIcon from '../../icons/IQUnexpectedErrorIcon';

export interface IQUnexpectedErrorProps {
  onSubmit: (feedback: string) => void;
  onRedirect: () => void;
}

const ErrorContainer = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: ${({ theme }) => theme.palette.background.default};
`;

const ErrorIconContainer = styled('div')`
  align-self: center;
  margin-bottom: 34px;
`;

const ButtonSubmit = styled(Button)`
  &.Mui-disabled {
    color: ${({ theme }) => (theme.palette.common.white)}
  }
  text-transform: none;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  height: 40px;
  border-radius: 2px;
  font-family: 'Unify Sans';
  margin-top: 24px;
`;

const ButtonBack = styled(IQButtonLink)`
  font-size: 20px;
  line-height: 20px;
  margin-top: 60px;
  font-weight: 600;
`;

const IQUnexpectedError: FC<IQUnexpectedErrorProps> = ({ onSubmit, onRedirect }) => {
  const yupSchema = {
    unexpectedErrorFeedback: yup.string(),
  };

  const schema = {
    yupValidations: yupSchema,
    customValidations: {},
  };
  const methods = useForm({
    mode: 'onSubmit',
    defaultValues: {
      unexpectedErrorFeedback: '',
    },
  });
  const { handleSubmit, watch } = methods;
  const unexpectedErrorFeedback = watch('unexpectedErrorFeedback');
  const submitHandler = handleSubmit(({ unexpectedErrorFeedback }) => onSubmit(unexpectedErrorFeedback));

  return (
    <ErrorContainer>
      <ErrorIconContainer>
        <IQUnexpectedErrorIcon />
      </ErrorIconContainer>
      <Typography
        variant="h3"
        sx={{ color: 'info.dark' }}
      >
        An unexpected error occurred.
      </Typography>
      <Typography
        variant="subtitle1"
        sx={{
          color: 'text.primary', fontSize: '18px', lineHeight: '27px', margin: '18px 0 50px', fontWeight: 400,
        }}
      >
        We apologize for the inconvenience. Our support team has been alerted.
      </Typography>
      <Typography
        variant="subtitle2"
        sx={{
          color: 'text.primary', fontSize: '16px', lineHeight: '24px', marginBottom: '18px', fontWeight: 400,
        }}
      >
        Please describe what tasks were being completed when the error occurred.
        By providing feedback,
        the support team can try to identify the issue and find a solution to the problem.
      </Typography>
      <ValidationProvider schema={schema}>
        <FormProvider {...methods}>
          <form onSubmit={submitHandler}>
            <IQFormTextArea
              id="unexpectedErrorFeedback"
              name="unexpectedErrorFeedback"
              fullWidth
              labelText="Feedback"
              rowCount={5}
              fontLabelWeight="bold"
            />
            <ButtonSubmit
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              disabled={!unexpectedErrorFeedback}
            >
              Submit Feedback
            </ButtonSubmit>
          </form>
        </FormProvider>
      </ValidationProvider>
      <ButtonBack
        onClick={onRedirect}
        startIcon={<ArrowBackIcon />}
      >
        Back to Home
      </ButtonBack>
    </ErrorContainer>
  );
};

export default IQUnexpectedError;
