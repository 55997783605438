import React from 'react';
import { styled } from '@mui/material/styles';
import {
  ToggleButton, ToggleButtonProps,
  Theme,
} from '@mui/material';

const IQImgStyled = styled('img')(() => ({
  display: 'block',
  marginLeft: 'auto',
  marginRight: 'auto',
  marginTop: '20px',
}));

export interface IQBigBoxButtonStyleProps {
  selected?: boolean,
  disabled?: boolean,
  theme?: Theme,
}

const IQInvisibleButton = styled(ToggleButton) <IQBigBoxButtonStyleProps>`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    width: 218px;
    height: 218px;
    text-transform: none;
    font-size: 20px;
    font-weight: 400;
    color:  ${({ theme }) => (theme.palette.primary.main)};
    background-color: ${({ theme }) => (theme.palette.common.white)};
    &:hover {
        background-color: ${({ theme }) => (theme.palette.primary.main)}28 !important
    }
    &:focus-within {
        border: ${(props) => (props.selected ? `3px dashed ${props.theme.palette.primary.main}` : `1px dashed ${props.theme.palette.primary.main}`)};
    }
    &.MuiToggleButtonGroup-grouped:not(:last-of-type), &.MuiToggleButtonGroup-grouped, &.MuiToggleButtonGroup-grouped:not(:first-of-type) {
      border: 1px solid ${(props) => props.theme.palette.primary.main};
      border-radius: 2px;
      margin-right: 16px;
      width: 218px;
      height: 218px;
      border:  ${(props) => (props.selected ? `3px solid ${props.theme.palette.primary.main}` : `1px solid ${props.theme.palette.primary.main}`)};
      border:  ${(props) => props.disabled && props.selected && `3px solid ${props.theme.palette.action.disabledBackground}`};
      border:  ${(props) => props.disabled && !props.selected && `1px solid ${props.theme.palette.action.disabledBackground}`};

      &:focus-within {
          border: ${(props) => (props.selected ? `3px dashed ${props.theme.palette.primary.main}` : `1px dashed ${props.theme.palette.primary.main}`)};
      }

      &.Mui-error {
        border: ${(props) => props.selected && `3px solid ${props.theme.palette.error.main}`};
        border: ${(props) => !props.selected && `1px solid ${props.theme.palette.error.main}`};
        border: ${(props) => !props.selected && `1px solid ${props.theme.palette.error.main}`};
        color:  ${(props) => !props.disabled && `${props.theme.palette.error.main}`};
      }
    }
    &.Mui-selected {
      color:  ${(props) => `${props.theme.palette.primary.main}`};
      background-color: ${({ theme }) => (theme.palette.common.white)};
    }
    color:  ${(props) => props.disabled && `${props.theme.palette.action.disabledBackground}`};
    pointer-events: ${(props) => props.disabled && 'none'};
`;

export interface IQBigBoxButtonProps extends ToggleButtonProps {
  /**
   * Input label
   */
  label?: string
  /**
   * Switch checked value
   */
  image?: string
  /**
   * Switch size
   */
  selected?: boolean,
  disabled?: boolean,
  [rest: string]: any
}

export default function IQBigBoxButton({
  image, label, value, ...rest
} : IQBigBoxButtonProps) {
  return (
    <IQInvisibleButton value={value} {...rest}>
      <IQImgStyled src={image} width="100" height="100" />
      <p>{label}</p>
    </IQInvisibleButton>
  );
}
