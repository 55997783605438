import React from 'react';
import { Button } from '@mui/material';
import styled from '@emotion/styled';
import ImageIcon from '../../icons/IQImageIcon';

export interface IQImageButtonLinkProps {
  /**
   * Disabled state of the component
   */
  disabled?: boolean;
  /**
   *Button text
   */
  buttonText?: string;
  /**
   * On click handler
   */
  onClick?: () => void;
}

const StyledButton = styled(Button)`
  border-radius: 2px;
  & .MuiButton-startIcon {
    position: absolute;
    top: 38px;
    left: 50%;
    transform: translateX(-50%);
    
  }
  svg {
    path {
      fill: ${(props) => (props.theme.palette.primary.main)};
    }
  }
  position: relative;
  max-width: 210px;
  max-height: 144px;
  min-width: 210px;
  min-height: 144px;
  color: ${({ theme }) => (theme.palette.primary.main)};
  font-size: 14px;
  text-transform: none;
  font-weight: 600;
  background-color: ${({ theme }) => (theme.palette.common.white)};
  font-family: 'Unify Sans';
`;

const StyledButtonText = styled('div')`
  position: absolute;
  bottom: 38px;
`;

export const IQImageButtonLink = ({
  disabled = false,
  buttonText = 'Find Stock Images',
  onClick,
  ...props
}: IQImageButtonLinkProps) => (
  <StyledButton
    startIcon={<ImageIcon />}
    variant="outlined"
    disabled={disabled}
    onClick={onClick}
    {...props}
  >
    <StyledButtonText>{buttonText}</StyledButtonText>
  </StyledButton>
);

export default IQImageButtonLink;
