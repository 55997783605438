import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import styled from '@emotion/styled';
import {
  Box, Chip, FormControlLabel, Radio, Typography,
} from '@mui/material';
// Typescript doesn't understand the component prop on typography.
type TopLabelProps = {
  component: React.ElementType
};
const TopLabel = styled(Typography)<TopLabelProps>({
  fontSize: 14,
  lineHeight: '25px',
  letterSpacing: '0px',
  textAlign: 'left',
  marginBottom: 10,
});

const LabelFlag = styled(Chip)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  fontSize: 12,
  marginLeft: 10,
  height: 23,
}));

type RadioWrapperProps = {
  selected: boolean
};
const RadioWrapper = styled(Box)<RadioWrapperProps>`
  padding: ${(props) => props.theme.spacing(1, 1, 2, 3)};
  border: ${(props) => (props.selected ? '2px' : '1px')} solid ${(props) => (props.selected ? props.theme.palette.primary.main : props.theme.palette.text.primary)};
`;

const CustomRadio = styled(Radio)(({ theme }) => ({
  '& svg': {
    [theme.breakpoints.down('md')]: {
      height: '28px',
      width: '28px',
    },
  },
}));

type RadioLabelProps = {
  component: React.ElementType
};
const RadioLabelStyles = styled(Typography)<RadioLabelProps>`
  font-size: 16px;
  font-family: 'Unify Sans';
  line-height: 24px;
  letter-spacing: 0.25px;
  color: ${(props) => props.theme.palette.text.primary};
  margin-left: 5px;
  margin-top: 8px;
`;

/**
 * Represents a radio button with a light grey surrounding box.
 */
export const IQRadioBox = ({
  id,
  radioDisplayValue,
  value,
  label,
  isSelected,
  labelFlagContent,
  theme,
  disabled,
  className,
}: InferProps<typeof IQRadioBox.propTypes>) => {
  const RadioControlLabel = (
    <FormControlLabel
      sx={{
        alignItems: 'flex-start',
      }}
      control={(
        <CustomRadio
          id={id}
          sx={{
            color: isSelected ? theme.palette.primary.main
              : theme.palette.text.primary,
          }}
          value={value}
          disabled={disabled}
        />
)}
      label={<RadioLabelStyles component="div">{radioDisplayValue}</RadioLabelStyles>}
    />
  );

  return (
    <div className={className}>
      <Box>
        {label && (
          <TopLabel component="div">
            {label}
            {labelFlagContent && <LabelFlag label={labelFlagContent} />}
          </TopLabel>
        )}
      </Box>
      <RadioWrapper selected={isSelected}>
        {RadioControlLabel}
      </RadioWrapper>
    </div>
  );
};

export default IQRadioBox;

IQRadioBox.propTypes = {
  /**
   * The value to display next to the radio button itself
   */
  radioDisplayValue: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,

  /**
   * The internal value of radio
   */
  value: PropTypes.string.isRequired,

  /**
   * An optional label to display above the outlined radio box
   */
  label: PropTypes.string,

  /**
   * Whether a radio button is selected
   */
  isSelected: PropTypes.bool,

  /**
   * What content to pass to the chip to show next to he label. If nothing passed
   * chip is not shown
   */
  labelFlagContent: PropTypes.string,

  /**
   * The material Theme from parent
   */
  theme: PropTypes.object.isRequired,

  /**
    * Disabled state of the radio box
    */
  disabled: PropTypes.bool,

  /**
   * Class name to target the wrapper for overrides
   */
  className: PropTypes.string,
};

IQRadioBox.defaulProps = {

};
