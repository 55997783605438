import React, { useEffect } from 'react';
import {
  createStyles, styled, TextField, Theme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useFormContext } from 'react-hook-form';
import { IQTheme } from '../../styles/IQTheme';
import { useValidationContext } from '../../providers/ValidationProvider';
import { IQFormLayout } from '../../form/IQFormLayout/IQFormLayout';
import schemaValidate from '../../services/schemaValidate';

const StyledTextField = styled(TextField)`
  background: ${(props) => props.theme.palette.common.white};

  input {
    max-height: 7px;
  }

  & .MuiOutlinedInput-root {
      border: 1px solid ${(props) => props.theme.palette.text.primary};
      & .MuiOutlinedInput-notchedOutline {
        border: none;
        outline: none;
      };
    };
    & .Mui-focused {
      border: 1px solid ${(props) => props.theme.palette.primary.main};
      & .MuiOutlinedInput-notchedOutline {
        border: none;
      }
    }
    & .Mui-error {
      border: 1px solid ${(props) => props.theme.palette.error.main};
      & .MuiOutlinedInput-notchedOutline {
        border: none;
      }
    }

  /* ensures the increment/decrement arrows always display */
  //TODO implement Firefox and Safari support for this
  input[type=number]::-webkit-inner-spin-button {
    opacity: 1;
  }
  input[type=number]::-webkit-outer-spin-button {
    opacity: 1;
  }

  *, *:focus, *:hover {
    outline: none;
  }

  input {
    &:focus-within {
    outline: none;
    border-width: 1px;
    }
  }
`;
export interface IQInputNumericFieldProps {
  /**
   * Label to be displayed above the input box. default is empty string
   */
  label?: string;
  name: string;
  required?: boolean;
  disabled?: boolean;
  tooltipText?: string;
  tooltipPlacement?: 'bottom' | 'right' | 'top' | 'left';
  tooltilPaddingBottom?: number;
  tooltipArrow?: boolean;
  fullWidth?: boolean;
  theme?: Theme;
  /**
   * Default value to be set initially (defaults to '0')
   */
  defaultValue?: string | number;
  /**
   * If you are using a fieldset and the error is deeply nested, pass that direct route here
   * E.g.
   * Field array phoneNumbers, field name is phoneNumber[0].number.
   * Pass errors.phoneNumbers[0].number
   */
  directErrorReference?: { message: any };
  /**
   * Additional parameters for custom validations (Optional)
   */
  params?: string;

  customValidation?: any;
  /**
   * Whether to allow negative values in the input field
   */
  disallowNegative?: boolean;

  /**
   * Properties for react-hook-forms / yup integration
   */
  [rest: string]: any

  onChange?: (value: number | string) => void;
}

export default function IQInputNumericField({
  label = '',
  required,
  disabled,
  tooltipText,
  tooltipPlacement,
  fullWidth,
  directErrorReference,
  params,
  onChange,
  name,
  defaultValue,
  disallowNegative,
  tooltilPaddingBottom,
  tooltipArrow,
  customValidation,
  theme = IQTheme,
  ...rest
}: IQInputNumericFieldProps) {
  const { schema } = useValidationContext();

  const {
    register,
    formState: { errors },
    getValues,
    setValue,
  } = useFormContext();

  const errorMessage = directErrorReference?.message || errors[name]?.message || '';

  useEffect(() => {
    const value = getValues(name);
    if (value === undefined || value === null || value === '') {
      if (defaultValue || defaultValue === '') {
        setValue(name, defaultValue);
      } else {
        setValue(name, 0);
      }
    }
  }, []);

  return (
    <IQFormLayout
      fullWidth={fullWidth}
      showError={!!errors[name]}
      errorMessage={errorMessage}
      labelText={label + (required ? ' *' : '')}
      tooltipText={tooltipText}
      tooltipPlacement={tooltipPlacement}
      tooltipPaddingBottom={tooltilPaddingBottom}
      tooltipArrow={tooltipArrow}
      fontLabelWeight="600"
    >
      <StyledTextField
        {...register(name, {
          validate: (val) => schemaValidate(val, name, schema, params, customValidation),
        })}
        error={!!errors[name]}
        type="number"
        disabled={disabled}
        fullWidth={fullWidth}
        onKeyDown={(e) => {
          if (e.key === '-' && disallowNegative) {
            e.preventDefault();
          }
        }}
      />
    </IQFormLayout>
  );
}
