import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import IQOutlinedCheckboxInput from '../../input/IQOutlinedCheckbox/IQOutlinedCheckbox';
import { IQStyledCard } from '../../layout/IQStyledCard/IQStyledCard';

/**
 * Component for a card with checkbox group
 *
 * See below prop types for param info
 */
export const IQCheckboxGroupCard = ({
  cardHeaderText,
  cardDescription,
  checkboxArray,
  onChange,
  theme,
}: InferProps<typeof IQCheckboxGroupCard.propTypes>) => (
  <IQStyledCard headerText={cardHeaderText} description={cardDescription} theme={theme}>
    {
        checkboxArray.map(({ labelText, name, disabled }) => (
          <div key={`checkboxCardGroupItem-${name}`}>
            <div style={{ padding: '10px 0' }} />
            <IQOutlinedCheckboxInput
              labelText={labelText}
              name={name}
              disabled={disabled}
              onChange={onChange}
              theme={theme}
            />
          </div>
        ))
      }
  </IQStyledCard>
);

IQCheckboxGroupCard.propTypes = {
  /**
     * Receives the card header
     */
  cardHeaderText: PropTypes.string.isRequired,

  /**
     * Receives the card description
     */
  cardDescription: PropTypes.string,

  /**
     * An array of checkbox object
     * The checkbox object must have the following structure:
     *  {
     *     labelText, // the display value of the checkbox item
     *     name, // the value of the selected checkbox item
     *     disabled, // if the checkbox item is disabled (true) or not (false)
     *  }
     */
  checkboxArray: PropTypes.arrayOf(PropTypes.object).isRequired,

  /**
     * The function that handles radio item change selection
     */
  onChange: PropTypes.func.isRequired,

  /**
     * The return provided from createTheme from parent app
     */
  theme: PropTypes.object,
};

export default IQCheckboxGroupCard;
