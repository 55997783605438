import React from 'react';
import {
  Theme, ToggleButton, ToggleButtonProps, useTheme,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import IQRadioButtonSelected from '../../icons/IQRadioButtonSelected';
import IQRadioButtonUnselected from '../../icons/IQRadioButtonUnselected';
import IQRadioButtonDisabled from '../../icons/IQRadioButtonDisabled';
import { IQLabelTooltip } from '../../tooltip/IQLabelTooltip/IQLabelTooltip';
import IQTooltipLink from '../../icons/IQTooltipLink';

interface IQButtonRadioStyleProps {
  selected?: boolean,
  disabled?: boolean,
  theme?: Theme,
}
const ToggleButtonStyled = styled(ToggleButton) <IQButtonRadioStyleProps>`
  text-transform: none;
  border: none !important; // using !important to override disabled state too
  color: ${(props) => props.theme.palette.text.primary};
  flex: 50%;
  justify-content: flex-start;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.25px;
  padding: 4px 11px;

  rect {
    fill: ${(props) => (props.selected
    ? props.theme.palette.primary.main
    : props.theme.palette.text.primary
  )};
  }

  &.Mui-selected {
    background-color: transparent;
  }

  &.Mui-disabled { // TODO fix disabled style overrides not working
    border: none;

    img {
      opacity: 0.5;
    }
  }
`;

export interface IQButtonRadioProps extends ToggleButtonProps {
  label: string, // text label of the button
  value: string; // internal value used for tracking selection
  field: string; // form field name
  required?: boolean;
  selected?: boolean;
  disabled?: boolean;
  onClick?: (event: React.MouseEvent<HTMLElement>, value :string) => void;
  tooltipText?: string;
  arrow?: boolean;
  [rest: string]: any
}

export default function IQButtonRadio({
  label,
  value,
  selected,
  onClick,
  disabled,
  tooltipText,
  tooltipPlacement = 'right',
  errorMessage = null,
  arrow = true,
  ...rest
}: IQButtonRadioProps) {
  const theme = useTheme();
  const hasError = !!errorMessage;

  return (
    <ToggleButtonStyled
      value={value}
      disabled={disabled}
      selected={selected}
      onClick={onClick}
    >
      {selected
        ? disabled
          ? (<IQRadioButtonDisabled />)
          : (<IQRadioButtonSelected />)
        : (<IQRadioButtonUnselected />)}

      <span style={{ marginLeft: '8px', marginRight: '4px' }}>{label}</span>

      {tooltipText && (
        <IQLabelTooltip
          hasError={hasError}
          tooltipText={tooltipText}
          labelText={null}
          theme={theme}
          tooltipPlacement={tooltipPlacement}
          arrow={arrow}
          Icon={IQTooltipLink}
          required={false}
        />
      )}
    </ToggleButtonStyled>
  );
}
