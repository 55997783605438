import * as React from 'react';
import { useState } from 'react';
import styled from '@emotion/styled';
import CheckIcon from '@mui/icons-material/Check';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { css } from '@emotion/react';

export interface IStep {
  label: string
  url?: string
  status: 'active' | 'incomplete' | 'completed'
  subSteps?: IStep[]
}

export interface IQLeftNavStepperProps {
  leftNavSteps: IStep[]
  defaultOpenStepIndex?: number
  onStepClick?: (index: string) => void;
}

const LeftNav = styled('nav')`
  font-family: ${(props) => props.theme.typography.fontFamily};
`;

const MainSteps = styled('ul')`
  list-style: none;
  padding-left: 0;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    width: 4px;
    height: calc(100% - 60px);
    background: ${(props) => props.theme.palette.primary.main};
    left: -2px;
    top: 0;
    z-index: -1;
    margin-top: 34px;
  }
`;

const SubMainSteps = styled('ul')`
  list-style: none;
  position: relative;
  left: -20px;
  padding: 0;

  li:first-child {
    padding-top: 30px;
  }

  li:last-child {
    padding-bottom: 0;
  }
`;

const Step = styled('li')`
  position: relative;
  padding: 15px 0 15px 20px;

  &.active a{
    &:after {
    content: "";
      background-color: ${(props) => props.theme.palette.primary.main};
      width: 6px;
      height: 6px;
      border-radius: 50%;
      position: absolute;
      top: 61%;
      transform: translateY(-50%);
      left: -23px;
    }
  }
`;

const SubStep = styled('li')`
  padding: 15px 0 15px 20px;

  &.active a{
    &:after {
    content: "";
      background-color: ${(props) => props.theme.palette.primary.main};
      width: 6px;
      height: 6px;
      border-radius: 50%;
      position: absolute;
      top: 61%;
      transform: translateY(-50%);
      left: -23px;
    }
  }
`;

const LinkContainer = styled('span')<Pick<IStep, 'status'>>`
  width: 80%;
  position: relative;
  display: inline-block;

  ${({ status, theme }) => (status === 'active' && css`
    &:after {
      content: "";
      border-bottom: 4px solid ${theme.palette.primary.main};
      width: 100%;
      height: 4px;
      position: absolute;
      bottom: -10px;
      left: 0px;
    }
  `)}
`;

const Link = styled('a') <Pick<IStep, 'status'>>`
  &:hover {
    text-decoration: underline;
      }
  text-decoration: none;
  position: relative;
  color: ${({ status }) => {
    switch (status) {
      case 'completed':
        return (props) => props.theme.palette.text.secondary;
      default:
        return (props) => props.theme.palette.common.black;
    }
  }};
  font-weight: ${({ status }) => {
    switch (status) {
      case 'active':
        return 'bold';
      default:
        return 'initial';
    }
  }};
  font-size: 0.875rem;
  line-height: 1rem;

  ${({ status, theme }) => (status === 'active' && css`
  &:after {
        content: '';
        background-color: ${theme.palette.primary.main};
        width: 6px;
        height: 6px;
        border-radius: 50%;
        position: absolute;
        top: 61%;
        transform: translateY(-50%);
        left: -23px;
      }
  `)}

  &:before {
    content: "";
    position: absolute;
    background-color: ${({ status }) => {
    switch (status) {
      case 'completed':
        return (props) => props.theme.palette.primary.main;
      case 'incomplete':
        return (props) => props.theme.palette.common.white;
      case 'active':
        return (props) => props.theme.palette.common.white;
      default:
        return 'transparent';
    }
  }};
    width: 14px;
    height: 14px;
    left: -30px;
    top: 0px;
    border-radius: 50%;
    border: 3px solid ${(props) => props.theme.palette.primary.main};
  }
`;

const SubLink = styled('a') <Pick<IStep, 'status'>>`
  text-decoration: none;
  position: relative;
  color: ${({ status }) => {
    switch (status) {
      case 'completed':
        return (props) => props.theme.palette.text.secondary;
      default:
        return (props) => props.theme.palette.common.black;
    }
  }};
  font-weight: ${({ status }) => {
    switch (status) {
      case 'active':
        return 'bold';
      default:
        return 'initial';
    }
  }};
  font-size: 12px;
  line-height: 1rem;

  &:hover {
    text-decoration: underline;
      }
  

  ${({ status, theme }) => (status === 'active' && css`
    &:after {
      content: "";
      background-color: ${theme.palette.primary.main};
      width: 4px;
      height: 4px;
      border-radius: 50%;
      position: absolute;
      top: 55%;
      transform: translateY(-50%);
      left: -22px;
    }
  `)}

  &:before {
    content: "";
    position: absolute;
    background-color: ${({ status }) => {
    switch (status) {
      case 'completed':
        return (props) => props.theme.palette.primary.main;
      case 'incomplete':
        return (props) => props.theme.palette.common.white;
      case 'active':
        return (props) => props.theme.palette.common.white;
      default:
        return 'transparent';
    }
  }};
    content: "";
    position: absolute;
    width: 8px;
    height: 8px;
    left: -26px;
    top: 2px;
    border-radius: 50%;
    border: 2px solid ${(props) => props.theme.palette.primary.main};
  }
`;

const ArrowIconStyled = styled(KeyboardArrowDownIcon) <{ isOpen: boolean }>`
  position: absolute;
  color: ${(props) => props.theme.palette.common.black};
  top: 12px;
  right: 0;
  font-size: 1.2rem;
  cursor: pointer;
  transition: 0.3s;
  transform: rotate(${({ isOpen }) => (isOpen ? '180' : '0')}deg);
`;

export default function IQLeftNavStepper({
  leftNavSteps,
  defaultOpenStepIndex,
  onStepClick,
}: IQLeftNavStepperProps) {
  const [openStepIndex, setOpenStepIndex] = useState(defaultOpenStepIndex);
  const toggleStep = (index: number) => {
    const newIndex = index === openStepIndex ? null : index;
    setOpenStepIndex(newIndex);
  };

  const onClickLink = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, url: string) => {
    e.preventDefault();
    if (url) onStepClick?.(url);
  };

  return (
    <LeftNav>
      <MainSteps>
        {leftNavSteps.map(({
          label, subSteps, status, url,
        }, index) => (
          <Step>
            <LinkContainer status={status}>
              <Link status={status} href="/" onClick={(e) => onClickLink(e, url)}>
                {status === 'completed' && (
                <CheckIcon
                  sx={{
                    position: ' absolute',
                    left: '-28px',
                    fontSize: ' 16px',
                    top: '2px',
                    color: 'common.white',
                  }}
                />
                )}
                {label}
              </Link>
            </LinkContainer>

            {subSteps?.length
              && (
                <ArrowIconStyled
                  isOpen={openStepIndex === index}
                  onClick={() => toggleStep(index)}
                />
              )}
            {openStepIndex === index && subSteps?.length && (
            <SubMainSteps>
              {subSteps.map((subStep) => (
                <SubStep>
                  <SubLink status={subStep.status} href="/" onClick={(e) => onClickLink(e, subStep.url)}>
                    {subStep.status === 'completed' && (
                    <CheckIcon sx={{
                      position: ' absolute',
                      fontSize: '9px',
                      left: '-25px',
                      top: '4px',
                      color: 'common.white',
                    }}
                    />
                    )}
                    {subStep.label}
                  </SubLink>
                </SubStep>
              ))}
            </SubMainSteps>
            )}
          </Step>
        ))}
      </MainSteps>
    </LeftNav>
  );
}
