import React, { FC, useEffect } from 'react';
import { Stack, Typography, Radio, FormControlLabel } from '@mui/material';
import styled from '@emotion/styled';
import { useFormContext } from 'react-hook-form';
import { IQAddressMapboxSuggestionProps } from '../../AddressMapbox/IQAddressMapbox/IQAddressMapbox';

export interface IQAddressCardProps {
  title: string;
  address: IQAddressMapboxSuggestionProps;
  isSelected: boolean;
  name: string;
}
export interface IQAddressCardContainerProps {
  isSelected: boolean;
}

const CardContainer = styled('div')<IQAddressCardContainerProps>(({ theme, isSelected }) => ({
  boxShadow: `0px 0px 0px ${isSelected ? 2 : 1}px ${isSelected ? theme.palette.primary.main : theme.palette.text.secondary} inset`,
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  borderRadius: 2,
  margin: `${theme.spacing(1.5)} ${theme.spacing(2)}`,

  'label > span': {
    alignSelf: 'start',
  }
}));

const CardLabelContainer = styled(Stack)(({ theme }) => ({
  margin: theme.spacing(1),
}));

const IQAddressCard: FC<IQAddressCardProps> = ({
  title,
  address,
  isSelected,
  name,
}) => {
  const { unregister, register, setValue } = useFormContext();

  useEffect(() => {
    setValue(name, address.id);

    return () => unregister(name);
  }, []);

  return (
    <Stack>
      <Typography align="center" variant="caption" textTransform="uppercase" fontWeight={600}>{title}</Typography>
      <CardContainer isSelected={isSelected}>
        <FormControlLabel
          name={name}
          control={<Radio value={address.id} {...register(name)} />}
          label={(
            <CardLabelContainer>
              <Typography variant="caption" gutterBottom>{address.address}</Typography>
              <Typography variant="caption" gutterBottom>{address.place}, {address.region} {address.postalCode}</Typography>
              <Typography variant="caption" gutterBottom>{address.country}</Typography>
            </CardLabelContainer>
          )}
        />
      </CardContainer>
    </Stack>
  );
};

export default IQAddressCard;
