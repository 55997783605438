import React, { useEffect, useState } from 'react';
import { LinearProgress, Typography } from '@mui/material';
import styled from '@emotion/styled';

export enum ProgressTextPosition {
  TOP,
  BOTTOM,
};

type ProgressBlockWrapperProps = {
  desktopHeight: number;
  mobileHeight: number;
};

const ProgressBlockWrapper = styled('div')<ProgressBlockWrapperProps>(({ theme, desktopHeight, mobileHeight }) => ({
  display: 'flex',
  alignItems: 'center',
  flexFlow: 'column',
  justifyContent: 'center',
  height: desktopHeight,
  width: '100%',
  [theme.breakpoints.down('md')]: {
    height: mobileHeight,
  },
}));

const ProgressWrapper = styled('div')(({ theme }) => ({
  margin: theme.spacing(3, 0),
  width: '40%',
  [theme.breakpoints.down('md')]: {
    width: '80%',
  },
}));

type BasicLinearProgressProps = {
  progress: number;
};

const BasicLinearProgress = styled(LinearProgress)<BasicLinearProgressProps>(({ theme, progress }) => ({
  '&.MuiLinearProgress-root': {
    height: 20,
    borderRadius: 10,
    boxShadow: `0px 0px 0px 1px ${theme.palette.action.disabledBackground}`,

    '> span': {
      borderRadius: 13,
      background: `linear-gradient(90deg, ${theme.palette.primary.main} ${100 - progress}%, #F03C4B ${100 - (progress / 2)}%, ${theme.palette.success.main} ${200 - progress}%)`,
    },
  },
  '&.MuiLinearProgress-colorPrimary': {
    backgroundColor: theme.palette.common.white,
    animation: `pulse 2000ms ${theme.transitions.easing.easeInOut}`,
    animationIterationCount: 'infinite',
  },
  '@keyframes pulse': {
    '0%': {
      backgroundColor: theme.palette.common.white,
    },
    '50%': {
      backgroundColor: theme.palette.info.light,
    },
    '100%': {
      backgroundColor: theme.palette.common.white,
    },
  },
}));

type IQProgressBarProps = {
  loading: boolean;
  progressText?: string;
  progressTextPosition?: ProgressTextPosition,
  onFinish?: () => void;
  progressInterval?: number;
  updateProgressionInterval?: number;
  minimumDisplayTime?: number;
  timeBeforeOnFinish?: number;
  wrapperDesktopHeight?: number;
  wrapperMobileHeight?: number;
};

const DEFAULT_PROGRESS_INTERVAL = 20;
const DEFAULT_UPDATE_PROGRESSION_INTERVAL = 200;
const DEFAULT_MINIMUM_DISPLAY_TIME = 2000;
const DEFAULT_TIME_BEFORE_ON_FINISH = 50;
const DEFAULT_WRAPPER_DESKTOP_HEIGHT = 500;
const DEFAULT_WRAPPER_MOBILE_HEIGHT = 375;

export function IQProgressBar({
  loading,
  progressText = '',
  progressTextPosition = ProgressTextPosition.TOP,
  onFinish = () => {},
  progressInterval = DEFAULT_PROGRESS_INTERVAL,
  updateProgressionInterval = DEFAULT_UPDATE_PROGRESSION_INTERVAL,
  minimumDisplayTime = DEFAULT_MINIMUM_DISPLAY_TIME,
  timeBeforeOnFinish = DEFAULT_TIME_BEFORE_ON_FINISH,
  wrapperDesktopHeight = DEFAULT_WRAPPER_DESKTOP_HEIGHT,
  wrapperMobileHeight = DEFAULT_WRAPPER_MOBILE_HEIGHT,
}: IQProgressBarProps) {
  const [progress, setProgress] = useState<number>(10);
  const [startTime] = useState<number>(Date.now());
  let intervalTracker = null;

  const updateProgression = () => {
    // If the backend is finished, check to make sure we have run for at least 2 seconds,
    // then jump to 100 and close on next interation.
    const elapsedTime = Date.now() - startTime;
    const pastMinimum = elapsedTime > minimumDisplayTime;
    if (pastMinimum) {
      if (!loading) {
        setProgress(100);
        // Buy a bit more time for the bar to get to 100% before redirect
        setTimeout(onFinish, timeBeforeOnFinish);
      } else if (progress < 100) {
        const diff = Math.random() * progressInterval;
        setProgress(Math.min(progress + diff, 100));
      }
    } else if (progress < 100) {
      const diff = Math.random() * progressInterval;
      setProgress(Math.min(progress + diff, 100));
    }
  };

  useEffect(() => {
    intervalTracker = setInterval(
      updateProgression,
      updateProgressionInterval,
    );
    return () => clearInterval(intervalTracker);
  });

  return (
    <ProgressBlockWrapper desktopHeight={wrapperDesktopHeight} mobileHeight={wrapperMobileHeight}>
      {
        progressText && progressTextPosition === ProgressTextPosition.TOP && (
          <Typography variant="h5" component="div">
            {progressText}
          </Typography>
        )
      }
      <ProgressWrapper>
        <BasicLinearProgress
          variant="determinate"
          progress={progress}
          value={progress}
        />
      </ProgressWrapper>
      {
        progressText && progressTextPosition === ProgressTextPosition.BOTTOM && (
          <Typography variant="h5" component="div">
            {progressText}
          </Typography>
        )
      }
    </ProgressBlockWrapper>
  );
};
