import React from 'react';
import styled from '@emotion/styled';
import { Typography, Popover } from '@mui/material';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

export interface IItems {
  id: number
  cartItemThumbnail: string
  cartItemTitle: string
  cartItemPrice?: string
  applicableTaxesLabel?: string
}
export interface IQCartOverlayProps {
  items: IItems[];
  taxesLabel?: string;
  [rest: string]: any;
}

const IconContainer = styled('span')`
  max-width: 48px;
  max-height: 48px;
`;

const ContainerItem = styled('div')`
  display: flex;
  gap: 15px;
`;

const LabelTaxes = styled('p')`
  font-size: 14px;
  color: ${(props) => props.theme.palette.text.secondary};
`;

const PopoverButton = styled('div')`
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: 14px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 10px 0px;
  width: 100%;
  color: ${(props) => props.theme.palette.primary.main};
  border: 1px solid ${(props) => props.theme.palette.primary.main};
  max-width: 211px;
  cursor: pointer;
`;

const Items = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 18px;
`;

const ItemTitle = styled('div')`
  font-weight: bold;
`;

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const ContainerDescription = styled('div')`
  display: flex;
  flex-direction: column;
  font-size: 14px;
`;

const LabelPlus = styled('span')`
  font-size: 12px;
  color: ${(props) => props.theme.palette.text.secondary};
`;

const PopverTitleContainer = styled('div')`
  font-family: ${(props) => props.theme.typography.fontFamily};
  background: ${(props) => props.theme.palette.primary.main};
  color: ${(props) => props.theme.palette.common.white};
  font-size: 18px;
  padding: 15px 25px;
  align-items: center;
  display: flex;
  gap: 10px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
`;

const PopoverStyled = styled(Popover)`
  max-width: 300px;

  & .MuiPaper-root {
    position: relative;
    border-radius: 2px;
    padding: 10px;
    background: transparent;
    box-shadow: none;

    &:before {
      content: "";
      display: block;
      width: 0;
      height: 0;
      border-top: 8px solid transparent;
      border-bottom: 8px solid transparent;
      border-right: 8px solid ${(props) => props.theme.palette.common.white};
      position: absolute;
      bottom: 17px;
      left: 3px;
    }
    &.Mui-expanded {
      min-height: 48px;
      background: ${(props) => props.theme.palette.info.dark};

      &:before {
        border-right: 13px solid  ${(props) => props.theme.palette.info.dark};
      }
    }

    & .MuiAccordionSummary-content {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    & .MuiAccordionSummary-content.Mui-expanded {
      margin: 12px 0;
    }

    & .MuiSvgIcon-root {
      font-size: 17px;
    }

    & .MuiTypography-root {
      font-size: 18px;
      background: ${(props) => props.theme.palette.common.white};
      border-bottom-right-radius: 5px;
      border-bottom-left-radius: 5px;
      box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 0%), 0px 1px 10px 1px rgb(0 0 0 / 15%), 0px 3px 14px 2px rgb(0 0 0 / 0%);
    }
  }
`;

export default function IQCartOverlay({ items, taxesLabel }: IQCartOverlayProps) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div>
      <PopoverButton
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <ShoppingCartIcon sx={{ fontSize: '16px' }} />
        <span>Cart</span>
      </PopoverButton>
      <PopoverStyled
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <PopverTitleContainer>
          <ShoppingCartIcon />
          <span>Cart</span>
        </PopverTitleContainer>
        <Typography sx={{ p: 1 }}>
          <Items>
            {items.map(({
              id, cartItemTitle, cartItemPrice, applicableTaxesLabel, cartItemThumbnail,
            }) => (
              <ContainerItem key={id}>
                <IconContainer>
                  <img style={{ height: '100%', width: '100%' }} alt="selection" src={cartItemThumbnail} />
                </IconContainer>
                <Container>
                  <ContainerDescription>
                    <ItemTitle>{cartItemTitle}</ItemTitle>
                    {cartItemPrice && (
                    <span>
                      $
                      {' '}
                      {cartItemPrice}
                      /Mo
                    </span>
                    )}
                  </ContainerDescription>
                  <LabelPlus>{applicableTaxesLabel}</LabelPlus>
                </Container>
              </ContainerItem>
            ))}
            <LabelTaxes>
              {taxesLabel}
            </LabelTaxes>
          </Items>
        </Typography>
      </PopoverStyled>
    </div>
  );
}
