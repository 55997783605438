import React, { useState } from 'react';
import {
  Menu, MenuItem, Theme, Button,
} from '@mui/material';
import { useTheme } from '@emotion/react';
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import styled from '@emotion/styled';
import { IQHeaderLink, IQLink, MenuIcon } from '../IQHeader/IQHeader';

type DesktopMenuItemButtonProps = {
  theme: Theme,
  isActive?: boolean,
};

export const DesktopMenuItemButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'isActive',
})(({ theme, isActive }: DesktopMenuItemButtonProps) => ({
  position: 'relative',
  fontFamily: 'Unify Sans',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '24px',
  padding: theme.spacing(2, 2),
  color: theme.palette.background.default,
  textTransform: 'none',
  cursor: 'pointer',
  marginLeft: 0,

  '&.right': {
    fontWeight: 600,
    marginLeft: 'auto',
  },
  '&:hover': {
    background: theme.palette.common.black,
    color: theme.palette.success.main,
  },
  '&:focus': {
    background: theme.palette.common.black,
    border: '2px solid #6982C8',
    borderRadius: 'unset',
  },
  '&:before': {
    content: '""',
    display: 'inline-block',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    width: '100%',
    height: 4,
    backgroundColor: theme.palette.success.main,
    opacity: isActive ? 1 : 0,
    transition: '.25s opacity ease-in-out',
  },
}));

type DropdownMenuItemProps = {
  theme: Theme,
  hide?: boolean,
  isActive?: boolean,
};

export const DropdownMenuItem = styled(MenuItem, {
  shouldForwardProp: (prop) => prop !== 'isActive',
})(({ theme, hide, isActive }: DropdownMenuItemProps) => ({
  display: hide && 'none',
  padding: theme.spacing(1, 2),
  backgroundColor: theme.palette.common.black,
  color: isActive ? theme.palette.success.main : theme.palette.common.white,
  fontWeight: isActive ? 600 : 400,
  borderTop: `1px solid ${theme.palette.action.disabledBackground}`,
  '&:hover': {
    color: theme.palette.success.main,
    backgroundColor: theme.palette.common.black,
  },
}));

const StyledLabelTxtWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(0.7),
}));

export interface IQDesktopHeaderExpandableLink {
  labelText: string,
  dropdownLinks: IQLink[],
  handleLinkClick: (link) => void,
  activeLink?: IQHeaderLink,
  headerLinkClassName?: string,
}

/**
 * Component to render a header link with a "dropdown" below it.
 * @param {IQLink[]} links: An object array describing the dropdown links
 * Each object has:
 * link.labelText: The label text describing the link
 * link.link: The link itself
 */
export default function IQDesktopHeaderExpandableLink({ labelText, dropdownLinks, handleLinkClick, activeLink, headerLinkClassName }: IQDesktopHeaderExpandableLink) {
  const theme = useTheme();
  const [menuAnchor, setMenuAnchor] = useState(null);

  return (
    <React.Fragment>
      <DesktopMenuItemButton
        theme={theme}
        endIcon={menuAnchor ? <ExpandLess /> : <ExpandMore />}
        onClick={(e) => setMenuAnchor(e.currentTarget)}
        isActive={dropdownLinks.some(l => l.id === activeLink?.id)}
        className={headerLinkClassName}
      >
        {labelText}
      </DesktopMenuItemButton>
      <Menu
        anchorEl={menuAnchor}
        onClose={() => setMenuAnchor(null)}
        open={!!menuAnchor}
        sx={{
          '& .MuiMenu-list': {
            backgroundColor: theme.palette.common.black,
            padding: 0,
          },
        }}
      >
        {dropdownLinks.map((link) => (
          <DropdownMenuItem
            key={link.id}
            theme={theme}
            onClick={() => handleLinkClick(link)}
            hide={link?.hide}
            isActive={activeLink?.id === link.id}
          >
            <StyledLabelTxtWrapper>
              {link.labelText}
              {link.rightIconUrl && <MenuIcon src={link.rightIconUrl} alt={`${link.labelText} icon.`}/>}
            </StyledLabelTxtWrapper>
          </DropdownMenuItem>
        ))}
      </Menu>
    </React.Fragment>
  );
}
