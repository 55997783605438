import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { styled } from '@mui/material/styles';
import {
  Checkbox, FormControlLabel, FormGroup, useTheme,
} from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export interface IQContentTileItemProps {
  name?: string
  /**
   * Input label
   */
  label?: string,
  desc?: string,
  selected?: boolean,
  disabled?: boolean,
  showError?: boolean,
  index?: number,
  selectedCallback?: any
}

const IQCheckboxStyled = styled(Checkbox)(({ theme }) => ({
  padding: '4px 9px',
  '&.Mui-checked': {
    color: `${(theme.palette.primary.main)} !important`,
  },
  '&.Mui-disabled': {
    color: `${(theme.palette.action.disabledBackground)} !important`,
  },
  '&.MuiCheckbox-root': {
    color: theme.palette.text.primary,
  },
}));

const IQExpandMoreIcon = styled(ExpandMoreIcon)(({ theme }) => ({
  color: `${(theme.palette.primary.main)}`,
}));

const FormControlLabelStyled = styled(FormControlLabel)(({ theme }) => ({
  color: `${(theme.palette.primary.main)}`,
  '&.Mui-disabled': {
    color: `${(theme.palette.primary.main)}`,
  },
}));

const AccordionDetailsStyled = styled(AccordionDetails)(({ theme }) => ({
  marginLeft: '5%',
}));

const IQAccordionStyled = styled(Accordion) <IQContentTileItemProps>`
    box-shadow: none;
    width: 100%;
    padding: 2;
    &:hover {
        background-color: ${({ theme }) => (theme.palette.primary.main)}28
    }
    &:focus-within {
        border: ${(props) => (props.selected ? `3px solid ${(props.theme.palette.primary.main)}` : `1px solid ${(props.theme.palette.primary.main)}`)};
        border:  ${(props) => props.showError && props.selected && `3px solid ${(props.theme.palette.error.main)}`};
    }
    border:  ${(props) => (props.selected ? `3px solid ${(props.theme.palette.primary.main)}` : `1px solid ${(props.theme.palette.primary.main)}`)};
    border:  ${(props) => props.showError && props.selected && `3px solid ${(props.theme.palette.error.main)}`};
    border:  ${(props) => props.showError && !props.selected && `1px solid ${(props.theme.palette.primary.main)}`};
    border:  ${(props) => props.disabled && props.selected && `3px solid ${(props.theme.palette.action.disabledBackground)}`};
    border:  ${(props) => props.disabled && !props.selected && `1px solid ${(props.theme.palette.action.disabledBackground)}`};
    color:  ${(props) => props.disabled && `${(props.theme.palette.action.disabledBackground)}`};
    pointer-events: ${(props) => props.disabled && 'none'};
    &.Mui-disabled {
        background-color: ${({ theme }) => (theme.palette.common.white)} !important;
    }
    margin-top: 10px;
    margin-bottom: 10px;
    .MuiAccordionSummary-content.Mui-expanded {
      margin: 10px 0px 10px 0px;
    }
    &.MuiAccordion-root.Mui-expanded {
        margin: 0;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .MuiAccordionDetails-root {
      padding: 0 30px 16px
    }
    &.MuiAccordion-root.MuiAccordionSummary-content.Mui-expanded {
      margin-bottom: 8px;
    }
`;

export default function IQContentTileItem({
  index,
  selectedCallback,
  name,
  label,
  desc,
  disabled,
  selected = false,
  showError,
}: IQContentTileItemProps) {
  const [actualSelected, setActualSelected] = React.useState(selected);
  const [expand, setExpand] = React.useState(false);
  const theme = useTheme();
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
    clearErrors,
  } = useFormContext();
  const hasError = !!errors[name];
  const errorMessage = hasError ? errors[name].message : '';
  if (hasError) {
    showError = true;
  }
  const toggleAccordion = () => {
    setExpand((prev) => !prev);
  };

  const handleClick = (event) => {
    selectedCallback(index, !actualSelected);
    setActualSelected(!actualSelected);
  };

  return (
    <IQAccordionStyled
      selected={actualSelected}
      disabled={disabled}
      showError={showError}
      expanded={expand}
    >
      <AccordionSummary
        onClick={toggleAccordion}
        expandIcon={<IQExpandMoreIcon />}
        id={name + index}
        aria-controls="panel1a-content"
      >
        <FormGroup onClick={(e) => e.stopPropagation()}>
          <FormControlLabelStyled
            control={(
              <IQCheckboxStyled
                sx={{
                  pointerEvents: 'auto',
                }}
                checked={actualSelected}
                onChange={handleClick}
                disabled={disabled}
              />
            )}
            label={label}
          />
        </FormGroup>
      </AccordionSummary>
      <AccordionDetailsStyled>
        <Typography>
          {desc}
        </Typography>
      </AccordionDetailsStyled>
    </IQAccordionStyled>
  );
}
