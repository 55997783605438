import React from 'react';
import styled from '@emotion/styled';
import { Badge } from '@mui/material';

export interface IQHeaderItemBadgeProps {
  badgeContent: string | number;
  showBadge: boolean;
  badgeMax?: number;
  className?: string;
  children?: React.ReactNode
};

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    position: 'relative',
    top: 20,
    left: '-10px',
    width: 'auto',
    height: 20,
    borderRadius: 8,
    backgroundColor: theme.palette.success.main,
    color: theme.palette.common.black,
    transform: 'none',
  },

  [theme.breakpoints.down('md')]: {
    '& .MuiBadge-badge': {
      top: 5,
      left: 5,
    },
  }
}));

/**
 * Component for showing a badge around a given component
 */
export default function IQHeaderItemBadge({
  badgeContent, badgeMax, showBadge, className, children,
}: IQHeaderItemBadgeProps) {
  if (showBadge) {
    return (
      <StyledBadge badgeContent={badgeContent} max={badgeMax} className={className}>
        {children}
      </StyledBadge>
    );
  };

  return (
    <React.Fragment>
      {children}
    </React.Fragment>
  );
}
