/* eslint-disable react/jsx-fragments */
import React, { ReactNode } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { FormControlLabel, Checkbox, useTheme } from '@mui/material';
import { ReactSVG } from 'react-svg';
import IQLabelTooltip from '../../tooltip/IQLabelTooltip/IQLabelTooltip';

export interface IQBigBoxCheckboxProps {
  id: string;
  fontIcon? : JSX.Element,
  svgIcon?: string;
  label?: string;
  name?: string;
  showError?: boolean;
  disabled?: boolean;
  subDescription?: string;
  tooltipText?: string | ReactNode;
  checked?: boolean;
  onChange?: any;
  required?: boolean;
  toolTipIcon?: any;
  tooltipPlacement?: 'bottom' | 'right' | 'top' | 'left';
  backgroundColor?: string;
}

type StateProps = {
  disabled: boolean;
  showError: boolean;
  checked: boolean;
  backgroundColor?: string;
  height?: number;
};

const CheckboxContainer = styled('div')<StateProps>`
  border: 1px solid ${({ theme }) => (theme.palette.primary.main)};
  border-color: ${({ disabled, showError, theme }) => (disabled ? theme.palette.action.disabledBackground : showError ? theme.palette.error.main : theme.palette.primary.main)};
  background-color: ${(props) => (props.backgroundColor ? props.backgroundColor : props.theme.palette.common.white)};
  width: 294px;
  height: ${(props) => `${props.height}px`} ;
  position: relative;
  &:hover {
    background: ${({ theme }) => (theme.palette.primary.main)}29;
  }

  ${({
    checked, disabled, showError, theme,
  }) => checked
    && css`
      &:before {
        content: "";
        position: absolute;
        inset: -1px;
        border: 3px solid ${theme.palette.primary.main};
        pointer-events: none;
        border-color: ${disabled
    ? theme.palette.action.disabledBackground
    : showError
      ? theme.palette.error.main
      : theme.palette.primary.main};
      }
    `}
`;

const LabelContainer = styled('div')`
  display: flex;
  color: ${({ theme }) => (theme.palette.primary.main)};
  grid-column: 1 / 1;
  grid-row: 1 / 1;
`;

const SvgContainer = styled('div')`
  svg path {
    stroke: currentColor;
    fill: currentColor;
  }
`;

const LabelSubDescription = styled('span')`
  position: absolute;
  bottom: 16px;
  right: 16px;
  text-align: right;
  font-family: ${({ theme }) => (theme.typography.fontFamily)};
  display: block;
  color: ${({ theme }) => (theme.palette.text.secondary)};
  grid-column: 2 /2;
  grid-row: 2 / 2;
`;

const CheckboxStyled = styled(Checkbox)<StateProps>`
  padding: 0;
  &.Mui-checked {
    color: ${({ disabled, showError, theme }) => (disabled ? theme.palette.action.disabledBackground : showError ? theme.palette.error.main : theme.palette.primary.main)};
  }
`;

const CheckboxLabelStyled = styled('span')`
  font-size: 18px;
  display: flex;
  align-items: center;
  margin-left: 15px;
`;

const IconContainerStyled = styled('span')`
  color: ${({ theme }) => (theme.palette.primary.main)};

  img {
    width: 40px;
    height: 40px;
  }

  & .MuiSvgIcon-root {
    font-size: 40px;
  }
`;

const FormControlLabelStyled = styled(FormControlLabel)(({ theme }) => ({
  '&.MuiFormControlLabel-root': {
    marginLeft: 0,
    marginRight: 0,
    display: 'flex',
    alignItems: 'flex-start',
    gap: '15px',
    padding: '16px',
    paddingTop: '20px',
  },
  '& .MuiTypography-root': {
    display: 'grid',
    width: '100%',
  },
  '& .css-16zrpq8-MuiButtonBase-root-MuiCheckbox-root.Mui-checked': {
    color: `${theme.palette.primary.main}`,
  },
}));

export default function IQBigBoxCheckbox({
  id,
  label,
  svgIcon,
  fontIcon,
  subDescription,
  name,
  tooltipPlacement,
  disabled,
  showError = false,
  checked = false,
  tooltipText = null,
  required,
  onChange,
  toolTipIcon,
  backgroundColor,
}: IQBigBoxCheckboxProps) {
  const theme = useTheme();
  return (
    <CheckboxContainer
      disabled={disabled}
      showError={showError}
      checked={checked}
      backgroundColor={backgroundColor}
      height={subDescription ? 104 : 80}
    >
      <FormControlLabelStyled
        control={(
          <CheckboxStyled
            value={id}
            checked={checked}
            name={name || id}
            disabled={disabled}
            showError={showError}
            id={id}
            onChange={onChange}
          />
        )}
        label={(
          <LabelContainer>
            <IconContainerStyled>
              {svgIcon
                ? (
                  <SvgContainer>
                    <ReactSVG src={svgIcon} alt="icon checkbox" />
                  </SvgContainer>
                )
                : fontIcon}
            </IconContainerStyled>
            <CheckboxLabelStyled>{label}</CheckboxLabelStyled>
            {tooltipText && (
              <IQLabelTooltip
                tooltipText={tooltipText}
                labelText=""
                hasError={showError}
                theme={theme}
                tooltipPlacement={tooltipPlacement}
                paddingTop={13}
                required={required}
                Icon={toolTipIcon}
              />
            )}
          </LabelContainer>
        )}
      />
      {subDescription && <LabelSubDescription>{subDescription}</LabelSubDescription>}
    </CheckboxContainer>
  );
}
