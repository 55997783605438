import React from 'react';

export const ValidationContext = React.createContext(null);

export const useValidationContext = () => React.useContext(ValidationContext);

export function ValidationProvider({ children, schema }) {
  return (
    <ValidationContext.Provider value={{ schema }}>
      {children}
    </ValidationContext.Provider>
  );
}
