import { createTheme, Theme, TypeText } from '@mui/material/styles';
import { PaletteColorOptions } from '@material-ui/core';

export const IQThemeV2: Theme = createTheme({
  palette: {
    text: {
      primary: '#292928',
      secondary: '#716F6F',
      light: '#E8D0AB',
    } as Partial<TypeText> & { light: string },
    primary: {
      main: '#0046FF',
      light: '#E0E9FF',
      dark: '#0F4791',
    },
    secondary: {
      main: '#009BFF',
      light: '#E0F3FF',
      dark: '#0A32A0',
    },
    info: {
      main: '#688595',
      light: '#F0F0F0',
      dark: '#292928',
    },
    error: {
      main: '#C20F1E',
      tint: '#F5D5D7',
    } as PaletteColorOptions & { tint: string },
    warning: {
      main: '#E86417',
      // @ts-ignore
      tint: '#FFEAB8',
    },
    action: {
      disabledBackground: '#AEAEAD',
    },
    background: {
      default: '#F9F6F1',
    },
    // These are the Orion greens
    success: {
      main: '#FF6437',
      light: '#FFE8E0',
      dark: '#50902D',
      tint: '#D9ECCB',
    } as PaletteColorOptions & { tint: string },
  },
  typography: {
    fontFamily: 'Unify Sans',
    fontWeightMedium: 600,
    fontWeightBold: 800,
    h1: {
      fontFamily: 'Unify Sans SemiBold',
      fontSize: 48,
      lineHeight: '53px',
      '@media (max-width:600px)': {
        fontSize: 32,
        lineHeight: '35px',
      },
    },
    h2: {
      fontFamily: 'Unify Sans SemiBold',
      fontSize: 40,
      lineHeight: '50px',
      '@media (max-width:600px)': {
        fontSize: 28,
        lineHeight: '35px',
      },
    },
    h3: {
      fontFamily: 'Unify Sans SemiBold',
      fontSize: 32,
      lineHeight: '40px',
      '@media (max-width:600px)': {
        fontSize: 24,
        lineHeight: '30px',
      },
    },
    h4: {
      fontFamily: 'Unify Sans SemiBold',
      fontSize: 24,
      lineHeight: '30px',
      '@media (max-width:600px)': {
        fontSize: 20,
        lineHeight: '25px',
      },
    },
    h5: {
      fontFamily: 'Unify Sans SemiBold',
      fontSize: 20,
      lineHeight: '25px',
      '@media (max-width:600px)': {
        fontSize: 18,
        lineHeight: '23px',
      },
    },
  },
  shape: {
    borderRadius: 2,
  },
  spacing: 8,
  components: {
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: 0,
          marginTop: 8,
          '&.error': {
            color: '#A6083D',
          },
        },
      },
    },

    MuiButton: {
      styleOverrides: {
        root: {
          whiteSpace: 'nowrap',
          borderRadius: '30px',
          textTransform: 'initial',
          boxShadow: 'none',
          '&.Mui-disabled': {
            backgroundColor: '#0046FF38',
          },

          '&.MuiButton-containedPrimary:': {
            boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
            ':hover': {
              backgroundColor: '#0035C2',
            },
            ':focus': {
              outline: 'none',
              boxShadow: '2px -2px 0px white, 1px -2px 0px white, -2px -3px 0px white, -2px 2px 0px white, 2px 3px 0px white, 0 0 0 6px #5578BD',
            },
          },
          '&.MuiButton-outlinedPrimary:': {
            boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
            ':hover': {
              backgroundColor: '#F5F8FF',
            },
            ':focus': {
              outline: 'none',
              boxShadow: '2px -2px 0px white, 1px -2px 0px white, -2px -3px 0px white, -2px 2px 0px white, 2px 3px 0px white, 0 0 0 6px #5578BD',
            },
          },
        },
      },
    },
  },
});

export default IQThemeV2;
