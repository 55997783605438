import React, { useState } from 'react';
import {
  FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Theme
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { IQTheme } from '../../styles/IQTheme';

interface IQRadioButtonProps {
  /**
   * Label text (title) for the radio buttons group
   */
  title: string,

  /**
   * title font weight
   */
  fontTitleWeight?: string,

  /**
  * Parent callback function when the Yes/No value changes
  */
  onValueChange: (value: boolean) => void,

  /**
   * Theme
   */
  theme?: Theme,
}

interface FormLabelProps {
  /**
   * title font weight
   */
  fontTitleWeight?: string,

  /**
   * Theme
   */
  theme?: Theme,
}

const FormLabelStyled = styled(FormLabel)<FormLabelProps>`
  color: ${(props) => props.theme.palette.text.primary};
  margin-bottom: 10px;
  display: inline-block;
  font-weight: ${(props) => props.fontTitleWeight};

  &.Mui-focused {
    color: ${(props) => props.theme.palette.text.primary};
  },
`;

const FormControlLabelStyled = styled(FormControlLabel)`
  margin-right: 40px;

  .MuiRadio-root {
    margin-right: 4px;
    padding: 4px 9px;
    color: ${(props) => props.theme.palette.text.primary};

    &:hover {
      background-color: transparent;
    }
  }

  .MuiTypography-root.MuiFormControlLabel-label {
    color: ${(props) => props.theme.palette.text.primary};
  }
`;

export default function IQYesNoRadioButtons({
  title,
  onValueChange,
  theme,
  fontTitleWeight,
}: IQRadioButtonProps) {
  const [value, setValue] = useState('');

  const handleChange = (e) => {
    setValue(e.target.value);

    if (e.target.value === 'yes') {
      onValueChange(true);
    } else if (e.target.value === 'no') {
      onValueChange(false);
    }
  };

  return (
    <FormControl>
      <FormLabelStyled
        id="row-radio-buttons-group-label"
        theme={theme}
        fontTitleWeight={fontTitleWeight}
      >
        {title}
      </FormLabelStyled>
      <RadioGroup
        row
        aria-labelledby="row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={value}
        onChange={handleChange}
      >
        <FormControlLabelStyled value="yes" control={<Radio disableRipple />} label="Yes" />
        <FormControlLabelStyled value="no" control={<Radio disableRipple />} label="No" />
      </RadioGroup>
    </FormControl>
  );
}

IQYesNoRadioButtons.defaultProps = {
  theme: IQTheme,
};
