import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { IQFormInput } from '../../form/IQFormInput/IQFormInput';
import { useValidationContext } from '../../providers/ValidationProvider';

export enum Adornment {
  START_ADORNMENT = 'START',
  END_ADORNMENT = 'END',
}

export interface IQInputWebURLProps {
  /**
   * Input label
   */
  label: string;
  /**
   * Input field name
   */
  name: string;

  /**
   * Input required
   */
  required?: boolean;
  /**
   * Default value
   */
  defaultValue?: string;

  /**
   * Default value
   */
  ftpValidation?: boolean;

  /**
   * Tooltip Text
   */
  tooltipText?: string;
  /**
   * Adornment Icon
   */
  adornmentIcon?: any;
  /**
   * Subdescription
   */
  subDescription?: string;
  /**
   * Placeholder
   */
  placeholder?: string;

  tooltipPaddingBottom?: number;

  tooltipPlacement?: 'bottom' | 'right' | 'top' | 'left'
  /**
   * Additional parameters for custom validations (Optional)
   */
  params?: string;
  /**
   * Properties for react-hook-forms / yup integration
   */
  [rest: string]: any;
}

const httpRegex = /^((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm;
const ftpRegex = /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm;

export default function IQInputWebURL({
  name,
  label,
  defaultValue,
  ftpValidation,
  required = false,
  params,
  tooltipText,
  tooltipPlacement,
  tooltipPaddingBottom,
  adornmentIcon,
  subDescription,
  ...rest
}: IQInputWebURLProps) {
  const { schema } = useValidationContext();
  const theme = useTheme();
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
    clearErrors,
  } = useFormContext();
  const [customValidation, setCustomValidation] = useState(yup.string().url());
  useEffect(() => {
    const customValidationRegex = !ftpValidation ? httpRegex : ftpRegex;
    let newValidation = yup
      .string()
      .matches(customValidationRegex, 'URL is not valid');
    if (required) {
      newValidation = newValidation.required();
    }

    setCustomValidation(newValidation);
    clearErrors(name);
  }, [required, ftpValidation]);

  return (
    <IQFormInput
      showError={errors[name] && true}
      name={name}
      id={name}
      schema={schema}
      labelText={label}
      theme={theme}
      tooltipText={tooltipText}
      customValidation={customValidation}
      adornmentIcon={adornmentIcon}
      adornmentPosition={Adornment.START_ADORNMENT}
      subDescription={subDescription}
      tooltipPlacement={tooltipPlacement}
      tooltipPaddingBottom={tooltipPaddingBottom}
      {...rest}
    />
  );
}
