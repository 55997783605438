import React from 'react';

import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

export interface IQButtonProps {
  label?: string
  disabled?: boolean
  hasError?: boolean
  [rest: string]: any
}

const IQButtonStyled = styled(Button) <IQButtonProps>`
  color: ${(props) => (props.disabled ? '#c5c5c5' : (props.hasError ? '#d2424b' : '#1665CF'))};
  border-color: ${(props) => (props.disabled ? '#c5c5c5' : (props.hasError ? '#d2424b' : '#1665CF'))};
  &:hover {
    border-color: #1665CF;
    border-style: solid;
    background-color: rgba(22, 101, 207, 0.16);
  }
`;

export default function IQButton({
  label,
  disabled = false,
  hasError = false,
  ...rest
}:IQButtonProps) {
  return (
    <IQButtonStyled
      variant="outlined"
      disabled={disabled}
      hasError={hasError}
      {...rest}
    >
      {label}
    </IQButtonStyled>
  );
}
