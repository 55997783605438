import React from 'react';
import styled from '@emotion/styled';

export interface IQLeftNavMainContainerProps {
  children?: React.ReactNode;
}

const LeftNav = styled('div')`
  position: fixed;
  background: ${(props) => props.theme.palette.common.white};
  height: calc(100vh - 90px);
  width: 100%;
  max-width: calc(260px - 68px);
  padding: 34px 34px 60px;
`;

export const IQLeftNavContainer = ({ children } : IQLeftNavMainContainerProps) => (
  <LeftNav>
    {children}
  </LeftNav>
);

export default IQLeftNavContainer;
