import PropTypes, { InferProps } from 'prop-types';
import styled from '@emotion/styled';
import React, { Fragment } from 'react';
import {
  Backdrop, Box, CircularProgress, Typography,
} from '@mui/material';
import IQLoadingSpinner from '../IQLoadingSpinner/IQLoadingSpinner';

const BackdropRoot = styled(Backdrop)({
  position: 'absolute',
  zIndex: 100,
});

const IQBackdropRoot = styled(Backdrop)({
  zIndex: 100,
});

type SpinnerProps = {
  spinnerSize?: boolean;
};

const SpinnerWrapper = styled(Box)<SpinnerProps>`
  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props.spinnerSize ? 'center' : 'inherit')};
`;

type LoadginSpinnerProps = {
  component: React.ElementType
};

const LoadingSpinner = styled(CircularProgress)`
  display: block;
  margin: 0 auto;
  color: ${(props) => props.theme.palette.common.white};
`;

const LoadingText = styled(Typography)({
  fontFamily: 'Unify Sans Bold',
  fontSize: 30,
  letterSpacing: 1,
});

export const IQLoadingOverlay = ({
  openOverlay,
  loadingText,
  spinnerSize,
  isLocalIQSpinner,
  contained,
  theme,
}: InferProps<typeof IQLoadingOverlay.propTypes>) => {
  const localIQSpinnerWrapper = (
    <SpinnerWrapper component="div" spinnerSize={spinnerSize || 40}>
      <IQLoadingSpinner size={spinnerSize || 40} />
      {loadingText && (
        <LoadingText>{loadingText}</LoadingText>
      )}
    </SpinnerWrapper>
  );

  return (
    <Fragment>
      {isLocalIQSpinner && contained
        && (
        <BackdropRoot open={openOverlay}>
          {localIQSpinnerWrapper}
        </BackdropRoot>
        )}
      {
        isLocalIQSpinner && !contained && (
          <IQBackdropRoot open={openOverlay}>
            {localIQSpinnerWrapper}
          </IQBackdropRoot>
        )
      }
      {
        !isLocalIQSpinner && (
          <BackdropRoot open={openOverlay}>
            <SpinnerWrapper component="div">
              <LoadingSpinner size={spinnerSize} />
              {loadingText && (
                <LoadingText>{loadingText}</LoadingText>
              )}
            </SpinnerWrapper>
          </BackdropRoot>
        )
      }
    </Fragment>
  );
};

IQLoadingOverlay.propTypes = {
  openOverlay: PropTypes.bool,
  loadingText: PropTypes.string,
  spinnerSize: PropTypes.number,
  isLocalIQSpinner: PropTypes.bool,
  theme: PropTypes.object,
};

IQLoadingOverlay.defaultProps = {
  loadingText: 'Loading',
  spinnerSize: 150,
  isLocalIQSpinner: false,
  contained: false,
};

export default IQLoadingOverlay;
