import React from 'react';
import IQTopNavCompleteBadgeIcon from '../../icons/IQTopNavCompleteBadgeIcon';
import IQTopNavCurrentBadgeIcon from '../../icons/IQTopNavCurrentBadgeIcon';
import IQTopNavNotStartedBadgeIcon from '../../icons/IQTopNavNotStartedBadgeIcon';
import { TopNavStatusEnum } from '../enums/IQTopNavStepStatusEnum';

export interface IQTopNavStepBadgeProps {
  status?: TopNavStatusEnum,
}

const defaultProps: IQTopNavStepBadgeProps = {
  status: TopNavStatusEnum.NotStarted,
};

export default function IQTopNavStepBadge({
  status,
}: IQTopNavStepBadgeProps) {
  switch (status) {
    case TopNavStatusEnum.NotStarted:
      return (
        <IQTopNavNotStartedBadgeIcon />
      );
    case TopNavStatusEnum.Current:
      return (
        <IQTopNavCurrentBadgeIcon />
      );
    case TopNavStatusEnum.Complete:
      return (
        <IQTopNavCompleteBadgeIcon />
      );
    default:
      return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M12 0C5.376 0 0 5.376 0 12C0 18.624 5.376 24 12 24C18.624 24 24 18.624 24 12C24 5.376 18.624 0 12 0ZM12 21.6C6.696 21.6 2.4 17.304 2.4 12C2.4 6.696 6.696 2.4 12 2.4C17.304 2.4 21.6 6.696 21.6 12C21.6 17.304 17.304 21.6 12 21.6Z" fill="#737373" />
        </svg>
      );
  }
}

IQTopNavStepBadge.defaultProps = defaultProps;
