import React, { FC } from 'react';
import styled from '@emotion/styled';
import { Theme } from '@mui/material';
import { useFormContext } from 'react-hook-form';

export interface IQFormLabelProps {
  id?: string;
  /**
   * The material Theme from parent
   */
  theme: Theme;

  /**
   * Whether to use the error state styling
   */
  hasError?: boolean;

  /**
   * Spacing bottom multiplier
   */
  spaceBottomMultiplier?: number;
  fontLabelWeight?: string;
  lineHeight?: string;
}

/**
 * Component for a basic label for an input field with the default styling
 *
 * See below prop types for param info
 */
const IQFormLabel: FC<IQFormLabelProps> = ({
  id,
  theme,
  hasError = false,
  children,
  fontLabelWeight,
  spaceBottomMultiplier = 2,
  lineHeight = '20px',
}) => {
  const {
    formState: {},
  } = useFormContext();

  const FormLabel = styled('label')({
    color: hasError
      ? theme.palette.error.main : theme.palette.text.primary,
    fontSize: 16,
    lineHeight,
    letterSpacing: '0.25px',
    fontFamily: 'Unify Sans',
    paddingBottom: theme.spacing(spaceBottomMultiplier),
    fontWeight: fontLabelWeight,
    display: 'inline-block',
  });

  return (
    <FormLabel id={id}>
      {children}
    </FormLabel>
  );
};

export default IQFormLabel;
