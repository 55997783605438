import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { useFormContext } from 'react-hook-form';
import styled from '@emotion/styled';
import { Check } from '@mui/icons-material';

type ButtonCheckboxProps = {
  isSelected?: boolean;
  disabled?: boolean;
  hasError?: boolean;
};

const ButtonWrapper = styled('div')<ButtonCheckboxProps>`
  background: ${({ theme }) => (theme.palette.common.white)};
  border: 1px solid ${(props) => props.theme.palette.primary.main};
  box-sizing: border-box;
  box-shadow: 0px 1px 3px rgb(0 0 0 / 20%);
  border-radius: 2px;
  padding: ${(props) => (props.isSelected ? 0 : 2)}px;
  border-width: ${(props) => (props.isSelected ? 3 : 1)}px;
  border-color: ${(props) => (props.disabled ? (props.theme.palette as any).disabled.main : props.hasError && !props.isSelected ? props.theme.palette.error.main : props.theme.palette.primary.main)};
  [${(props) => props.theme.breakpoints.down('md')}]: {
    text-align: 'center',
  };
`;
const ButtonLabel = styled('label')<ButtonCheckboxProps>`
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.25px;
  display: flex;
  align-items: center;
  font-weight: 600;
  min-height: 40px;
  text-align: center;
  cursor: ${(props) => (props.disabled ? 'auto' : 'pointer')};
  color: ${(props) => (props.disabled ? (props.theme.palette as any).disabled.main : props.hasError && !props.isSelected ? props.theme.palette.error.main : props.theme.palette.primary.main)};
`;

const ButtonImgBlock = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  maxWidth: '15%',
  flexBasis: '15%',
}));

const CheckIcon = styled(Check)(({ theme }) => ({
  height: 34,
  marginTop: 2,
  color: theme.palette.common.white,
}));

const ButtonLabelText = styled('div')<ButtonCheckboxProps>`
  align-self: center;
  user-select: none;
  max-width: ${(props) => (props.isSelected ? '85%' : '100%')};
  flex-basis: ${(props) => (props.isSelected ? '85%' : '100%')};
`;

export const IQButtonCheckbox = ({
  id,
  name,
  labelText,
  disabled,
  forceError,
}: InferProps<typeof IQButtonCheckbox.propTypes>) => {
  const {
    register,
    watch,
    formState: {
      errors,
    },
  } = useFormContext();
  const hasError = !!errors[name] || forceError;
  const isSelected = watch(name);

  return (
    <ButtonWrapper isSelected={isSelected} hasError={hasError} disabled={disabled}>
      <ButtonLabel isSelected={isSelected} hasError={hasError} disabled={disabled}>
        {isSelected && (
          <ButtonImgBlock>
            <CheckIcon />
          </ButtonImgBlock>
        )}
        <ButtonLabelText isSelected={isSelected}>
          { labelText }
        </ButtonLabelText>
        <input id={id} type="checkbox" style={{ display: 'none' }} {...register(name)} disabled={disabled} />
      </ButtonLabel>
    </ButtonWrapper>
  );
};

IQButtonCheckbox.propTypes = {
  id: PropTypes.string,
  /**
   * The field name for this  checkbox
   * (corresponds to react-hook-form and yup structure on parent form)
   */
  name: PropTypes.string.isRequired,
  labelText: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  /**
   * Use if you want to force the error state to display
   * (useful if this component is part of a checkbox group that has some over-arching error state
   * E.g.  must select at least 2 checkboxes)
   */
  forceError: PropTypes.bool,

  /**
   * The material Theme from parent
   */
  theme: PropTypes.object.isRequired,
};

IQButtonCheckbox.defaultProps = {
  disabled: false,
  forceError: false,
};

export default IQButtonCheckbox;
