import PropTypes, { InferProps } from 'prop-types';
import styled from '@emotion/styled';
import React from 'react';
import Spinner from '../../img/LocalIQ-Loading';

export const IQLoadingSpinner = ({ size }: InferProps<typeof IQLoadingSpinner.propTypes>) => {
  const SpinnerImg = styled('div')({
    height: size || 40,
    width: size || 40,
  });

  return (
    <SpinnerImg>
      <Spinner />
    </SpinnerImg>
  );
};

export default IQLoadingSpinner;

IQLoadingSpinner.propTypes = {
  size: PropTypes.number,
};
