import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
} from '@mui/material';
import styled from '@emotion/styled';
import { Controller, useFormContext } from 'react-hook-form';
const NO_OP = () => {};

const CheckboxWrapper = styled(FormControl)(({ theme, border, selected }: any) => ({
  borderRadius: 2,
  border: `${selected ? 2 : 1}px solid ${border}`,
  fontSize: 16,
  width: '100%',
  background: theme.palette.common.white,
  '& .MuiCheckbox-colorPrimary': {
    color: theme.palette.text.main,
  },
  '& .MuiCheckbox-colorPrimary.Mui-checked': {
    color: theme.palette.primary.main,
  },
}));

const CheckboxLabel = styled(FormControlLabel)(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'flex-start',
  marginLeft: 0,
  width: '100%',
  '&.Mui-error': {
    '& .MuiCheckbox-colorPrimary': {
      color: theme.palette.error.main,
    },
  },
  '&.Mui-disabled': {
    cursor: 'not-allowed'
  }
}));

export default function IQOutlinedCheckboxInput({
  name,
  labelText,
  onChange = NO_OP,
  customError = null,
  disabled = false,
  theme,
}: InferProps<typeof IQOutlinedCheckboxInput.propTypes>) {
  const {
    control,
    watch,
    formState: {
      errors,
    },
  } = useFormContext();
  const errorObj = customError || errors[name];
  const isSelected = watch(name);
  let borderColor = '';
  if (errorObj) {
    borderColor = '#D2424B';
  } else if (isSelected) {
    borderColor = '#1665CF';
  } else {
    borderColor = '#D7D7D7AA';
  }

  const handleChange = (clickEvent: React.ChangeEvent<HTMLInputElement>, builtInChange: (...event: any[]) => void) => {
    const isChecked = clickEvent.target.checked;
    if (onChange) { onChange(clickEvent); }
    return builtInChange(isChecked);
  };

  return (
    <CheckboxWrapper
      className={ disabled ? 'Mui-disabled' : ''}
      variant="outlined"
      error={!!errorObj}
      border={borderColor}
      selected={isSelected}
      theme={theme}
    >
      <CheckboxLabel
        disableTypography
        control={(
          <Controller
            control={control}
            name={name}
            render={({ field }) => (
              <Checkbox
                {...field}
                sx={{
                  cursor: disabled ? 'not-allowed' : 'inherit',
                  color: '#1665CF',
                }}
                onChange={(e) => handleChange(e, field.onChange)}
                checked={field.value}
                color="primary"
                disabled={disabled}
              />
            )}
          />
        )}
        disabled={disabled}
        label={labelText}
        labelPlacement="start"
      />
    </CheckboxWrapper>
  );
}

IQOutlinedCheckboxInput.propTypes = {
  name: PropTypes.string.isRequired,
  labelText: PropTypes.node.isRequired,
  onChange: PropTypes.func,
  customError: PropTypes.object,
  disabled: PropTypes.bool,
  theme: PropTypes.object.isRequired,
};
