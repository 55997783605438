import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { styled } from '@mui/material/styles';

export interface IQBigBoxItemProps {
  name?: string
  /**
     * Input label
     */
  label?: string
  /**
     * Switch checked value
     */
  image?: string
  /**
     * Switch size
     */
  selected?: boolean,
  disabled?: boolean,
  onChange?: any,
  onBlur?: any,
  showError?: boolean
}

const IQImgStyled = styled('img')(() => ({
  display: 'block',
  marginLeft: 'auto',
  marginRight: 'auto',
  marginTop: '20px',
}));

const IQInvisibleButton = styled('button') <IQBigBoxItemProps>`
    height: 220px;
    width: 214px;
    background: transparent;
    text-align: center;
    color: ${({ theme }) => (theme.palette.primary.main)};
    font-weight: 400;
    font-size: 24px;
    line-height: 30px;
    cursor: pointer;
    &:hover {
        background-color: ${({ theme }) => `${(theme.palette.primary.main)}28`};
    }
    &:focus-within {
        border: ${(props) => (props.selected ? `3px dashed #${props.theme.palette.primary.main}` : `1px dashed ${props.theme.palette.primary.main}`)};
    }
    border:  ${(props) => (props.selected ? `3px solid ${props.theme.palette.primary.main}` : `1px solid ${props.theme.palette.primary.main}`)};
    border:  ${(props) => props.showError && props.selected && `3px solid ${props.theme.palette.error.main}`};
    border:  ${(props) => props.showError && !props.selected && `1px solid ${props.theme.palette.error.main}`};
    border:  ${(props) => props.disabled && props.selected && `3px solid ${props.theme.palette.action.disabledBackground}`};
    border:  ${(props) => props.disabled && !props.selected && `1px solid ${props.theme.palette.action.disabledBackground}`};
    color:  ${(props) => props.showError && `${props.theme.palette.error.main}`};
    color:  ${(props) => props.disabled && `${props.theme.palette.action.disabledBackground}`};
    pointer-events: ${(props) => props.disabled && 'none'};
    margin: 10px;
`;

export default function IQBigBoxItem({
  name,
  label,
  image,
  disabled,
  selected = false,
  onChange,
  onBlur,
  showError,
}: IQBigBoxItemProps) {
  const [actualSelected, setActualSelected] = React.useState(selected);
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
    clearErrors,
  } = useFormContext();
  const hasError = !!errors[name];

  const handleClick = (event) => {
    setActualSelected(!actualSelected);
    if (onChange) {
      onChange(!actualSelected, label);
    }
  };

  const handleBlur = (event) => {
    if (onBlur) { onBlur(); }
  };

  useEffect(() => {
    setActualSelected(selected);
  }, [selected]);

  return (
    <IQInvisibleButton
      selected={actualSelected}
      disabled={disabled}
      onClick={handleClick}
      onBlur={handleBlur}
    >
      <IQImgStyled src={image} width="134" height="134" />
      <p>{label}</p>
    </IQInvisibleButton>
  );
}
