import React, { Fragment } from 'react';
import styled from '@emotion/styled';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DescriptionIcon from '@mui/icons-material/Description';
import {
  List, ListItem, ListItemText, Divider,
} from '@mui/material';

export interface IQUploaderFilesProps {
  files: any[];
  onDelete?: (index: number) => void;
  [rest: string]: any;
}

const ItemsContainer = styled('div')`
  display: flex;
  gap: 15px;
  align-items: center;
`;

const DeleteOutlineIconStyled = styled(DeleteOutlineIcon)`
  color: ${(props) => (props.theme.palette.primary.main)};
  font-size: 22px;
`;

const IconContainer = styled('span')`
  height: 48px;
  width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) => (props.theme.palette.secondary.light)};
  border-radius: 50%;

  svg{
    font-size: 2rem;
    color: ${(props) => (props.theme.palette.secondary.main)};
  }
`;

const DeleteButton = styled('button')`
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
`;

const ImageStyled = styled('img')`
  width: 56px;
  height: 56px;
  object-fit: cover;
  border-radius: 4px;
`;

const FileSize = styled('span')`
  font-size: 0.75rem;
  font-family: 'Unify Sans';
  color: ${(props) => (props.theme.palette.text.secondary)};
`;

const ListStyled = styled(List)(({ theme }) => ({
  '& .MuiListItem-root': {
    display: 'flex',
    justifyContent: 'space-between',
    background: theme.palette.common.white,
  },
  '& .MuiTypography-root': {
    fontSize: ' 1.125rem',
  },
  '& .MuiListItemText-root': {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  '& .MuiListItemText-primary': {
    display: 'inline-block',
    width: '250px',
    whiteSpace: 'nowrap',
    overflow: 'hidden !important',
    textOverflow: 'ellipsis',
  },
}));

function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
}

export default function IQUploaderFiles({ files, onDelete }: IQUploaderFilesProps) {
  const renderPreview = (file) => {
    if (['image/png', 'image/jpeg'].includes(file.type)) {
      return <ImageStyled src={URL.createObjectURL(file)} alt={file.name} />;
    }
    if (file.type === 'application/pdf') {
      return (
        <IconContainer>
          <DescriptionIcon />
        </IconContainer>
      );
    }
    if (file.type === 'video/mp4') {
      return (
        <IconContainer>
          <PlayCircleOutlineIcon />
        </IconContainer>
      );
    }
    return (
      <IconContainer>
        <DescriptionIcon />
      </IconContainer>
    );
  };

  return (
    <ListStyled>
      {files.map((file, index) => (
        <Fragment key={index}>
          <ListItem>
            <ItemsContainer>
              {renderPreview(file)}
              <ListItemText primary={file.name} />
            </ItemsContainer>
            <ItemsContainer>
              <FileSize>{formatBytes(file.size)}</FileSize>
              <DeleteButton type="button" onClick={() => onDelete(index)}>
                <DeleteOutlineIconStyled />
              </DeleteButton>
            </ItemsContainer>
          </ListItem>
          {index !== files.length - 1 && <Divider />}
        </Fragment>
      ))}
    </ListStyled>
  );
}
