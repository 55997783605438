import React from 'react';
import styled from '@emotion/styled';
import CheckIcon from '@mui/icons-material/Check';

interface IItem {
  id: string;
  title: string;
  uri: string;
}

export interface IQAssetsGridProps {
  data?: IItem[];
  selectedAssets?: IItem[];
  handleSelect?: (_item: IItem) => void;
  columnCount?: number;
  assetType?: 'images' | 'videos';
}

const ImageContainer = styled.div<IQAssetsGridProps>`
  overflow: auto;
  line-height: 0;
  column-count: ${({ columnCount }) => columnCount};
  column-gap: 8px;
`;

const AssetContainer = styled.div<{ isSelected: boolean }>`
  cursor: pointer;
  border: 2px solid transparent;
  border: 2px solid ${({ isSelected, theme }) => (isSelected ? theme.palette.primary.main : 'transparent')};
  position: relative;
  margin-bottom: 6px;
`;

const Image = styled('img')`
  width: 100%;
  height: 100%;
`;

const Video = styled('video')`
  width: 100%;
  height: 100%;
`;

const CheckContainer = styled('span')<{ isSelected: boolean }>`
  display: ${({ isSelected }) => (isSelected ? 'inline-block' : 'none')};
  position: absolute;
  background: ${({ theme }) => theme.palette.primary.main};
  bottom: 0;
  right: -1px;
  left: 0;
  text-align: center;
  color: ${({ theme }) => (theme.palette.common.white)};
  padding: 4px 0;
`;

export const IQAssetsGrid = ({
  data,
  selectedAssets,
  handleSelect,
  columnCount,
  assetType,
}: IQAssetsGridProps) => {
  const playVideo = (e) => {
    e?.target?.play();
  };

  const stopVideo = (e) => {
    e?.target?.pause();
  };
  const renderContent = (item) => {
    if (assetType === 'images') {
      return (
        <Image onClick={() => handleSelect(item)} src={item.uri} alt="" />
      );
    }
    return (
      <Video
        onMouseOver={playVideo}
        onMouseOut={stopVideo}
        onClick={() => handleSelect(item)}
        src={item.uri}
        loop
      />
    );
  };
  return (
    <ImageContainer columnCount={columnCount}>
      {data.map((item) => (
        <AssetContainer
          key={item.id}
          isSelected={selectedAssets.map(({ id }) => id).includes(item.id)}
        >
          {renderContent(item)}
          <CheckContainer isSelected={selectedAssets.map(({ id }) => id).includes(item.id)}>
            <CheckIcon />
          </CheckContainer>
        </AssetContainer>
      ))}
    </ImageContainer>
  );
};
export default IQAssetsGrid;
