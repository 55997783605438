import React from 'react';
import {
  AppBar,
  Container,
  Toolbar,
} from '@mui/material';
import styled from '@emotion/styled';

const AppBarStyled = styled(AppBar)(({ theme }) => ({
  display: 'flex',
  background: theme.palette.background.default,
  boxShadow: 'none',
  // borderBottom: `1px solid ${theme.palette.text.tint}`, // semantic error TS2339: Property 'tint' does not exist on type 'TypeText'. Otherwise color is correct according to spec
  borderBottom: `1px solid ${theme.palette.text.primary}`,
  padding: '0',
}));

export interface IQTopNavContainerProps {
  children?: React.ReactNode;
}

export default function IQTopNavContainer({
  children,
}: IQTopNavContainerProps) {
  return (
    <AppBarStyled position="static">
      <Container maxWidth="xl" disableGutters>
        <Toolbar disableGutters sx={{ minHeight: '0 !important' }}>
          {children}
        </Toolbar>
      </Container>
    </AppBarStyled>
  );
}
