import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { AppBar } from '@mui/material';
import styled from '@emotion/styled';
import IQDesktopHeader from '../IQDesktopHeader/IQDesktopHeader';
import IQMobileHeader from '../IQMobileHeader/IQMobileHeader';
import { IQHeaderItemBadgeProps } from '../IQHeaderItemBadge/IQHeaderItemBadge';

export interface IQHeaderProps {
  logoSrc: string,
  logoOnClickUrl: string,
  logoOnClick: React.MouseEventHandler<HTMLButtonElement>,
  links: IQHeaderLink[],
  handleInternalLinks?: (relativeUrl: string, currentScreen: string) => void,
  logoAlt?: string,
  children?: React.ReactNode,
}

export interface IQLink {
  id: string,
  labelText: string,
  url?: string,
  isRootUrl?: boolean,
  relativeUrl?: string,
  currentScreen?: string,
  isInternal?: boolean,
  newTab?: boolean,
  rightIconUrl?: string,
  hide?: boolean,
  cbOnClick?: () => void,
}

export interface IQHeaderLink extends IQLink {
  badge?: IQHeaderItemBadgeProps,
  dropdownLinks?: IQLink[],
  isOnRightSide?: boolean,
}

export const MenuIcon = styled('img')(({ theme }) => ({
  color: theme.palette.common.white,
  width: '12px',
  height: '12px',
}));

const HeaderWrapper = styled(AppBar)(({ theme }) => ({
  height: '64px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'left',
  verticalAlign: 'middle',
  boxShadow: 'none',
  border: 'none',
  background: theme.palette.common.black,
  padding: theme.spacing(1.5, 4, 0, 4),
  justifyContent: 'flex-start',
  color: theme.palette.common.white,
  [theme.breakpoints.down('md')]: {
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(1),
  },
}));

const LogoImgWrapper = styled('img')(({ theme }) => ({
  width: 125,
  marginRight: theme.spacing(4),
  cursor: 'pointer',
}));

export const IQHeader = ({
  logoSrc,
  logoOnClickUrl,
  logoOnClick,
  links,
  logoAlt = 'LocalIQ Logo.',
  handleInternalLinks,
  children,
}: IQHeaderProps) => {
  const [activeLink, setActiveLink] = useState(null);

  const handleLinkClick = (link) => {
    const { url, relativeUrl, currentScreen, isInternal, newTab, cbOnClick } = link;

    if (cbOnClick) {
      cbOnClick();
    }

    if (isInternal) {
      handleInternalLinks(relativeUrl, currentScreen);
      setActiveLink(link);
    } else {
      if (newTab) {
        window.open(url, '_blank');
      } else {
        window.location = url;
      }
    }
  };

  const handleLogoClick = (e) => {
    if (logoOnClick) {
      logoOnClick(e);
    }
  };

  useEffect(() => {
    // Auto-detect active link
    const currentUrl = window.location.pathname;
    let foundLink = links.find((l) => (
      currentUrl.includes(l?.url)
      || currentUrl.includes(l?.relativeUrl)
    ));
    if (!foundLink) {
      const searchedDropdownLinks = links.filter(l => !!l.dropdownLinks)
        .map(l => l.dropdownLinks)
        .flat(1);
      foundLink = searchedDropdownLinks
        .find((l) => currentUrl.includes(l?.url) || currentUrl.includes(l?.relativeUrl));
    }

    if (foundLink) {
      setActiveLink(foundLink);
    } else {
      foundLink = links.find((l) => l?.isRootUrl);
      if (foundLink) {
        setActiveLink(foundLink);
      }
    }
  }, []);

  return (
    <HeaderWrapper>
      <a href={logoOnClickUrl} onClick={handleLogoClick} style={{ display: 'flex' }}>
        <LogoImgWrapper src={logoSrc} alt={logoAlt} />
      </a>
      {!isMobile && (<IQDesktopHeader activeLink={activeLink} links={links} handleLinkClick={handleLinkClick}>{children}</IQDesktopHeader>)}
      {isMobile && (<IQMobileHeader activeLink={activeLink} links={links} logoSrc={logoSrc} handleLinkClick={handleLinkClick}>{children}</IQMobileHeader>)}
    </HeaderWrapper>
  )
};

export default IQHeader;
