import React, {
  useEffect, useState, useCallback,
} from 'react';
import { useFormContext, Controller } from 'react-hook-form';

import {
  Autocomplete, TextField, Popper, PopperProps, ListItem,
} from '@mui/material';
import { KeyboardArrowUp } from '@mui/icons-material';
import styled from '@emotion/styled';

import { IQTheme } from '../../styles/IQTheme';
import { IQLabelTooltip } from '../../tooltip/IQLabelTooltip/IQLabelTooltip';

export interface Props {
  label: boolean;
  labelText?: string;
  labelTooltipText?: string;
  required?: boolean;
  labelIcon?: SVGElement;
  dropdownItems: DropdownItem[];
  name: string;
  defaultValue: string;
  fullWidth?: boolean;
  disableClear?: boolean;
  placeholder?: string;
}

type DropdownItem = { label: string; value: string };

const StyledAutocomplete = styled(Autocomplete)(
  ({ theme, fullWidth }: { theme: typeof IQTheme, fullWidth: boolean }) => `
  max-width: ${fullWidth ? 0 : '452px'};
  max-height: ${fullWidth ? 0 : '40px'};
  min-width: ${fullWidth ? '100%' : 0};

  .MuiFormControl-root {
    min-width: 452px;
    max-height: 40px;
  }

  label {
    color: ${theme.palette.text.primary};
    padding: 0 8px 0 3px;
    max-width: 80%;
    margin-top: -8px;
  }

  fieldset {
    border-color: ${theme.palette.text.primary};
  }

  .MuiInputLabel-root.Mui-focused {
    color: ${theme.palette.text.primary};
  }

  .MuiInputBase-root {
    background-color: ${theme.palette.common.white};
    padding: 8px 11px;
    color: ${theme.palette.text.primary};
    font-family: ${theme.typography.fontFamily};
    font-style: normal;
    font-weight: ${theme.typography.fontWeightRegular};
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.25px;
    max-height: 40px;
  }

  .MuiInputBase-root.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-width: 1px;
    color: ${theme.palette.text.primary};
    border-radius: 2px 2px 0 0;
  }

  .MuiInputBase-input {
    padding: 0;
    max-width: 90%;
    margin-top: -7px;
  }

  .MuiAutocomplete-hasPopupIcon {
    padding-right: 0;
  }

  .MuiAutocomplete-endAdornment {
    padding-left: 8px;
    transform: rotate(180deg);
  }

  .MuiAutocomplete-popupIndicatorOpen {
    color: ${theme.palette.primary.main};
  }
}
`,
);

const StyledPopper = styled(Popper)(({ theme } :{ theme: typeof IQTheme }) => `
  font-family: ${theme.typography.fontFamily};
  font-style: normal;
  font-weight: ${theme.typography.fontWeightRegular};
  font-size: 16px;
  line-height: 24px;

  letter-spacing: 0.25px;

  .MuiPaper-root {
    border: 1px solid ${theme.palette.primary.main};
    border-top: 0;
    width: calc(100% - 2px);
    border-radius: 0 0 2px 2px;
  }

  .MuiAutocomplete-listbox {
    min-height: 40px !important;
    padding: 0;
  }

  .MuiAutocomplete-option {
    padding: 0 8px 0 16px !important;
    min-height: 40px;

    span {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      min-width: 0;
    }
  }

  .MuiAutocomplete-option.Mui-focused {
    background-color: ${theme.palette.common.white};
  }

  .MuiAutocomplete-option:hover {
    background-color: ${theme.palette.secondary.light} !important;
  }
`);

const IQDropdown = ({
  defaultValue,
  dropdownItems,
  label,
  name,
  disableClear,
  fullWidth,
  labelIcon,
  labelText,
  labelTooltipText,
  placeholder,
  required,
}: Props) => {
  const [dropdownPlaceholder, setDropdownPlaceholder] = useState(placeholder);
  const { control, watch } = useFormContext();
  const inputValue = watch(name);

  useEffect(() => {
    if (inputValue && dropdownPlaceholder === placeholder) {
      setDropdownPlaceholder('');
    }
  }, [inputValue]);

  // eslint-disable-next-line max-len
  const CustomPopper = useCallback((props) => <StyledPopper {...props} theme={IQTheme} />, []);

  return (
    <React.Fragment>
      {label && (
        <IQLabelTooltip
          labelText={labelText}
          hasError={false}
          theme={IQTheme}
          tooltipText={labelTooltipText}
          required={required}
          Icon={labelIcon || undefined}
        />
      )}
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field }) => (
          <StyledAutocomplete
            {...field}
            openOnFocus
            theme={IQTheme}
            popupIcon={<KeyboardArrowUp />}
            // @ts-ignore
            getOptionLabel={(option) => (typeof option === 'object' ? option.label : '')}
            options={dropdownItems}
            fullWidth={fullWidth}
            disableClearable={disableClear}
            PopperComponent={CustomPopper}
            value={field.value?.label}
            inputValue={field.value?.label}
            onChange={(e, newValue) => field.onChange((newValue as DropdownItem).label)}
            onInputChange={(e, newValue) => field.onChange(newValue)}
            // @ts-ignore
            isOptionEqualToValue={(option, value) => option.label === value.label}
            renderInput={(params) => (
              <TextField
                {...params}
                InputLabelProps={{ shrink: false }}
                label={dropdownPlaceholder}
                fullWidth={fullWidth}
              />
            )}
            renderOption={(props, option) => (
              <ListItem {...props}>
                <span>
                  {// @ts-ignore
                    option.label
                  }
                </span>
              </ListItem>
            )}
          />
        )}
      />
    </React.Fragment>
  );
};

export default IQDropdown;
