/* eslint-disable linebreak-style */
import React from 'react';
import {
  FormControl,
  FormHelperText,
  useTheme,
  Typography,
  Select,
  InputAdornment,
  SelectProps,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useController, useFormContext } from 'react-hook-form';
import styled from '@emotion/styled';
import '../../styles/website_fonts.css';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useValidationContext } from '../../providers/ValidationProvider';
import schemaValidate from '../../services/schemaValidate';
import IQLabelTooltip from '../../tooltip/IQLabelTooltip/IQLabelTooltip';
import IQFormLabel from '../../input/IQFormLabel/IQFormLabel';

interface ISelectItem {
  value: string;
  description: string;
  icon?: string
}

export interface IQFormSelectProps extends SelectProps {

  /**
   * Label for the input field
   */
  labelText: string;

  /**
   * Label fontWeight for the input field
   */
  fontLabelWeight?: string;

  /**
   * Values to populate Select
   */
  items: ISelectItem[];

  /**
   * Name field used in the react hook form and yup schema
   */
  name: string;

  defaultValue?: string;

  /**
   * Icon to show at the start or end of the input field
   */
  adornmentIcon?: any;

  /**
   * Whether to use the standard error text message. Set to false if
   * using a custom error message
   */
  showError?: boolean;

  /**
   * Descriptive text displayed as a note below the input
   */
  subDescription?: string;

  /**
   * Tooltip text, if present a standard ? icon will be used with tooltip functionality.
   */
  tooltipText?: string;

  /**
   * Where should the tooltip be positioned.
   */

  tooltipPlacement?: 'bottom' | 'right' | 'top' | 'left';

  /**
   * Tooltip's bottom padding.
   */

  tooltipPaddingBottom?: number;

  /**
   * Placeholder text for the input
   */
  placeholder?: string;

  /**
   * Whether this field should be disabled
   */
  disabled?: boolean;

  /**
   * Whether this is full width
   */
  fullWidth?: boolean;

  spaceBottomMultiplier?: number;

  params?: any
  [rest: string]: any

}

const FormErrorHelper = styled(FormHelperText)(({ theme }) => ({
  color: theme.palette.error.main,
  marginTop: 4,
}));

const LocationSelectWrapper = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: '.7rem'
}));

const SelectStyled = styled(Select)(({ theme }) => ({

  '& .MuiSelect-icon': {
    color: `${theme.palette.text.primary}`,
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: `${theme.palette.text.primary}`,
  },
  '& .MuiSelect-select': {
    padding: '8.5px 14px 8.5px 0',
  },
  '&.Mui-disabled': {
    '.MuiSvgIcon-root': {
      color: `${theme.palette.action.disabledBackground}`,
    },
  },
  '&.Mui-focused': {
    color: `${theme.palette.primary.main}`,
    '.MuiSelect-icon': {
      color: `${theme.palette.primary.main}`,
    },
    '.MuiSvgIcon-root': {
      color: `${theme.palette.primary.main}`,
    },
  },
  '&.Mui-error': {
    color: theme.palette.error.main,
    '.MuiSelect-icon': {
      color: theme.palette.error.main,
    },
    '.MuiSvgIcon-root': {
      color: theme.palette.error.main,
    },
  },
}));

const SubDescriptionText = styled(Typography)({
  paddingTop: 12,
  fontFamily: 'Unify Sans',
  fontSize: 12,
  lineHeight: '16px',
});

/**
 * Component for a basic form input field
 *
 * See above IQFormSelectProps for param info
 */
export const IQFormSelect = ({
  labelText,
  name,
  placeholder,
  items,
  showError = true,
  subDescription,
  tooltipText = null,
  tooltipPlacement,
  tooltipPaddingBottom,
  disabled,
  adornmentIcon,
  defaultValue,
  params,
  fullWidth = false,
  spaceBottomMultiplier,
  fontLabelWeight,
  ...rest
}: IQFormSelectProps) => {
  const theme = useTheme();
  const { schema } = useValidationContext();
  const { control } = useFormContext();

  const { t } = useTranslation();

  const {
    field,
    formState: { errors },
  } = useController({
    name,
    control,
    rules: { validate: (value) => schemaValidate(value, name, schema, params, null) },
    defaultValue: defaultValue || '',
  });

  const hasError = !!errors[name];

  const doOnChange = (event, child) => {
    if (field?.onChange) {
      field.onChange(event);
    }

    if (rest?.onChange) {
      rest.onChange(event, child);
    }
  };

  const doOnBlur = (event) => {
    if (field?.onBlur) {
      field.onBlur();
    }

    if (rest?.onBlur) {
      rest.onBlur(event);
    }
  };
  return (
    <div>
      <FormControl error={hasError} fullWidth={fullWidth}>
        {tooltipText && (
          <IQLabelTooltip
            hasError={hasError}
            tooltipText={tooltipText}
            tooltipPlacement={tooltipPlacement}
            paddingBottom={tooltipPaddingBottom}
            labelText={labelText}
            theme={theme}
          />
        )}
        {!tooltipText && (
          <IQFormLabel
            spaceBottomMultiplier={spaceBottomMultiplier}
            hasError={hasError}
            theme={theme}
            fontLabelWeight={fontLabelWeight}
          >
            {labelText}
          </IQFormLabel>
        )}

        <SelectStyled
          {...rest}
          disabled={disabled}
          onChange={doOnChange} // send value to hook form
          onBlur={doOnBlur} // notify when input is touched/blur
          value={field.value} // input value
          name={field.name} // send down the input name
          inputRef={field.ref} // send input ref, so we can focus on input when error appear
          defaultValue={defaultValue}
          displayEmpty
          IconComponent={KeyboardArrowDownIcon}
          startAdornment={(
            <InputAdornment position="start" sx={{}}>
              {adornmentIcon}
            </InputAdornment>
          )}
        >
          <MenuItem disabled value="">
            {placeholder}
          </MenuItem>
          {items.map(({ value, description, icon = "" }) => {
            return (
              <MenuItem key={value} value={value}>
                <LocationSelectWrapper>
                  {(icon && !adornmentIcon) && <img src={icon} />}
                  {description}
                </LocationSelectWrapper>
              </MenuItem>
            )
          })}
        </SelectStyled>
        {showError && hasError && (
          <FormErrorHelper
            sx={{
              marginLeft: 0,
            }}
            role="alert"
          >
            {t(`${errors[name].message}`)}
          </FormErrorHelper>
        )}
      </FormControl>
      {subDescription && (
        <SubDescriptionText>{subDescription}</SubDescriptionText>
      )}
    </div>
  );
};

export default IQFormSelect;
