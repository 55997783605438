import React from 'react';
import styled from '@emotion/styled';

export interface IQLeftNavIconNeonProps {
  IQLeftNavIcon: string
}

const LeftNavLogo = styled('div')`
  text-align: center;
  margin-bottom: 40px;
`;

export const IQLeftNavIconNeon = ({ IQLeftNavIcon } : IQLeftNavIconNeonProps) => (
  <LeftNavLogo>
    <img src={IQLeftNavIcon} alt="Main LeftNav Icon" />
  </LeftNavLogo>
);

export default IQLeftNavIconNeon;
