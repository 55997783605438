import React, { FC, useState } from 'react';
import {
 Button, Typography, Modal, Box, useTheme, Stack, RadioGroup 
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import styled from '@emotion/styled';
import { FormProvider, useForm } from 'react-hook-form';
import { ValidationProvider } from '../../providers/ValidationProvider';
import IQButtonLink from '../../buttons/IQButtonLink/IQButtonLink';
import IQAddressCard from './IQAddressCard';
import { IQAddressMapboxSuggestionProps } from '../../AddressMapbox/IQAddressMapbox/IQAddressMapbox';

export interface IQAddressVerificationModalProps {
  open: boolean;
  handleClose: (event) => any;
  onSubmit: (selectedAddress) => void;
  onEdit: (selectedAddress) => void;
  addresses: {
    entered: IQAddressMapboxSuggestionProps,
    suggested: IQAddressMapboxSuggestionProps,
  };
}

const ModalBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  padding: `${theme.spacing(3)}`,
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.spacing(3),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
}));

const CloseIconStyled = styled(CloseIcon)(() => ({
  position: 'absolute',
  right: 20,
  cursor: 'pointer',
}));

const SubmitButton = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  marginTop: theme.spacing(3.5),
}));

const ModalTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.info.dark,
  textAlign: 'center',
  marginBottom: 30,

  '&:after': {
    content: '""',
    width: '100%',
    height: 2,
    background: `linear-gradient(90deg, ${theme.palette.success.main} 7%, ${theme.palette.secondary.main} 78%)`,
    marginTop: 30,
    display: 'block',
  },
}));

const ModalSubtitle = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  fontSize: 16,
  color: theme.palette.text.primary,
  fontWeight: 600,
}));

const RadioGroupStyled = styled(RadioGroup)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-around',
}));

const IQAddressVerificationModal: FC<IQAddressVerificationModalProps> = ({
  open,
  handleClose,
  onSubmit,
  onEdit,
  addresses,
}) => {
  const theme = useTheme();
  const [selectedAddress, setSelectedAddress] = useState<string>(addresses.suggested.id);

  const methods = useForm({
    mode: 'all',
    defaultValues: {
      enteredAddress: addresses.entered.id,
      suggestedAddress: addresses.suggested.id,
    },
  });

  const schema = {
    yupValidations: {},
    customValidations: {},
  };

  const { handleSubmit } = methods;

  const onFormSubmit = () => {
    onSubmit(selectedAddress);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="address-verification-modal-title"
      aria-describedby="address-verification-modal-description"
    >
      <ModalBox>
        <CloseIconStyled onClick={handleClose} />
        <Box>
          <ModalTitle id="address-verification-modal-header" variant="h4">
            Verify your Address
          </ModalTitle>
          <ModalSubtitle>
            To ensure address accuracy, review the changes highlighted below.
          </ModalSubtitle>
        </Box>
        <Stack direction="column">
          <ValidationProvider schema={schema}>
            <FormProvider {...methods}>
              <form onSubmit={handleSubmit(onFormSubmit)}>
                <RadioGroupStyled
                  onChange={(event) => setSelectedAddress(event.target.value)}
                  value={selectedAddress}
                >
                  <IQAddressCard
                    title="Address Entered"
                    address={addresses.entered}
                    isSelected={selectedAddress === addresses.entered.id}
                    name="enteredAddress"
                  />
                  <IQAddressCard
                    title="Suggested Address"
                    address={addresses.suggested}
                    isSelected={selectedAddress === addresses.suggested.id}
                    name="suggestedAddress"
                  />
                </RadioGroupStyled>
                <Stack
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <SubmitButton type="submit" color="primary" variant="contained">
                    Use Selected Address
                  </SubmitButton>
                  <IQButtonLink onClick={() => onEdit(selectedAddress)}>
                    Edit Address
                  </IQButtonLink>
                </Stack>
              </form>
            </FormProvider>
          </ValidationProvider>
        </Stack>
      </ModalBox>
    </Modal>
  );
};

export default IQAddressVerificationModal;
