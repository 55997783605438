import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { useController, useFormContext } from 'react-hook-form';
import { FormHelperText } from '@mui/material';
import IQBigBoxCheckbox, { IQBigBoxCheckboxProps } from './IQBigBoxCheckbox';
import { useValidationContext } from '../../providers/ValidationProvider';
import schemaValidate from '../../services/schemaValidate';

const FormErrorHelper = styled(FormHelperText)(({ theme }) => ({
  color: theme.palette.error.main,
  marginTop: 4,
}));
export interface IQBigBoxMultipleSelectProps {
  name: string;
  disabled?: boolean;
  hideErrors?: boolean;
  justifyContent?: string;
  optionsList: IQBigBoxCheckboxProps[];
  onChange?: (options: IQBigBoxCheckboxProps[]) => void;
  onBlur?: () => void;
  changeSubdescriptionOnCheck?: {
    keepSubdescriptionOn?: string;
    selected: string;
    available: string;
  }
}

function evaluateSubdescription(checkbox, changeOnCheck) {
  if (!changeOnCheck) return checkbox.subDescription;
  // eslint-disable-next-line max-len
  if (checkbox.subDescription === changeOnCheck.keepSubdescriptionOn) return checkbox.subDescription;
  return checkbox.checked ? changeOnCheck.selected : changeOnCheck.available;
}

export default function IQBigBoxMultipleSelect({
  optionsList,
  name,
  disabled,
  justifyContent,
  hideErrors = false,
  onChange = () => {},
  onBlur = () => {},
  changeSubdescriptionOnCheck,
}: IQBigBoxMultipleSelectProps) {
  const { schema } = useValidationContext();
  const { formState: { errors }, control } = useFormContext();
  const { t } = useTranslation();
  const {
    field,
  } = useController({
    name,
    control,
    rules: {
      validate: (value) => schemaValidate(value, name, schema, null, null),
    },
    defaultValue: [],
  });

  const CheckboxesContainer = styled.div`
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
    justify-content: ${justifyContent};
  `;

  const [checkBoxes, setCheckBoxes] = useState(optionsList.map((item) => ({
    ...item,
    checked: item.checked || false,
  })));

  const handleOnChange = (event) => {
    const { checked, id } = event.target;

    const updatedCheckBoxes = checkBoxes.map((checkBox) => {
      if (checkBox.id === String(id)) return { ...checkBox, checked };
      return checkBox;
    });

    const selectedValues = updatedCheckBoxes.filter((options) => options.checked);

    setCheckBoxes(updatedCheckBoxes);
    if (field?.onChange) {
      field.onChange(selectedValues);
    }

    if (onChange) {
      onChange(selectedValues);
    }
  };

  const handleOnBlur = () => {
    if (field?.onBlur) {
      field?.onBlur();
    }
    if (onBlur) {
      onBlur();
    }
  };

  return (
    <div>
      <CheckboxesContainer
        ref={field.ref}
        onBlur={handleOnBlur}
      >
        {checkBoxes.map((checkBox) => (
          <IQBigBoxCheckbox
            onChange={handleOnChange}
            checked={checkBox.checked}
            label={checkBox.label}
            name={checkBox.name}
            toolTipIcon={checkBox.toolTipIcon}
            svgIcon={checkBox.svgIcon}
            fontIcon={checkBox.fontIcon}
            subDescription={evaluateSubdescription(checkBox, changeSubdescriptionOnCheck)}
            key={checkBox.label}
            id={checkBox.id}
            disabled={disabled || checkBox.disabled}
            showError={!!errors[name]}
            tooltipText={checkBox.tooltipText}
            tooltipPlacement={checkBox.tooltipPlacement}
            backgroundColor={checkBox.backgroundColor}
            required={false}
          />
        ))}
      </CheckboxesContainer>
      <FormErrorHelper role="alert">{!hideErrors && errors[name] && t(`${errors[name].message}`)}</FormErrorHelper>
    </div>
  );
}
