import { Tab, Theme } from '@mui/material';
import React from 'react';

import styled from '@emotion/styled';
import { TopNavStatusEnum } from '../enums/IQTopNavStepStatusEnum';
import IQTopNavStepBadge from '../IQTopNavStepBadge/IQTopNavStepBadge';

export interface IQTabStyledProps {
  status: TopNavStatusEnum,
  selected: boolean,
}

const TabStyled = styled(Tab) <IQTabStyledProps>`
  text-transform: none;
  min-height: 0;
  padding: 12px 16px;
  opacity: 1;

  svg {
    margin-right: 8px;
  };

  color: ${(props) => (
    props.status !== TopNavStatusEnum.NotStarted
      ? props.theme.palette.primary.main
      : props.theme.palette.text.primary
  )};

  &:hover {
    background-color: ${(props) => props.theme.palette.background.default};
  };

  &:focus {
    outline: 2px dashed ${(props) => props.theme.palette.primary.main};
    outline-offset: -2px;
  };;

  border-bottom: ${(props) => props.selected && `2px solid ${props.theme.palette.primary.main}`};

  svg {
    path {
      fill: ${(props) => (props.status === TopNavStatusEnum.NotStarted
    ? props.theme.palette.text.primary
    : props.theme.palette.primary.main
  )};

    }
  }
`;

export type StepType = {
  id: number;
  name: string;
  status: TopNavStatusEnum,
};

export interface IQTopNavStepProps {
  step: StepType,
  selectStep: (step: number) => void,
}

export default function IQTopNavStep({
  step,
  selectStep,
}: IQTopNavStepProps) {
  return (
    <TabStyled
      label={step.name}
      icon={<IQTopNavStepBadge status={step.status} />}
      iconPosition="start"
      disableRipple
      selected={step.status === TopNavStatusEnum.Current}
      status={step.status}
      onClick={() => selectStep(step.id)}
    />
  );
}
