import styled from '@emotion/styled';
import { Card, Typography } from '@mui/material';
import React from 'react';
import PropTypes, { InferProps } from 'prop-types';

const StyledCardHeader = styled(Typography)`
  text-align: left;
  color: ${(props) => props.theme.palette.info.dark};
  size: 24px;
  line-height: 30px;
  font-weight: 600;
  margin-bottom: 15px;
`;

const StyledCardContent = styled(Card)`
  padding: 24px;
`;

const CardDescription = styled('label')(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: 16,
  lineHeight: '24px',
  letterSpacing: '0.25px',
  fontFamily: 'Unify Sans SemiBold',
  paddingBottom: theme.spacing(1),
}));

/**
 *
 * Styled card component with header
 */
export const IQStyledCard = ({
  headerText,
  description,
  theme,
  children
}: InferProps<typeof IQStyledCard.propTypes>) => (
  <Card>
    <StyledCardContent>
      <StyledCardHeader variant="h4">
        {headerText}
      </StyledCardHeader>
      {description && (
        <CardDescription theme={theme}>
          {description}
        </CardDescription>
      )}
      {children}
    </StyledCardContent>
  </Card>
);

IQStyledCard.propTypes = {
  /**
   * The text to be displayed as header of the card
   */
  headerText: PropTypes.string.isRequired,

  /**
   * The text to be displayed as description of the card
   */
  description: PropTypes.string,

  /**
   * The theme from createTheme received by the parent app
   */
  theme: PropTypes.object.isRequired,

  /**
   * The content to be displayed as body of the card
   */
  children: PropTypes.object,
};

export default IQStyledCard;
