import React from 'react';
import { Chip } from '@mui/material';
import styled from '@emotion/styled';
import PropTypes, { InferProps } from 'prop-types';

/**
 * Component for chip related to tier rendering purpose
 *
 * See below prop types for param info
 */
const StyledTierChip = styled(Chip)`
  border-radius: 2px;
  color:  ${({ theme }) => (theme.palette.common.white)};
  font-size: 12px;
  font-weight: 600;
  height: 16px;
  line-height: 15px;
  padding: 4px;
`;

export const IQTierChip = ({
  color,
  size,
  text,
  theme,
  disabled = false,
  sx,
}: InferProps<typeof IQTierChip.propTypes>) => (
  <StyledTierChip
    sx={{
      backgroundColor: color === 'success' || !color ? (theme.palette.success.dark) : color,
      cursor: disabled ? 'not-allowed' : 'inherit',
      ...sx,
    }}
    label={text}
    size={size}
  />
);

IQTierChip.propTypes = {

  /**
   * Background chip color, hex or rgb, 'success' renders the default color
   */
  color: PropTypes.string,

  /**
   * Chip size, can be 'small' or 'medium'
   */
  size: PropTypes.string,

  /**
   * Text inside the chip
   */
  text: PropTypes.string.isRequired,

  /**
   * Param to determine if the chip is disabled (true) or not (False)
   */
  disabled: PropTypes.bool,

  /**
   * Style object for customizing inline styles
   */
  sx: PropTypes.object,
};

export default IQTierChip;
