import React, {
  FocusEventHandler, useMemo, useState,
} from 'react';
import { ClickAwayListener, Stack, Theme } from '@mui/material';
import styled from '@emotion/styled';
import '../../styles/website_fonts.css';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useController, useFormContext } from 'react-hook-form';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { css } from '@emotion/react';
import { useValidationContext } from '../../providers/ValidationProvider';
import { IQFormLayout } from '../IQFormLayout/IQFormLayout';
import schemaValidate from '../../services/schemaValidate';
import { getError } from '../../error/ErrorFunctions';

export interface IQDatePickerProps {
  id: string;
  labelText: string;
  name: string;
  fontLabelWeight?: string;
  onFocus?: FocusEventHandler<HTMLInputElement>,
  onBlur?: FocusEventHandler<HTMLInputElement>;
  showError?: boolean;
  obscureValue?: boolean;
  directErrorReference?: { message: any };
  subDescription?: string;
  tooltipText?: string;
  placeholder?: string;
  disabled?: boolean;
  tooltipPlacement?: 'bottom' | 'right' | 'top' | 'left';
  fullWidth?: boolean;
  theme: Theme;
  params?: any;
  customValidation?: any;
  maxLength?: number;
  disablePast?: boolean;
  dateFormat?: string;
  minDate?: Date;
  maxDate?: Date;
  openPickerOnClick?: boolean;
  [rest: string]: any;
  tooltipPaddingBottom?: number;
}

type StateProps = {
  open?: boolean,
  disabled: boolean,
  hasError: boolean
};

const InputWrapper = styled('div') <StateProps>`
  background-color: ${(props) => props.theme.palette.common.white};
  border-width: 1px;
  border-style: solid;
  border-radius: 2px;
  border-color: ${(props) => (
    props.disabled
      ? props.theme.palette.action.disabledBackground
      : props.hasError
        ? props.theme.palette.error.main
        : props.theme.palette.text.primary
  )};
  border-color: ${(props) => props.open && props.theme.palette.primary.main};
  &:focus-within {
    border-color: ${(props) => (
    props.hasError
      ? props.theme.palette.error.main
      : props.theme.palette.primary.main
  )};
  overflow: hidden;
  box-sizing: border-box;
  }

  .MuiInputBase-input {
    color: ${({ theme }) => (theme.palette.text.secondary)};
    padding: 8.5px 16px;
  }

  .MuiPickersArrowSwitcher-spacer {
    width: 0 !important;
  }

  .MuiButtonBase-root {
    margin-right: 7px;
  }

  ${({ hasError, theme }) => hasError && css`
    & .MuiSvgIcon-root {
      color: ${theme.palette.error.main};
    }
  `}
`;

export const IQDatePicker = ({
  labelText,
  fontLabelWeight,
  name,
  showError,
  directErrorReference,
  subDescription,
  tooltipPlacement,
  tooltipText = null,
  disabled,
  fullWidth = false,
  customValidation,
  tooltipPaddingBottom,
  params,
  disablePast,
  openPickerOnClick,
  minDate,
  maxDate,
  ...rest
}: IQDatePickerProps) => {
  const [open, setOpen] = useState(false);

  const { schema } = useValidationContext();
  const { control, register } = useFormContext();
  const {
    field,
    formState: { errors },
  } = useController({
    name,
    control,
    rules: { validate: (value) => schemaValidate(value, name, schema, params, customValidation) },
  });

  const { ref } = register(name, {
    validate: (value) => schemaValidate(value, name, schema, params, customValidation),
  });

  const hasError = useMemo(() => !!getError(errors, name), [errors, name]);

  const doOnChange = (value) => {
    if (field?.onChange) {
      field.onChange(value);
    }
    if (rest?.onChange) {
      rest.onChange(value);
    }
  };

  const doOnBlur = (event) => {
    if (field?.onBlur) {
      field.onBlur();
    }

    if (rest?.onBlur) {
      rest.onBlur(event);
    }
  };

  const handleClick = (e :React.MouseEvent<HTMLDivElement>) => {
    const { dataset } = e.currentTarget as HTMLDivElement;
    const { classList } = e.target as HTMLButtonElement;

    const isCalendar = dataset.id === 'datepicker-calendar';
    const isInputField = dataset.id === 'datepicker-input';
    const isDateButton = classList.contains('MuiPickersDay-root');

    if (!isCalendar && (!open && isInputField)) {
      setOpen((prev) => !prev);
    }

    if (isDateButton) {
      setOpen((prev) => !prev);
    }
  };

  const onClickAway = () => {
    if (openPickerOnClick) {
      setOpen(false);
    }
  };

  return (
    <IQFormLayout
      fullWidth={fullWidth}
      showError={hasError && showError}
      labelText={labelText}
      tooltipText={tooltipText}
      fontLabelWeight={fontLabelWeight}
      subDescription={subDescription}
      tooltipPlacement={tooltipPlacement}
      errorMessage={getError(errors, name)}
      tooltipPaddingBottom={tooltipPaddingBottom}
    >
      <ClickAwayListener onClickAway={onClickAway}>
        <InputWrapper onBlur={doOnBlur} hasError={hasError} disabled={disabled} open={open}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Stack
              data-id="datepicker-input"
              spacing={3}
              onClick={(e) => openPickerOnClick && handleClick(e)}
            >
              <DesktopDatePicker
                data-id="datepicker-calendar"
                {...(openPickerOnClick && { open })}
                components={{
                  OpenPickerIcon: CalendarTodayIcon,
                  SwitchViewIcon: KeyboardArrowDownIcon,
                }}
                InputProps={{
                  disableUnderline: true,
                }}
                PaperProps={openPickerOnClick && { tabIndex: undefined }}
                inputFormat={rest.dateFormat || 'MM/dd/yyyy'}
                inputRef={ref}
                value={field.value}
                onChange={doOnChange}
                {...({ disablePast })}
                minDate={minDate || new Date()}
                maxDate={maxDate}
                renderInput={(inputParams) => (
                  disabled
                    ? (
                      <TextField
                        {...inputParams}
                        variant="standard"
                        inputProps={{ readOnly: true }}
                        value={inputParams.inputProps.value}
                      />
                    )
                    : (
                      <TextField
                        {...inputParams}
                        variant="standard"
                        value={inputParams.inputProps.value}
                      />
                    )
                )}
              />
            </Stack>
          </LocalizationProvider>
        </InputWrapper>
      </ClickAwayListener>
    </IQFormLayout>
  );
};

export default IQDatePicker;
