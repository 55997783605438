import React from 'react';
import { Button } from '@mui/material';
import styled from '@emotion/styled';
import '../../styles/website_fonts.css';

export interface IQButtonLinkProps {
  // Whether to shift to the disabled state
  disabled?: boolean;
  // Icon to display on the left side of the button.
  startIcon?: any;
  // On click handler
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  // Button content
  children: string;
}

const ButtonStyled = styled(Button)`
  color: ${(props) => props.theme.palette.primary.main};
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: 14px;
  text-transform: none;
  text-decoration: underline;
  padding: 10px 15px;
  font-weight: 400;
  background-color: transparent;

  :hover {
    text-decoration: underline;
    background-color: transparent;
  }
`;

export const IQButtonLink = ({
  disabled = false,
  children,
  startIcon,
  onClick,
  ...props
}: IQButtonLinkProps) => (
  <ButtonStyled
    disabled={disabled}
    startIcon={startIcon}
    onClick={onClick}
    {...props}
  >
    {children}
  </ButtonStyled>
);

export default IQButtonLink;
