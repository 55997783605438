import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import { Theme, Tooltip } from '@mui/material';
import React, { ReactNode } from 'react';
import { isMobile } from 'react-device-detect';
import { HelpOutline } from '@mui/icons-material';
import { IQInfoTooltipWrapper } from '../IQInfoTooltipWrapper/IQInfoTooltipWrapper';
import '../../styles/website_fonts.css';

export interface IQLabelTooltipProps {
  tooltipText: string | ReactNode,
  labelText: string | React.ReactNode,
  hasError: boolean,
  Icon?: any,
  spacing?: number,
  required?: boolean,
  theme: Theme,
  paddingTop?: number,
  paddingBottom?: number,
  tooltipPlacement?: 'bottom' | 'right' | 'top' | 'left',
  arrow?: boolean,
}

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip children={undefined} title="" {...props} classes={{ popper: className }} />
))`
  & .MuiTooltip-tooltip {
    max-width: calc(208px - 17px);
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    background: ${(props) => props.theme.palette.text.primary};
    border-radius: 4px;
  }

  & .MuiTooltip-tooltipArrow {
    background: ${(props) => props.theme.palette.text.primary};
  }

  & .MuiTooltip-arrow {
    color: ${(props) => props.theme.palette.text.primary};
  }
`;

interface StyledLabelProps {
  hasError: boolean,
  theme: Theme,
  paddingTop?: number,
  paddingBottom?: number,
}

const StyledLabel = styled('label')<StyledLabelProps>`
  color: ${(props) => (props.hasError ? props.theme.palette.error.main : props.theme.palette.text.primary)};
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.25px;
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
  padding-bottom: ${(props) => props.paddingBottom && `${props.paddingBottom}px`};
  padding-top: ${(props) => props.paddingTop && `${props.paddingTop}px`};
  display: inline-block;
  max-height: 18px;
`;

export const IQLabelTooltip = ({
  tooltipText,
  labelText,
  hasError,
  Icon = HelpOutline,
  spacing = 1,
  required = true,
  tooltipPlacement,
  paddingTop,
  paddingBottom,
  arrow = true,
}: IQLabelTooltipProps) => {
  const theme = useTheme();
  return (
    <StyledLabel
      theme={theme}
      hasError={hasError}
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
    >
      {
        isMobile
          ? (
            <IQInfoTooltipWrapper
              CustomIcon={Icon}
              tooltipText={tooltipText}
            >
              {labelText}
            </IQInfoTooltipWrapper>
          )
          : (
            <React.Fragment>
              {labelText}
              {required && <span style={{ color: theme.palette.text.primary }}>{' *'}</span>}
              {tooltipText
                && (
                  <StyledTooltip
                    title={tooltipText}
                    placement={tooltipPlacement}
                    arrow={arrow}
                    className={undefined}
                  >
                    {typeof Icon === 'object'
                      ? (
                        <Icon sx={{
                          color: theme.palette.primary.main,
                          height: 18,
                          width: 18,
                          marginLeft: spacing ? theme.spacing(spacing) : theme.spacing(2),
                          position: 'relative',
                          top: 2,
                          cursor: 'pointer',
                        }}
                        />
                      ) : (
                        <span className="tooltip-icon">
                          <Icon />
                        </span>
                      )}
                  </StyledTooltip>
                )}
            </React.Fragment>
          )
        }
    </StyledLabel>
  );
}

export default IQLabelTooltip;
