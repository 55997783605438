import React, { useState, useEffect } from 'react';
import { Stack, Theme, Typography } from '@mui/material';
import styled from '@emotion/styled';
import { useFormContext } from 'react-hook-form';
import { IQTheme } from '../../styles/IQTheme';
import { IQDatePicker } from '../IQDatePicker/IQDatePicker';
import { useValidationContext } from '../../providers/ValidationProvider';
import schemaValidate from '../../services/schemaValidate';
import '../../styles/website_fonts.css';

type returnTypes = {
  start?: Date;
  end?: Date;
};

type textTypes = {
  start?: string;
  end?: string;
};

type labelTextTypes = {
  separator: string;
} & textTypes;

type HasErrorType = {
  hasError: boolean;
};

export interface IQDateRangePickerProps {
  id: textTypes;
  name: textTypes;
  labelText: labelTextTypes;
  fontLabelWeight?: string;
  showError?: boolean;
  errorMessages?: textTypes;
  obscureValue?: boolean;
  directErrorReference?: { message: any };
  subDescription?: textTypes;
  tooltipText?: textTypes;
  placeholder?: textTypes;
  disabled?: boolean;
  tooltipPlacement?: 'bottom' | 'right' | 'top' | 'left';
  fullWidth?: boolean;
  theme: Theme;
  params?: any;
  customValidation?: any;
  maxLength?: number;
  disablePast?: boolean;
  dateFormat?: string;
  minDate?: Date;
  openPickerOnClick?: boolean;
  [rest: string]: any;
  tooltipPaddingBottom?: number;
  defaultValues?: returnTypes;
}

const Separator = styled('div')<HasErrorType>(({ hasError }) => ({
  display: 'flex',
  p: {
    alignSelf: 'end',
    marginBottom: hasError ? 36 : 10,
  },
}));

export const IQDateRangePicker = ({
  labelText,
  name,
  id,
  subDescription,
  tooltipText = null,
  placeholder,
  params,
  customValidation,
  errorMessages,
  defaultValues = { start: null, end: null },
  ...rest
}: IQDateRangePickerProps) => {
  const [ hasError, setHasError ] = useState<boolean>(false);
  const { schema } = useValidationContext();
  const { setValue, watch, clearErrors, unregister, register, formState } = useFormContext();
  const startDate= watch(name.start);
  const endDate= watch(name.end);

  useEffect(() => {
    setValue(name.start, defaultValues.start);
    setValue(name.end, defaultValues.end);

    return () => {
      unregister([name.start, name.end]);
    };
  }, []);

  useEffect(() => {
    setHasError(formState.errors && Object.keys(formState.errors).length > 0);
  }, [startDate, endDate]);

  register(name.start, {
    validate: (value) => schemaValidate(value, name.start, schema, params, customValidation),
    max: { value: endDate, message: errorMessages?.start },
  });

  register(name.end, {
    validate: (value) => schemaValidate(value, name.end, schema, params, customValidation),
    min: { value: startDate, message: errorMessages?.end },
  });

  const doOnChange = (value, index) => {
    clearErrors();
    setValue(name[index], value);
  };

  return (
    <Stack direction="row" spacing={2}>
      <IQDatePicker
        id={id.start}
        name={name.start}
        labelText={labelText.start}
        theme={IQTheme}
        openPickerOnClick
        onChange={value => doOnChange(value, 'start')}
        placeholder={placeholder?.start}
        subDescription={subDescription?.start}
        tooltipText={tooltipText?.start}
        showError={hasError}
        {...rest}
      />
      <Separator hasError={hasError}>
        <Typography>{labelText.separator}</Typography>
      </Separator>
      <IQDatePicker
        id={id.end}
        name={name.end}
        labelText={labelText.end}
        theme={IQTheme}
        openPickerOnClick
        onChange={value => doOnChange(value, 'end')}
        placeholder={placeholder?.end}
        subDescription={subDescription?.end}
        tooltipText={tooltipText?.end}
        showError={hasError}
        {...rest}
      />
    </Stack>
  );
};

export default IQDateRangePicker;
