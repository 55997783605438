import React from 'react';
import styled from '@emotion/styled';
import { HelpOutline } from '@mui/icons-material';
import { CardContent, CardHeader, Card, Divider, useTheme } from '@mui/material';
import IQLabelTooltip from '../../tooltip/IQLabelTooltip/IQLabelTooltip';

interface SectionCardProps {
  id: string,
  title: string | React.ReactNode,
  description?: string,
  tooltipText?: string,
  children: React.ReactNode,
  showDividingLine: boolean,
  shouldHaveLabelTooltip: boolean,
  bannerSection: React.ReactNode
  hasError: boolean,
  tooltipIcon: React.ReactNode
}

export const CustomCard = styled(Card)(({ theme }) => ({
  border: `1px solid ${theme.palette.text.primary}`,
  borderRadius: "8px",
  padding: theme.spacing(3),
  margin: theme.spacing(2, 0),
}));

const DividingLine = styled(Divider, {
  shouldForwardProp: (prop) => prop !== 'allowMobile',
})(({ allowMobile, theme }: any) => ({
  borderColor: '#D7D7D7',
  margin: theme.spacing(3, 0),
  [theme.breakpoints.down('md')]: {
    display: allowMobile ? 'block' : 'none',
  },
}));

const CustomHeader = styled(CardHeader)(({ theme }) => ({
  letterSpacing: '0.18px',
  padding: 0,
  '& .MuiCardHeader-title': {
    fontSize: 20,
    fontWeight: 600,
    lineHeight: '25px',
    letterSpacing: '0.18px',
    // Can be a p for tooltip or label without
    '& p': {
      fontSize: 20,
      fontWeight: 600,
      lineHeight: '25px',
      letterSpacing: '0.18px',
      [theme.breakpoints.down('md')]: {
        maxHeight: 'none',
      },
    },
    '& label': {
      fontSize: 20,
      fontWeight: 600,
      lineHeight: '25px',
      letterSpacing: '0.18px',
      [theme.breakpoints.down('md')]: {
        maxHeight: 'none',
      },
      '& svg': {
        // Default SVG sizing
        height: 20,
        width: 20,
      },
    },
  },
  '& .MuiCardHeader-subheader': {
    color: theme.palette.text.primary,
    fontSize: 16,
    lineHeight: '24px',
    paddingTop: theme.spacing(1),
  },
}));

const CustomContent = styled(CardContent)(({ theme }) => ({
  paddingLeft: 0,
  paddingTop: 0,
  '&.MuiCardContent-root:last-child': {
    paddingBottom: 0,
  },
  [theme.breakpoints.down('md')]: {
    paddingRight: 0,
  },
}));

export default function SectionCard({
  id,
  title,
  description = null,
  tooltipText = '',
  children,
  showDividingLine = true,
  shouldHaveLabelTooltip = true && tooltipText !== '',
  hasError = false,
  bannerSection = null,
  tooltipIcon = HelpOutline,
}: SectionCardProps) {
  const theme = useTheme();

  return (
    <CustomCard id={id}>
      {bannerSection && bannerSection}
      <CustomHeader
        title={
          shouldHaveLabelTooltip
            ? (
              <IQLabelTooltip
                required={false}
                hasError={hasError}
                theme={theme}
                tooltipText={tooltipText}
                labelText={title}
                Icon={tooltipIcon}
              />
            )
            : title
          }
        subheader={description}
      />
      {showDividingLine ? <DividingLine allowMobile /> : null }
      <CustomContent>
        {children}
      </CustomContent>
    </CustomCard>
  );
}