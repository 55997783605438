import { RadioGroup } from '@mui/material';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import PropTypes, { InferProps } from 'prop-types';
import IQRadioBox from '../../input/IQRadioBox/IQRadioBox';
import IQStyledCard from '../../layout/IQStyledCard/IQStyledCard';

/**
 * Component for a card with radio group
 *
 * See below prop types for param info
 */

export const IQRadioGroupCard = (({
  cardHeaderText,
  cardDescription,
  controllerName,
  selectedValue,
  radioBoxArray,
  onChange,
  theme,
}: InferProps<typeof IQRadioGroupCard.proptypes>) => {
  const {
    control,
  } = useFormContext();

  return (
    <IQStyledCard headerText={cardHeaderText} description={cardDescription} theme={theme}>

      <Controller
        control={control}
        name={controllerName}
        render={({ field }) => (
          <RadioGroup value={selectedValue} {...field}>
            <div style={{ padding: '10px 0' }} />
            {
              radioBoxArray.map(({
                radioDisplayValue, radioValue, isSelected, disabled,
              }) => (
                <div key={`radioItem-${radioValue}`}>
                  <IQRadioBox
                    radioDisplayValue={radioDisplayValue}
                    value={radioValue}
                    isSelected={isSelected}
                    disabled={disabled}
                    onChange={onChange}
                    theme={theme}
                  />
                  <div style={{ padding: '16px 0' }} />
                </div>
              ))
            }
          </RadioGroup>
        )}
      />
    </IQStyledCard>
  );
});

export default IQRadioGroupCard;

IQRadioGroupCard.proptypes = {

  /**
   * Receives the card header
   */
  cardHeaderText: PropTypes.string.isRequired,

  /**
   * Receives the card description
   */
  cardDescription: PropTypes.string,

  /**
   * Receives the controller name property
   */
  controllerName: PropTypes.string.isRequired,

  /**
   * The current selected value of the radioGroup
   */
  selectedValue: PropTypes.string.isRequired,

  /**
   * An array of radioBox object
   * The radioBox object must have the following structure:
   *  {
   *     radioDisplayValue, // the display value of the radio item
   *     radioValue, // the used value of the radio item
   *     isSelected, // if the radio item is selected (true) or not (false)
   *     disabled, // if the radio item is disabled (true) or not (false)
   *  }
   */
  radioBoxArray: PropTypes.arrayOf(PropTypes.object).isRequired,

  /**
   * The function that handles radio item change selection
   */
  onChange: PropTypes.func.isRequired,

  /**
   * The return provided from createTheme from parent app
   */
  theme: PropTypes.object,
};
