import React from 'react';
import {
  Chip, Stack, Card, CardContent,
  Typography, Divider, Modal, Box, Button,
} from '@mui/material';
import { Theme } from '@emotion/react';
import CircleIcon from '@mui/icons-material/Circle';
import DeleteIcon from '@mui/icons-material/Delete';
import styled from '@emotion/styled';
import '../../styles/website_fonts.css';
import IQThemeV2 from '../../styles/IQThemeV2';

export interface IQLocationPLCardProps {
  field: string;
  locationTypeLabel?: string;
  locationName: string;
  addressLine1: string;
  addressLine2?: string;
  city: string;
  state: string;
  zipcode: string;
  locationStatus?: 'Complete' | 'InProgress' | 'NotStarted' | string;
  handleDelete: any;
  onSelect: any;
  [rest: string]: any;
}

type StyleProps = {
  theme?: Theme;
  error?: boolean;
  locationStatus?: string;
};

const LocationCardStyled = styled(Card) <StyleProps>`
  padding: ${(props) => props.theme.spacing(1, 1, 0, 1)};
  border: 1px solid ${(props) => (props.error ? props.theme.palette.error.main : props.theme.palette.action.disabledBackground)};
  margin-bottom: 16px;
  &:hover {
    background-color: ${(props) => props.theme.palette.primary.light};
  }
`;

const LocationCardContent = styled(CardContent)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
}));

const ChipStyled = styled(Chip)(({ theme }) => ({
  height: '18px',
  fontSize: '12px',
  fontWeight: 600,
  padding: '1px',
}));

const ChipWrapper = styled(Stack)(({ theme, locationStatus }: StyleProps) => ({
  '.MuiChip-root > .MuiChip-icon': {
    color: `${locationStatus === 'Complete' ? theme.palette.success.dark : (locationStatus === 'InProgress' ? theme.palette.error.main : (locationStatus === 'NotStarted' ? theme.palette.primary.main : ''))}`,
  },
  [theme.breakpoints.down('sm')]: {
    marginLeft: theme.spacing(4),
  },
}));

const Subtitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

const AddressWrapper = styled(Stack)(({ theme }) => ({
  flexBasis: '40%',
  [theme.breakpoints.down('sm')]: {
    marginLeft: theme.spacing(6),
    maxWidth: '300px',
    wordWrap: 'break-word',
  },
}));

const VerticalDivider = styled(Divider)(({ theme }) => ({
  height: '24px',
  width: '1px',
  color: `${theme.palette.action.disabledBackground}`,

  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

const HorizontalDivider = styled(Divider)(({ theme }) => ({
  color: `${theme.palette.action.disabledBackground}`,
  paddingTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
  [theme.breakpoints.up('sm')]: {
    display: 'none',
  },
}));

const StyledCircleIcon = styled(CircleIcon)(({ theme }) => ({
  fontSize: '10px',
}));

const StatusIconMap = {
  Complete: {
    icon: <StyledCircleIcon />,
    name: 'COMPLETE',
  },
  InProgress: {
    icon: <StyledCircleIcon />,
    name: 'IN PROGRESS',
  },
  NotStarted: {
    icon: <StyledCircleIcon />,
    name: 'NOT STARTED',
  },
};

const IQLocationPLCard = ({
  field,
  locationTypeLabel,
  locationName,
  addressLine1,
  addressLine2,
  city,
  state,
  zipcode,
  locationStatus,
  handleDelete,
  onSelect,
}: IQLocationPLCardProps) => {
  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const closeModalNoBackdropClick = (event, reason) => {
    if (reason && reason === 'backdropClick') return;
    setOpenModal(false);
  };

  const onDelete = () => {
    handleDelete();
    handleCloseModal();
  };

  return (
    <React.Fragment>
      <LocationCardStyled theme={IQThemeV2} onClick={onSelect}>
        <LocationCardContent>
          <Stack direction="row" sx={{ flexBasis: '35%' }}>
            <Stack>
              <Subtitle variant="body2">{`${locationTypeLabel || 'Location'}:`}</Subtitle>
              {locationName ? (
                <Typography
                  component="div"
                  sx={{
                    fontFamily: 'Unify Sans',
                    fontSize: 16,
                    lineHeight: '24px',
                    fontWeight: 'bold',
                  }}
                >
                  {locationName}
                </Typography>
              ) : <Subtitle variant="body1">Enter Location</Subtitle>}
            </Stack>
          </Stack>
          <AddressWrapper>
            <HorizontalDivider orientation="horizontal" flexItem />
            <Subtitle variant="body2">Address:</Subtitle>
            {addressLine1 ? (
              <React.Fragment>
                <Typography variant="body1">{`${addressLine1}, ${addressLine2 || ''}`}</Typography>
                <Typography variant="body1">{`${city}, ${state} ${zipcode}`}</Typography>
              </React.Fragment>
            )
              : <Subtitle variant="body1">Enter Address</Subtitle>}
            <HorizontalDivider orientation="horizontal" flexItem />
          </AddressWrapper>
          <ChipWrapper direction="row" spacing={2} locationStatus={locationStatus} sx={{ flexBasis: '20%', flexShrink: 0, flexGrow: 0 }}>
            {locationStatus
          && (
            <React.Fragment>
              <VerticalDivider orientation="vertical" flexItem />
              <ChipStyled label={StatusIconMap[locationStatus].name} icon={StatusIconMap[locationStatus].icon} variant="outlined" />
            </React.Fragment>
          )}
          </ChipWrapper>
          <Stack>
            <DeleteIcon onClick={(event) => { event.stopPropagation(); handleOpenModal(); }} />
          </Stack>
        </LocationCardContent>
      </LocationCardStyled>
      {openModal && (
      <Modal
        open={openModal}
        onClose={closeModalNoBackdropClick}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{
          position: 'absolute' as 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 600,
          bgcolor: 'background.paper',
          borderRadius: '2px',
          boxShadow: 24,
          padding: '24px 8px 8px 24px',
        }}
        >
          <Typography variant="h5" component="h2" mb={3}>Remove Location</Typography>
          <Typography variant="body1">
            Are you sure you want to remove
            {city ? <strong>{` "${addressLine1} ${addressLine2}, ${city} ${state}, ${zipcode}"?`}</strong> : ' "?'}
          </Typography>
          <Box mt={4} sx={{ display: 'flex', justifyContent: 'flex-end', gap: '16px' }}>
            <Button variant="outlined" sx={{ borderRadius: '20px', textTransform: 'none' }} onClick={handleCloseModal}>Cancel</Button>
            <Button variant="contained" sx={{ borderRadius: '20px', textTransform: 'none' }} onClick={onDelete}>Remove</Button>
          </Box>
        </Box>
      </Modal>
      )}
    </React.Fragment>
  );
};

export default IQLocationPLCard;
