import { createTheme, Theme } from '@mui/material/styles';

export const IQTheme: Theme = createTheme({
  palette: {
    text: {
      primary: '#404040',
      secondary: '#737373',
    },
    primary: {
      main: '#1665CF',
      light: '#B9D1F1',
      dark: '#0F4791',
    },
    secondary: {
      main: '#009BFF',
      light: '#B9E1FF',
      dark: '#0A32A0',
    },
    info: {
      main: '#688595',
      light: '#B3C2CA',
      dark: '#03334E',
    },
    error: {
      main: '#D2424B',
    },
    action: {
      disabledBackground: '#C5C5C5',
    },
    background: {
      default: '#F9F9F9',
    },
    // These are the Orion greens
    success: {
      main: '#8FD16A',
      light: '#DAF0CD',
      dark: '#50902D',
    },
  },
  typography: {
    fontFamily: 'Unify Sans',
    fontWeightMedium: 600,
    fontWeightBold: 800,
    h1: {
      fontFamily: 'Unify Sans SemiBold',
      fontSize: 48,
      lineHeight: '53px',
      '@media (max-width:600px)': {
        fontSize: 32,
        lineHeight: '35px',
      },
    },
    h2: {
      fontFamily: 'Unify Sans SemiBold',
      fontSize: 40,
      lineHeight: '50px',
      '@media (max-width:600px)': {
        fontSize: 28,
        lineHeight: '35px',
      },
    },
    h3: {
      fontFamily: 'Unify Sans SemiBold',
      fontSize: 32,
      lineHeight: '40px',
      '@media (max-width:600px)': {
        fontSize: 24,
        lineHeight: '30px',
      },
    },
    h4: {
      fontFamily: 'Unify Sans SemiBold',
      fontSize: 24,
      lineHeight: '30px',
      '@media (max-width:600px)': {
        fontSize: 20,
        lineHeight: '25px',
      },
    },
    h5: {
      fontFamily: 'Unify Sans SemiBold',
      fontSize: 20,
      lineHeight: '25px',
      '@media (max-width:600px)': {
        fontSize: 18,
        lineHeight: '23px',
      },
    },
  },
  shape: {
    borderRadius: 2,
  },
  spacing: 8,
  components: {
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: 0,
          marginTop: 8,
          '&.error': {
            color: '#A6083D',
          },
        },
      },
    },
  },
});

export default IQTheme;
