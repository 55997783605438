import React, { useEffect, useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import {
  Checkbox, FormControlLabel, FormHelperText, useTheme, Box, Tooltip,
} from '@mui/material';
import styled from '@emotion/styled';
import InfoIcon from '@mui/icons-material/Info';

import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import schemaValidate from '../../services/schemaValidate';
import IQLabelTooltip from '../../tooltip/IQLabelTooltip/IQLabelTooltip';
import { useValidationContext } from '../../providers/ValidationProvider';

export interface IQCheckboxProps {
  /**
   * Input label
   */
  label?: string
  /**
   * Input field name
   */
  title?: string

  field: string
  /**
   * Required
   */
  required?: boolean
  /**
   * Shows exception message below checkbox (true/false)
   */
  tooltipText?: string;

  tooltipPosition?: 'title' | 'label';

  tooltipPlacement?: 'bottom' | 'right' | 'top' | 'left';

  tooltipArrow?: boolean;
  hideErrors?: boolean
  tooltipPaddingBottom?: number;
  checkboxBorderColor?: string;
  params?: any;
  [rest: string]: any;
}

interface StyledCheckboxProps {
  checkboxBorderColor?: string
}

type ErrorProps = {
  error?: string;
};

const StyledTooltip = styled(({ ...props }) => (
  <Tooltip children={undefined} title="" {...props} />
))`
  & .MuiTooltip-tooltip {
    max-width: calc(208px - 17px);
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    background: ${(props) => props.theme.palette.text.primary};
    border-radius: 4px;
  }

  & .MuiTooltip-tooltipArrow {
    background: ${(props) => props.theme.palette.text.primary};
  }

  & .MuiTooltip-arrow {
    color: ${(props) => props.theme.palette.text.primary};
  }
`;

const IQCheckboxContainer = styled('div') <ErrorProps>`
  & .MuiFormHelperText-root {
    color: ${(props) => props.theme.palette.error.main};
  }
`;

const StyledCheckbox = styled(Checkbox, {
  shouldForwardProp: (prop) => prop !== 'checkboxBorderColor',
})<StyledCheckboxProps>`
  color: ${(props) => (props.checkboxBorderColor ? props.checkboxBorderColor : props.theme.palette.text.primary)};
  padding: 0 9px;
  align-self: baseline;
`;

const FormControlLabelStyled = styled(FormControlLabel) <ErrorProps>`
  color: ${(props) => (props.error ? props.theme.palette.error.main : props.theme.palette.text.primary)};
  border: 2px solid ${(props) => (props.error ? props.theme.palette.error.main : 'transparent')};
`;

const Title = styled('span')`
  display: block;
  font-family: 'Unify Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${(props) => (props.theme.palette.text.primary)};
  margin-bottom: 15px;
`;

export default function IQCheckbox({
  label,
  title,
  field,
  required = false,
  hideErrors = false,
  tooltipPlacement = 'bottom',
  tooltipText = null,
  tooltipArrow = true,
  tooltipPosition = 'title',
  tooltipPaddingBottom,
  params, 
  disabled = false,
  checkboxBorderColor,
  ...rest
}: IQCheckboxProps) {
  const theme = useTheme();
  const { schema } = useValidationContext();
  const [customValidation, setCustomValidation] = useState(null);
  const { control } = useFormContext();
  const {
    field: {
      onChange, onBlur, name, value: fieldValue, ref,
    },
  } = useController({
    name: field,
    control,
    rules: {
      validate: (value) => schemaValidate(value, field, schema, params, customValidation),
    },
    defaultValue: rest?.defaultValue || false,
  });
  const { t } = useTranslation();

  const {
    formState: { errors },
    clearErrors,
  } = useFormContext();

  useEffect(() => {
    if (required) {
      setCustomValidation(yup.boolean().oneOf([true], `yup.${field}.required`));
    }

    clearErrors(field);
  }, [required]);

  const doOnChange = (event) => {
    onChange(event);

    if (rest.onChange) {
      rest.onChange(event);
    }
  };

  const doOnBlur = () => {
    onBlur();

    if (rest.onBlur) {
      rest.onBlur();
    }
  };

  return (
    <IQCheckboxContainer>
      {tooltipText && title && (
      <Box sx={{ marginBottom: '16px' }}>
        <IQLabelTooltip
          hasError={hideErrors}
          tooltipText={tooltipPosition === 'title' ? tooltipText : ''}
          labelText={title}
          theme={theme}
          tooltipPlacement={tooltipPlacement}
          required={required}
          arrow={tooltipArrow}
          paddingBottom={tooltipPaddingBottom}
        />
      </Box>
      )}
      {!tooltipText && title && (
        <Title>{`${title}${required ? ' *' : ''}`}</Title>
      )}
      <FormControlLabelStyled
        error={errors && errors[field] ? 'true' : undefined}
        control={(
          <StyledCheckbox
            required={errors[field] !== undefined && errors[field] !== null}
            onChange={doOnChange} // send value to hook form
            onBlur={doOnBlur} // notify when input is touched/blur
            value={fieldValue} // input value
            checked={fieldValue} // input value
            name={name} // send down the input name
            inputRef={ref} // send input ref, so we can focus on input when error appear
            disabled={disabled}
            checkboxBorderColor={checkboxBorderColor}
          />
        )}
        label={label}
      />
      {tooltipText && tooltipPosition === 'label' && (
      <StyledTooltip
        title={tooltipText}
        placement={tooltipPlacement}
        arrow
      >
        <InfoOutlinedIcon sx={{
          color: theme.palette.primary.main,
          height: 18,
          width: 18,
          cursor: 'pointer',
        }}
        />
      </StyledTooltip>
      )}
      <FormHelperText
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '7px',
        }}
        error={required}
      >
        {!hideErrors && errors[field] && (
        <React.Fragment>
          <InfoIcon sx={{ fontSize: '16px' }} />
          {' '}
          {t(`${errors[field].message}`)}
        </React.Fragment>
        )}
      </FormHelperText>
    </IQCheckboxContainer>
  );
}
