import React from 'react';
import { useTheme } from '@emotion/react';
import { IQHeaderLink } from '../IQHeader/IQHeader';
import IQDesktopHeaderExpandableLink, { DesktopMenuItemButton } from './IQDesktopHeaderExpandableLink';
import IQHeaderItemBadge from '../IQHeaderItemBadge/IQHeaderItemBadge';

export interface IQDesktopHeaderProps {
  links: IQHeaderLink[],
  handleLinkClick: (link) => void,
  activeLink: IQHeaderLink,
  children?: React.ReactNode,
};

export default function IQDesktopHeader({ links, handleLinkClick, activeLink, children }: IQDesktopHeaderProps) {
  const theme = useTheme();

  return (
    <React.Fragment>
      {links.map((linkObj) => {
        const {
          id, labelText, dropdownLinks, badge, isOnRightSide
        } = linkObj;
        const isActive = activeLink?.id === id;

        const standardBtn = (
            <DesktopMenuItemButton
              key={id}
              theme={theme}
              isActive={isActive}
              onClick={() => handleLinkClick(linkObj)}
            >
              {labelText}
            </DesktopMenuItemButton>
        );

        if (dropdownLinks) {
          return (
            <IQDesktopHeaderExpandableLink
              key={id}
              labelText={labelText}
              dropdownLinks={dropdownLinks}
              handleLinkClick={handleLinkClick}
              activeLink={activeLink}
              headerLinkClassName={isOnRightSide ? 'right' : ''}
            />
          );
        }

        if (badge) {
          return (
            <IQHeaderItemBadge key={id} {...badge}>
              {standardBtn}
            </IQHeaderItemBadge>
          );
        }

        return (
          standardBtn
        );
      })}
      {/* Render other type of dropdowns as children */}
      {children}
    </React.Fragment>
  );
}