import React from 'react';
import styled from '@emotion/styled';
import { Avatar, FormControl, FormControlLabel, FormHelperText, Paper, Radio, RadioGroup, Divider, Chip } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import PropTypes, { InferProps } from 'prop-types';
import '../../styles/website_fonts.css';
import IQFormLabel from '../IQFormLabel/IQFormLabel';
import IQTheme from '../../styles/IQTheme';
import IQLabelTooltip from '../../tooltip/IQLabelTooltip/IQLabelTooltip';

type ColorRadioProps = {
  color: string
  [x: string]: unknown
  disabled?: boolean
};

type ColorIconProps = {
  iconColor: string
};

const ColorIcon = styled('span') <ColorIconProps>`
  border-radius: 50%;
  width: 56px;
  height: 56px;
  border: 1px solid black;
  background-color: ${(props) => props.iconColor || props.theme.palette.error.main};
`;

const ColorIconWithLock = styled(Avatar)(({ theme }) => ({
  borderRadius: "50%",
  width: "56px",
  height: "56px",
  border: `1px solid ${theme.palette.common.black}`
}));

const CheckedColorIcon = styled(Avatar)(({ theme }) => ({
  width: 56,
  height: 56,
  border: `3px solid ${theme.palette.common.white}`,
  boxShadow: `0 0 0 2px  ${theme.palette.primary.main}`,
  '& > svg': {
    height: 30,
    width: 30,
  },
}));

const ErrorFormHelperText = styled(FormHelperText)`
  color: ${(props) => props.theme.palette.error.main};
  margin-top: 4px;
`;

const ProDivider = styled(Divider)(() => ({
  width: "93%"
}))

const MiddleIconWrapper = styled(Chip)(({ theme }) => ({
  border: `1px solid ${theme.palette.action.disabledBackground}`,
  height: "16px",
  display: "flex",
  backgroundColor: theme.palette.primary.contrastText,
  "& .MuiChip-label" : {
    fontSize: "10px",
    marginLeft: "8px"
  },
  "& .MuiChip-icon" : {
    position: "relative",
    left: "10px",
    bottom: "1px",
    width: "0.5rem"
  },
}))

const DisabledColorsWrapper = styled(Paper)(({ theme }) => ({
  label: {
    margin: 0,
  },
  padding: '8px 0px 15px 0px',
  backgroundColor: theme.palette.background.default,
  border: `1px solid ${theme.palette.action.disabledBackground}`,
  borderRadius: '4px',
}));

const EnabledColorsWrapper = styled('div')`
  padding-top: 8px;
  display: flex;
  flex-wrap: wrap;
  gap: 7px;
  justify-content: flex-start;
`;

function ColorRadio(props: ColorRadioProps) {
  const {
    color, disabled, selectIcon, disabledIcon, ...rest
  } = props;

  return (
    <Radio
      sx={{
        '&:hover': {
          bgcolor: 'transparent',
        },
      }}
      disableRipple
      disabled={disabled}
      color="default"
      checkedIcon={(
        <CheckedColorIcon sx={{ bgcolor: color }}>
          {selectIcon}
        </CheckedColorIcon>
      )}
      icon={disabled ? <ColorIconWithLock sx={{ bgcolor: color }} >
        {disabledIcon}
      </ColorIconWithLock> : <ColorIcon iconColor={color} />}
      {...rest}
    />
  );
}

const ProLine = ({ children, icon, label, proIconCustomStyle }) => {
  return (
    <div>
      {children}
      <ProDivider><MiddleIconWrapper icon={<img src={icon} style={proIconCustomStyle}/> } label={label}/></ProDivider>
    </div>
  )
}

/**
 * Component for a color picking widget (radio selection)
 * If you need to set the initial value, use the defaultValues array in your
 * containing react hook form
 *
 * See below prop types for param info
 */
export const IQColorWidget = ({
  name,
  labelText,
  tooltipText,
  colors,
  optionsPerRow,
  onChange,
  theme,
  required,
  addProLine,
  centerProIcon,
  centerText,
  proIconCustomStyle
}: InferProps<typeof IQColorWidget.propTypes>) => {
  const {
    control,
    formState: {
      errors,
    },
  } = useFormContext();

  const hasError = !!errors[name];
  const errorMessage = hasError ? errors[name].message : '';
  const enabledColors = colors.filter((cD) => !cD.disabled).map((colorData: ColorRadioProps) => {
    const { color, icon } = colorData;

    return (
      <FormControlLabel
        key={`color-${color}-key`}
        value={color}
        label=""
        control={(
          <ColorRadio
            id={`color-${color}-key`}
            color={color}
            selectIcon={icon}
            onChange={onChange}
          />
        )}
      />
    );
  });
  
  const disabledColors = colors.filter((cD) => cD.disabled).map((colorData: ColorRadioProps) => {
    const { color, disabled, icon, disabledIcon } = colorData;

    return (
      <FormControlLabel
        sx={{
          cursor: disabled ? 'not-allowed !important' : 'default',
        }}
        key={`color-${color}-key`}
        value={color}
        label=""
        control={(
          <ColorRadio
            id={`color-${color}-key`}
            color={color}
            selectIcon={icon}
            onChange={onChange}
            disabled={disabled}
            disabledIcon={disabledIcon}
          />
        )}
      />
    );
  });

  return (
    <FormControl>
      {tooltipText
        && (
          <IQLabelTooltip
            required={required}
            hasError={hasError}
            tooltipText={tooltipText}
            labelText={labelText}
            theme={theme}
          />
        )}
      {!tooltipText && (
        <IQFormLabel
          theme={theme}
        >
          {labelText}
        </IQFormLabel>
      )}
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <RadioGroup
            id={`color-widget-${name}`}
            row
            sx={{
              maxWidth: `${optionsPerRow * 90}px`,
            }}
            {...field}
          >
            <EnabledColorsWrapper>{enabledColors}</EnabledColorsWrapper>

            {addProLine ? (
              <ProLine icon={centerProIcon} label={centerText} proIconCustomStyle={proIconCustomStyle}>{disabledColors}</ProLine>
            ) : <div>{disabledColors}</div>}
          </RadioGroup>
        )}
      />
      <ErrorFormHelperText>
        {errorMessage}
      </ErrorFormHelperText>
    </FormControl>
  );
};

export default IQColorWidget;

IQColorWidget.propTypes = {
  /**
   * Label for the input field
   */
  labelText: PropTypes.string.isRequired,

  /**
   * Name field used in the react hook form and yup schema
   */
  name: PropTypes.string.isRequired,

  /**
   * Tooltip text, if present a standard ? icon will be used with tooltip functionality.
   */
  tooltipText: PropTypes.node,

  /**
   * Array of objects containing hex color values and select icon to use as color options
   * E.g.
   * {
   *   color: '#ff0000',
   *   icon: <SmartToyIcon />
   *   disabled: true/false
   * }
   */
  colors: PropTypes.arrayOf(PropTypes.object).isRequired,

  /**
   * How many colors to display side by side before starting a new row
   */
  optionsPerRow: PropTypes.number,

  /**
   * The material Theme from parent
   */
  theme: PropTypes.object.isRequired,

  /**
   * function triggered when the color selection changes
   */
  onChange: PropTypes.func,

  /**
   * flag which determines whether or not to add a wrapper to the enabled and disabled colors
   */
  addColorsWrapper: PropTypes.bool,
};

IQColorWidget.defaultProps = {
  tooltipText: null,
  optionsPerRow: 4,
  theme: IQTheme,
};
