import React, { useState } from 'react';
import styled from '@emotion/styled';
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import { Box, Button, Theme } from '@mui/material';
import { IQHeaderLink, IQLink, MenuIcon } from '../IQHeader/IQHeader';

type MobileMenuItemButtonProps = {
  theme: Theme,
  isActive?: boolean,
};

export const MobileMenuItemButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'isActive',
})(({ theme, isActive }: MobileMenuItemButtonProps) => ({
  fontFamily: 'Unify Sans',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '24px',
  padding: 0,
  margin: theme.spacing(1, 0),
  width: '100%',
  height: 'auto',
  display: 'flex',
  justifyContent: 'space-between',
  borderColor: 'transparent',
  color: theme.palette.background.default,

  '&.right': {
    fontWeight: 600,
  },

  '&.parent-link': {
    '&:before': {
      content: '""',
      display: 'inline-block',
      position: 'absolute',
      bottom: -5,
      left: 0,
      right: 0,
      width: '100%',
      height: 4,
      backgroundColor: theme.palette.success.main,
      opacity: isActive ? 1 : 0,
      transition: '.25s opacity ease-in-out',
    },
  },
  '&.child-link': {
    color: isActive ? theme.palette.success.main : theme.palette.common.white,
    fontWeight: isActive ? 600 : 400,
  },
  '&:hover': {
    color: theme.palette.success.main,
  },
  '&:focus': {
    padding: theme.spacing(1.1, 0),
    border: '1px solid #6982C8',
    borderRadius: 'unset',
  },
}));

const MenuGroup = styled(Box)(({ theme }) => ({
  padding: 0,
  color: theme.palette.common.white,
  '& svg': {
    fill: theme.palette.common.white,
  },
}));

const LinkGroup = styled(Box)(({ theme }) => ({
  paddingLeft: theme.spacing(2),
}));

const StyledLabelTxtWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(0.7),
}));

export interface IQMobileHeaderExpandableLinkProps {
  labelText: string,
  dropdownLinks: IQLink[],
  theme: Theme,
  handleLinkClick: (link) => void,
  activeLink?: IQHeaderLink,
  headerLinkClassName?: string,
};

/**
 * Component for rendering a collapsible menu group
 */
export default function IQMobileHeaderExpandableLink({ labelText, dropdownLinks, theme, handleLinkClick, activeLink, headerLinkClassName }: IQMobileHeaderExpandableLinkProps) {
  const [open, setOpen] = useState(false);
  const linksId = `${labelText}-groupLinks`;

  const triggerLinkGroup = () => {
    setOpen(!open);
  };

  return (
    <MenuGroup key={labelText}>
      <MobileMenuItemButton
        theme={theme}
        endIcon={open ? <ExpandLess /> : <ExpandMore />}
        onClick={() => triggerLinkGroup()}
        isActive={dropdownLinks.some(l => l.id === activeLink?.id)}
        className={`parent-link ${headerLinkClassName}`}
      >
        {labelText}
      </MobileMenuItemButton>
      <LinkGroup id={linksId} sx={{ display: open ? 'block' : 'none' }}>
        {dropdownLinks.map((link) => (
          <MobileMenuItemButton
            theme={theme}
            key={link.labelText}
            onClick={() => handleLinkClick(link)}
            sx={{ display: link.hide ? 'none' : 'inline-flex' }}
            isActive={activeLink?.id === link.id}
            className='child-link'
          >
            <StyledLabelTxtWrapper>
              {link.labelText}
              {link.rightIconUrl && <MenuIcon src={link.rightIconUrl} alt={`${link.labelText} icon.`}/>}
            </StyledLabelTxtWrapper>
          </MobileMenuItemButton>
        ))}
      </LinkGroup>
    </MenuGroup>
  );
}