/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import {
  FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Theme,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useController, useFormContext } from 'react-hook-form';
import { useValidationContext } from '../../providers/ValidationProvider';
import schemaValidate from '../../services/schemaValidate';

interface IQYesNoFormRadioButtonsProps {
  /**
   * Label text (title) for the radio buttons group
   */
  title: string,

  /**
   * title font weight
   */
  fontTitleWeight?: string,

  /**
   * Name field used in the react hook form and yup schema
   */
  field: string

  /**
   * Theme
   */
  theme?: Theme,

  params?: any,
  [rest: string]: any
}

interface FormLabelProps {
  /**
   * title font weight
   */
  fontTitleWeight?: string,

  /**
   * Theme
   */
  theme?: Theme,
}

const FormLabelStyled = styled(FormLabel)<FormLabelProps>`
  color: ${(props) => props.theme.palette.text.primary};
  margin-bottom: 0.5em;
  font-weight: ${(props) => props.fontTitleWeight};

  &.Mui-focused {
    color: ${(props) => props.theme.palette.text.primary};,
  }
`;

const RadioGroupStyled = styled(RadioGroup)<FormLabelProps>`
  .Mui-checked {
      span {
        color: ${(props) => (props.theme.palette.primary.main)};
    }
  }
`;

const FormControlLabelStyled = styled(FormControlLabel)`
  margin-right: 40px;

  .MuiRadio-root {
    margin-right: 4px;
    color: ${(props) => props.theme.palette.text.primary};

    &:hover {
      background-color: transparent;
    }
  }

  .MuiTypography-root.MuiFormControlLabel-label {
    color: ${(props) => props.theme.palette.text.primary};
  }
`;

export default function IQYesNoFormRadioButtons({
  title,
  field,
  theme,
  fontTitleWeight,
  params,
  ...rest
}: IQYesNoFormRadioButtonsProps) {
  const [internalValue, setInternalValue] = useState('');

  const { schema } = useValidationContext();

  const [customValidation, setCustomValidation] = useState(null);
  const { control } = useFormContext();
  const {
    field: {
      onChange, onBlur, name, value: fieldValue, ref,
    },
  } = useController({
    name: field,
    control,
    rules: {
      validate: (value) => schemaValidate(value, field, schema, params, customValidation),
    },
    defaultValue: rest?.defaultValue || false,
  });

  const {
    formState: { errors },
    clearErrors,
  } = useFormContext();

  const handleChange = (e) => {
    setInternalValue(e.target.value);
    onChange(e);
  };

  return (
    <FormControl>
      <FormLabelStyled
        theme={theme}
        fontTitleWeight={fontTitleWeight}
        id="row-radio-buttons-group-label"
      >
        {title}
      </FormLabelStyled>
      <RadioGroupStyled
        row
        onChange={handleChange} // send value to hook form
        value={fieldValue} // input value
        name={name} // send down the input name
        aria-labelledby="row-radio-buttons-group-label"
      >
        <FormControlLabelStyled value="yes" control={<Radio disableRipple />} label="Yes" />
        <FormControlLabelStyled value="no" control={<Radio disableRipple />} label="No" />
      </RadioGroupStyled>
    </FormControl>
  );
}
