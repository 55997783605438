import styled from '@emotion/styled';
import { FormControl, FormHelperText } from '@mui/material';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import PhoneInput from 'react-phone-number-input/react-hook-form-input';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import PropTypes, { InferProps } from 'prop-types';
import '../../styles/website_fonts.css';

type ErrorProp = {
  haserror?: boolean,
};

type WrapperProp = {
  haserror?: boolean,
  disabled?: boolean,
};

const FormLabel = styled('label') <ErrorProp>`
  color: ${(props) => (props.haserror
    ? props.theme.palette.error.main : props.theme.palette.text.secondary)};
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.25px;
  font-family: Unify Sans;
  padding-bottom: ${({ theme }) => theme.spacing(1)};
`;

const PhoneInputWrapper = styled('div') <WrapperProp>`
  display: flex;
  align-items: center;
  background-color: white;
  border: 1px solid;
  border-color: ${(props) => (props.haserror
    ? props.theme.palette.error.main : props.theme.palette.text.secondary)};
  border-radius: 2px;
  overflow: hidden;
  box-sizing: border-box;
  ${({ disabled }) => disabled && {
    opacity: '0.38',
    backgroundColor: '#efefef',
  }};
  &:focus-within {
    border-width: 2px;
    border-color: ${(props) => (props.haserror
    ? props.theme.palette.error.main : props.theme.palette.primary.main)};
    /** Keeps the field from moving with the increased border size above */
    margin: -1px 0;
  }
  & > input {
    color: rgba(0, 0, 0, 0.87);
    width: calc(100% - 16px);
    border: 0;
    padding: 12px;
    font-size: 16px;
    font-family: Unify Sans;
    padding-left: 18px;
    &:focus {
      outline: none;
    }
  }
`;

const PhoneIconWrapper = styled('div')`
  padding-left: 18px;
  & svg {
    vertical-align: bottom;
  }
`;

const ErrorIconWrapper = styled('div')`
  display: inline-block;
  margin-right: ${({ theme }) => theme.spacing(1)};
  & svg {
    fill: ${({ theme }) => theme.palette.error.main};
    vertical-align: bottom;
    height: 20px;
    width: 20px;
  }
`;

const ErrorFormHelperText = styled(FormHelperText)(({ theme }) => ({
  color: theme.palette.error.main,
  marginTop: 4,
}));

/**
 * Component for a phone input field
 *
 * See below prop types for param info
 */
export const IQFormPhoneInput = ({
  id,
  labelText,
  name,
  onChange,
  fullWidth = false,
  disabled = false,
  onBlur,
  onFocus,
  phoneIcon,
  useSchemaError,
  errorIcon,
  placeholder
}: InferProps<typeof IQFormPhoneInput.propTypes>) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const hasError = !!errors[name];
  const STANDARD_PHONE_ERROR = 'Please enter a valid US phone number';
  const errorMessage = hasError ? useSchemaError ? errors[name].message : STANDARD_PHONE_ERROR : '';

  const handleBlur = (e, builtInBlur) => {
    if (onBlur) {
      onBlur(e);
    }

    return builtInBlur(e);
  };

  const handleFocus = (e) => {
    if (onFocus) {
      onFocus(e);
    }
  };

  const renderedIcon = phoneIcon ? (
    <PhoneIconWrapper>
      {phoneIcon}
    </PhoneIconWrapper>
  ) : null;

  const renderedErrorIcon = errorIcon ? (
    <ErrorIconWrapper>
      {errorIcon}
    </ErrorIconWrapper>
  ) : null;

  return (
    <FormControl fullWidth={fullWidth}>
      <FormLabel haserror={hasError}>
        {labelText}
      </FormLabel>
      <Controller
        name={name}
        control={control}
        rules={{
          validate: (value) => (value ? isPossiblePhoneNumber(value) : true),
        }}
        render={({ field: { value, onBlur: builtInBlur } }) => (
          <PhoneInputWrapper haserror={hasError} disabled={disabled}>
            {renderedIcon}
            <PhoneInput
              id={id}
              name={name}
              control={control}
              country="US"
              international={false}
              value={value}
              defaultValue=""
              onChange={onChange}
              disabled={disabled}
              onBlur={(e) => handleBlur(e, builtInBlur)}
              onFocus={(e) => handleFocus(e)}
              placeholder={placeholder}
            />
          </PhoneInputWrapper>
        )}
      />
      <ErrorFormHelperText role="alert">
        {errors[name] && renderedErrorIcon}
        {errors[name] && errorMessage}
      </ErrorFormHelperText>
    </FormControl>
  );
};

export default IQFormPhoneInput;

IQFormPhoneInput.propTypes = {
  /**
   * Unique ID of this component
   */
  id: PropTypes.string.isRequired,

  /**
   * Label for the input field
   */
  labelText: PropTypes.string.isRequired,

  /**
   * Name field used in the react hook form and yup schema
   */
  name: PropTypes.string.isRequired,

  /**
   * onChange function that receives a value corresponding to each input value when it changes
   */
  onChange: PropTypes.func.isRequired,

  /**
   * fullWidth field for input field
   */
  fullWidth: PropTypes.bool.isRequired,

  /**
   * Whether the field is disabled
   */
  disabled: PropTypes.bool,

  /**
   * On blur function
   */
  onBlur: PropTypes.func,

  /**
   * On focus function
   */
  onFocus: PropTypes.func,

  /**
   * Icon to use in the input
   */
  phoneIcon: PropTypes.node,

  /**
   * Whether to use the schema set error message, or a default one
   */
  useSchemaError: PropTypes.bool,

  /**
   * Error icon to show with the error message
   */
  errorIcon: PropTypes.node,
};
