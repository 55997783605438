import {
  FormHelperText,
  FormLabel, Theme, ToggleButtonGroup, useTheme,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import InfoIcon from '@mui/icons-material/Info';
import IQButtonRadio, { IQButtonRadioProps } from './IQButtonRadio';
import schemaValidate from '../../services/schemaValidate';
import { useValidationContext } from '../../providers/ValidationProvider';

const ToggleButtonGroupStyled = styled(ToggleButtonGroup) <Omit<IQButtonRadioContainerProps, 'name'> & { error: any }>`
  display: flex;
  flex-wrap: ${(props) => (props.inline ? 'nowrap' : 'wrap')};
  flex-direction: ${(props) => (props.singleColumn ? 'column' : 'row')};
  max-width: fit-content;
  & button{
   color: ${(props) => (props.error ? props.theme.palette.error.main : props.theme.palette.text.primary)}
  }
 `;

const FormLabelStyled = styled(FormLabel)<Omit<IQButtonRadioContainerProps, 'name'>>`
  color: ${(props) => props.theme.palette.text.primary};
  margin-bottom: 10px;
  display: inline-block;
  font-weight: ${(props) => props.fontTitleWeight || '400'};

  &.Mui-focused {
    color: ${(props) => props.theme.palette.text.primary};
  }
`;

interface IQButtonRadioContainerProps {
  /**
   * Form name
   */
  name: string;
  defaultValue?: string;
  optionsList: IQButtonRadioProps[];
  /**
   * Label/Title text
   */
  label?: string;
  disabled?: boolean;
  /**
   * Show radio buttons on one line or not. default - undefined
   */
  inline?: boolean;
  /**
   * Show radio buttons in one column or not. default - undefined
   */
  singleColumn?: boolean;
  /**
   * title font weight. default - 600
   */
  fontTitleWeight?: string;

  /**
   * Allow radio buttons to be de-selectable
   */
  allowRadioDeselect?: boolean;
  required?: boolean;

  /**
    * Theme
    */
  theme?: Theme;
}

export default function IQButtonRadioContainer({
  defaultValue,
  optionsList,
  disabled,
  label,
  inline,
  singleColumn,
  fontTitleWeight = '600',
  allowRadioDeselect = false,
  name,
  required = true,
  ...props
}: IQButtonRadioContainerProps) {
  const theme = useTheme();
  const {
    formState: { errors },
    control,
    watch,
    setValue,
  } = useFormContext();
  const { schema } = useValidationContext();
  const hasError = !!errors[name];
  const errorMessage = hasError ? errors[name].message : '';

  useEffect(() => {
    if (defaultValue) setValue(name, defaultValue);
  }, []);

  const inputValue = watch(name);

  const validation = required ? {
    validate: (value) => schemaValidate(value, name, schema),
  } : {};

  return (
    <React.Fragment>
      {label && (
        <FormLabelStyled
          optionsList={optionsList}
          theme={theme}
          fontTitleWeight={fontTitleWeight}
          id="row-radio-buttons-group-label"
          sx={{ fontWeight: fontTitleWeight }}
        >
          {label}
        </FormLabelStyled>
      )}
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        rules={validation}
        render={({ field: { value, onChange } }) => (
          <ToggleButtonGroupStyled
            error={errors[name]?.message}
            optionsList={optionsList}
            exclusive
            value={value}
            inline={inline}
            singleColumn={singleColumn}
          >
            {
            optionsList.map((radioButtonProps: IQButtonRadioProps) => (
              <IQButtonRadio
                key={radioButtonProps.value}
                onClick={(e, newValue) => {
                  if (allowRadioDeselect && inputValue === newValue) {
                    return onChange('');
                  }

                  return onChange(radioButtonProps.value);
                }}
                disabled={disabled}
                {...radioButtonProps}
              />
            ))
          }
          </ToggleButtonGroupStyled>
        )}
      />
    </React.Fragment>
  );
}
