import React from 'react';
import { Card, Typography, useTheme } from '@mui/material';
import styled from '@emotion/styled';

import { StyleProps } from '../../styles';

export const SUCCESS_TYPE = 'SUCCESS_TYPE';
export const ERROR_TYPE = 'ERROR_TYPE';
export const INFO_TYPE = 'INFO_TYPE';
export const WARNING_TYPE = 'WARNING_TYPE';

interface ActionCardProps {
  id: string,
  type: 'SUCCESS_TYPE' | 'ERROR_TYPE' | 'INFO_TYPE' | 'WARNING_TYPE',
  children: React.ReactNode,
  icons: {
    SuccessIcon: string,
    ErrorIcon: string,
    InfoIcon: string,
    WarningIcon: string,
    style: React.CSSProperties
  }
}
interface IconImageWrapperProps {
  colorFill: string
}

const BasicCard = styled(Card)(() => ({
  margin: '0 auto',
  borderRadius: '2px',
  border: 'none',
  boxShadow: '0 1px 3px rgba(0, 0, 0, 0.2)',
  borderLeft: 0,
}));

const ColorBar = styled('div')(({ bgColor, theme }: StyleProps) => ({
  minWidth: theme.spacing(1),
  backgroundColor: bgColor,
  borderRadius: '2px 0 0 2px',
}));

const MessageWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  padding: theme.spacing(1, 2),
}));

const IconImageWrapper = styled('div')<IconImageWrapperProps>(({ colorFill }) => ({
  '& svg': {
    height: 32,
    width: 32,
    '& path': {
      fill: colorFill,
    },
  },
}));

// Component for a card representing an action taken (reset password result, error, success etc)
export default function ActionCard({ id, type = SUCCESS_TYPE, children, icons }: ActionCardProps) {
  const theme = useTheme();
  let selectedIcon = null;
  let typeColor = theme.palette.primary.dark;

  switch (type) {
    case SUCCESS_TYPE:
      typeColor = theme.palette.success.main;
      selectedIcon = (
        <IconImageWrapper colorFill={typeColor}>
          <img style={icons.style} src={icons.SuccessIcon} alt="success-icon" />
        </IconImageWrapper>
      );
      break;
    case ERROR_TYPE:
      typeColor = theme.palette.error.main;
      selectedIcon = (
        <IconImageWrapper colorFill={typeColor}>
          <img style={icons.style} src={icons.ErrorIcon} alt="error-icon" />
        </IconImageWrapper>
      );
      break;
    case INFO_TYPE:
      typeColor = theme.palette.info.dark;
      selectedIcon = (
        <IconImageWrapper colorFill={typeColor}>
          <img src={icons.InfoIcon} alt="info-icon" />
        </IconImageWrapper>
      );
      break;
    case WARNING_TYPE:
      typeColor = theme.palette.warning.main;
      selectedIcon = (
        <IconImageWrapper colorFill={typeColor}>
          <img src={icons.WarningIcon} alt="warning-icon" />
        </IconImageWrapper>
      );
      break;
    default:
      break;
  }
  return (
    <BasicCard id={id}>
      <div style={{ display: 'flex' }}>
        <ColorBar bgColor={typeColor} />
        <MessageWrapper>
          {selectedIcon}
          <Typography
            component="div"
            sx={{
              fontFamily: 'Unify Sans',
              fontSize: 14,
              lineHeight: '25px',
              textAlign: 'left',
              paddingLeft: theme.spacing(2),
              flex: 1,
              display: 'flex',
              flexWrap: 'wrap',
            }}>
            {children}
          </Typography>
        </MessageWrapper>
      </div>
    </BasicCard>
  );
};