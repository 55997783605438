import React, {useEffect, useState} from 'react';
import {useController, useFormContext} from 'react-hook-form';
import {styled} from '@mui/material/styles';
import { FormHelperText, Grid } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Info';
import {IQTheme} from '../../styles/IQTheme';

import schemaValidate from '../../services/schemaValidate';
import {useValidationContext} from '../../providers/ValidationProvider';
import IQContentTileItem from '../IQContentTileItem/IQContentTileItem';

export interface IQContentToneItemProps {
  name: string,
  label: string
  desc?: string,
  disabled?: boolean,
  showError?: boolean
}

export interface IQContentToneContainerProps {
  name?: string,
  defaultValue?: string,
  tonesList: ContentToneOptionsType,
  disabled?: boolean,
  theme?: object
}

export type ContentToneOptionsType = IQContentToneItemProps[];

const IQGridStyled = styled(Grid)(({theme}) => ({
  '&.MuiGrid-container': {
    width: '100%'
  }
}))

const ErrorFormHelperText = styled(FormHelperText)`
  color: ${(props) => props.theme.palette.error.main};
  margin-top: 4px;
`;

export default function IQContentToneContainer({
  defaultValue,
  tonesList,
  name,
  disabled,
  theme
}: IQContentToneContainerProps) {
  const {schema} = useValidationContext();
  const {formState: {errors}, control} = useFormContext();
  const [objSelected, setObjSelected] = useState([]);
  const {
    field,
  } = useController({
    name,
    control,
    rules: {
      validate: (value) => schemaValidate(value, name, schema, null, null),
    },
    defaultValue: defaultValue || '',
  });

  const hasError = !!errors[name];

  const handleOnBlur = () => {
    if (field?.onBlur) {
      field?.onBlur();
    }
  };

  useEffect(() => {
    setObjSelected(tonesList.map((tone) => ({
      ...tone, selected: field.value.includes(tone.name)
    })))
  }, [])

  const logSelected = (index: number, selected: boolean) => {
    objSelected[index] = {...objSelected[index], selected};
    field?.onChange(objSelected.filter((obj) => obj.selected).map((obj) => obj.name))
  }

  return (
    <IQGridStyled
      container
      columnSpacing={2}
      rowSpacing={0}
      justifyContent="flex-start"
      alignItems="stretch"
      onBlur={handleOnBlur}
      ref={field.ref}
    >
      {
        (
          objSelected.map((singleOption, key) => (
            <Grid item xs={5} key={key}>
              <IQContentTileItem
                showError={hasError}
                index={key}
                selectedCallback={logSelected}
                key={singleOption.label}
                name={singleOption.name}
                label={singleOption.label}
                desc={singleOption.desc}
                selected={singleOption.selected}
                disabled={disabled || singleOption?.disabled}
              />
            </Grid>
          ))
        )
      }
      <Grid item xs={12}>
        {hasError && (
        <ErrorFormHelperText>
          <ErrorIcon sx={{ fontSize: '16px', verticalAlign: 'middle' }} />
          {' '}
          {errors[name].message}
        </ErrorFormHelperText>
          )}
      </Grid>
    </IQGridStyled>
  );
}

IQContentToneContainer.defaultProps = {
  defaultValue: null,
  theme: IQTheme,
};
