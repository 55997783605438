import React, { } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { styled } from '@mui/material/styles';
import {
  FormHelperText,
  ToggleButtonGroup,
} from '@mui/material';

import { IQTheme } from '../../styles/IQTheme';

import schemaValidate from '../../services/schemaValidate';
import { useValidationContext } from '../../providers/ValidationProvider';

import IQBigBoxButton from './IQBigBoxButton';

export interface IQBigBoxSelectItemProps {
  /**
   * Label
   */
  label?: string
  /**
   * Value
   */
  value?: string
  /**
   * Image
   */
  image?: string
  disabled?: boolean
}

export interface IQBigBoxSelectProps {
  name?: string,
  defaultValue?: string,
  optionsList: BigBoxOptionsType,
  disabled?: boolean,
  optionsPerRow?: number,
  theme?: object,
}

export type BigBoxOptionsType = IQBigBoxSelectItemProps[];

const IQStyledBoxSelect = styled(ToggleButtonGroup)(({ theme }) => ({
  display: 'flex',
}));

const ErrorFormHelperText = styled(FormHelperText)`
  color: ${(props) => props.theme.palette.error.main};
  margin-top: 4px;
`;

export default function IQBigBoxSelect({
  defaultValue,
  optionsList,
  name,
  disabled,
  optionsPerRow,
  theme,
}: IQBigBoxSelectProps) {
  const { schema } = useValidationContext();
  const { formState: { errors }, control } = useFormContext();

  const {
    field,
  } = useController({
    name,
    control,
    rules: {
      validate: (value) => schemaValidate(value, name, schema, null, null),
    },
    defaultValue: defaultValue || '',
  });

  // This will be set to true only if we have an exception for this field
  const hasError = !!errors[name];
  const errorMessage = hasError ? errors[name].message : '';

  const handleOnChange = (event) => {
    const newValue = 'value' in event.target ? event.target.value : event.target.parentElement?.value;

    if (field?.onChange) {
      field?.onChange(newValue);
    }
  };

  const handleOnBlur = () => {
    if (field?.onBlur) {
      field?.onBlur();
    }
  };

  return (
    <div>
      <IQStyledBoxSelect
        exclusive
        onChange={handleOnChange}
        color={hasError ? 'error' : 'standard'}
        onBlur={handleOnBlur}
        value={field.value}
        defaultValue={defaultValue}
        ref={field.ref}
      >
        {
          (
            optionsList.map((singleOption) => (
              <IQBigBoxButton
                key={singleOption.label}
                label={singleOption.label}
                image={singleOption.image}
                value={singleOption.label}
                disabled={disabled || singleOption?.disabled}
              />
            ))
          )
        }
      </IQStyledBoxSelect>
      <ErrorFormHelperText>
        {errorMessage}
      </ErrorFormHelperText>
    </div>
  );
}

IQBigBoxSelect.defaultProps = {
  defaultValue: null,
  optionsPerRow: 4,
  theme: IQTheme,
};
