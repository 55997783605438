import * as React from 'react';
import { styled } from '@mui/material/styles';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { ExpandMore } from '@mui/icons-material';
import { ChangeEventHandler } from 'react';

interface IQExpandableTextAreaProps {
  id: string;
  label: string;
  name: string;
  value: string;
  defaultOpen?: boolean;
  onChange: ChangeEventHandler<HTMLTextAreaElement>;
  width?: string;
}

const StyledTextArea = styled('textarea')`
  box-sizing:border-box;
  width: 100%;
  font-size: 16px;
  padding: 8px 16px;
  font-weight: 400;
  resize: vertical;
  font-family: ${(props) => props.theme.typography.fontFamily};
  border: 1px solid ${(props) => (props.theme.palette.text.primary)};
  border-color: ${(props) => (props.disabled ? props.theme.palette.action.disabledBackground : props.theme.palette.text.primary)};
  border-radius: 2px;
  &:focus-within {
    outline: none;
    border-width: 1px;
    border-color: ${(props) => (props.theme.palette.primary.main)};
  },
`;

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: 'none',
  color: theme.palette.primary.main,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ExpandMore />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiButtonBase-root-MuiAccordionSummary-root': {
    padding: '0px',
  },
  '& .MuiSvgIcon-root': {
    color: theme.palette.primary.main,
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function IQExpandableTextArea(props: IQExpandableTextAreaProps) {
  const {
    id, label, name, value, onChange, defaultOpen = false, width,
  } = props;
  const [expanded, setExpanded] = React.useState<boolean>(defaultOpen);

  const handleChange = (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded);
  };

  return (
    <div style={width ? { width } : {}}>
      <Accordion expanded={expanded} onChange={handleChange} key={`accordion-${id}`} sx={{ width: '100%' }}>
        <AccordionSummary aria-controls="panel1d-content" id={`panel-${id}`} sx={{ width: '100%' }}>
          <Typography sx={{ fontSize: '14px' }}>{label}</Typography>
        </AccordionSummary>
        <AccordionDetails id={`details-${id}`} sx={{ width: '100%' }}>
          <StyledTextArea name={name} id={id} key={`text-area-${id}`} onChange={onChange} value={value} rows={2} />
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
