import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Close, Menu } from '@mui/icons-material';
import { Theme, useTheme } from '@emotion/react';
import { AppBar, Box, Divider, Drawer, IconButton } from '@mui/material';
import { IQHeaderLink } from '../IQHeader/IQHeader';
import IQHeaderItemBadge from '../IQHeaderItemBadge/IQHeaderItemBadge';
import IQMobileHeaderExpandableLink, { MobileMenuItemButton } from './IQMobileHeaderExpandableLink';

type DividerProps = {
  theme: Theme,
  isActive?: boolean,
};

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  '.MuiDrawer-paper': {
    width: '100vw',
    height: '100vh',
    backgroundColor: theme.palette.common.black,
  },
}));

const CloseWrapper = styled(Box)(({ theme }) => ({
  marginRight: theme.spacing(1),
  display: 'block',
  alignItems: 'center',
  zIndex: 9999,
  padding: 3,

  button: {
    height: 30,
    flexShrink: 0,
  },
}));

const HeaderWrapper = styled(AppBar)(({ theme }) => ({
  height: '64px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'left',
  verticalAlign: 'middle',
  boxShadow: 'none',
  border: 'none',
  background: theme.palette.common.black,
  paddingLeft: theme.spacing(2),
  justifyContent: 'flex-start',
  color: theme.palette.common.white,
  [theme.breakpoints.down('md')]: {
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

const HeaderButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.common.white,
}));

const LinkBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0, 2),
}));

const LOCALiQLogoMobile = styled('img')(({ theme }) => ({
  width: 105,
  marginRight: theme.spacing(4),
}));

const MenuDivider = styled(Divider, { shouldForwardProp: (prop) => prop !== 'isActive' })(({ theme, isActive }: DividerProps) => ({
  '&.MuiDivider-root.MuiDivider-fullWidth': {
    borderBottom: isActive ? `4px solid ${theme.palette.success.main}` : `1px solid ${theme.palette.action.disabledBackground}`,
    margin: 0,
  },
}));

export interface IQMobileHeaderProps {
  links: IQHeaderLink[],
  logoSrc: string,
  handleLinkClick?: (link) => void,
  activeLink: IQHeaderLink,
  children?: React.ReactNode,
};

export default function IQMobileHeader({ links, logoSrc, handleLinkClick, activeLink, children }: IQMobileHeaderProps) {
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <HeaderButton sx={{ mr: theme.spacing(1) }} onClick={() => setOpen(true)} size="large">
        <Menu />
      </HeaderButton>
      <StyledDrawer
        anchor="top"
        open={open}
      >
        <HeaderWrapper position="relative">
          <LOCALiQLogoMobile alt="localiq-logo" src={logoSrc} />
          <CloseWrapper>
            <HeaderButton onClick={handleClose} size="large">
              <Close />
            </HeaderButton>
          </CloseWrapper>
        </HeaderWrapper>
        <LinkBox>
          {links.map((linkObj) => {
            const {
              id, labelText, dropdownLinks, badge, isOnRightSide
            } = linkObj;
            const isActive = activeLink?.id === id;

            const standardBtn = (
              <MobileMenuItemButton
                key={id}
                theme={theme}
                isActive={isActive}
                onClick={() => handleLinkClick(linkObj)}
              >
                {labelText}
              </MobileMenuItemButton>
            );
            let displayComponent = standardBtn;

            if (dropdownLinks) {
              displayComponent = (
                <IQMobileHeaderExpandableLink
                  key={id}
                  theme={theme}
                  dropdownLinks={dropdownLinks}
                  labelText={labelText}
                  handleLinkClick={handleLinkClick}
                  activeLink={activeLink}
                  headerLinkClassName={isOnRightSide ? 'right' : ''}
                />
              );
            }

            if (badge) {
              displayComponent = (
                <IQHeaderItemBadge key={id} {...badge}>
                  {standardBtn}
                </IQHeaderItemBadge>
              );
            }

            return (
              <React.Fragment key={id}>
                {displayComponent}
                <MenuDivider theme={theme} isActive={isActive} />
              </React.Fragment>
            );
          })}
          {children}
        </LinkBox>
      </StyledDrawer>
    </React.Fragment>
  );
}