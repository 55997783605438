import React, { useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import Switch from '@mui/material/Switch';
import {
  FormGroup,
  FormControlLabel,
  useTheme,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { getError } from '../../error/ErrorFunctions';
import IQLabelTooltip from '../../tooltip/IQLabelTooltip/IQLabelTooltip';

export interface IQSwitchProps {
  id?: string,
  /**
   * Input name reference for react-hook-form usage
   */
  name?: string
  /**
   * Input label
   */
  label: string | React.ReactElement
  /**
   * Switch size
   */
  size?: string
  disabled?: boolean
  /**
   * Hook into the change function
   */
  onChange?: { (checked: boolean): void }
  /**
   * Removes background color and border from the switch
   */
  unboxed?: boolean
  /**
   * Text to use for the normal tooltip implementation
   */
  tooltipText?: string
  /**
   * Icon to use for the tooltip
   */
  tooltipIcon?: any,
}

type LabelProps = {
  isChecked?: boolean,
  error?: string, // Either "true" or undefined
  unboxed?: boolean,
};

const IQFormControlLabel = styled(FormControlLabel, {
  shouldForwardProp: (prop) => prop !== 'isChecked' && prop !== 'unboxed',
}) <LabelProps>`
  margin-left: 0;
  border-width: 1px;
  border-style: solid;
  border-color: ${(props) => (props.isChecked ? props.theme.palette.primary.main : props.theme.palette.text.primary)};
  border-radius: 2px;
  background: #FFFFFF;
  ${({ unboxed }) => unboxed && {
    background: 'transparent',
    border: 'none',
  }};
  box-sizing: border-box;
  &.Mui-disabled {
    opacity: 0.5;
  }
  &.Mui-error {
    border-color: ${(props) => (props.theme.palette.error.main)};
  }
  & .MuiTypography-root {
    font-family: 'Unify Sans';
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.18px;

    &.Mui-disabled {
      color: inherit;
    }
  }
  & svg {
    // Set a default for icon height/width
    height: 20px;
    width: 20px;
  }
`;

type SwitchStyleProps = {
  error?: boolean,
};

const IQSwitchStyled = styled(Switch, {
  shouldForwardProp: (prop) => prop !== 'error',
}) <SwitchStyleProps>`
  padding: 0;
  display: flex;
  margin: 8px;
  &.MuiSwitch-sizeSmall {
    width: 40px;
    height: 20px;
    & .MuiSwitch-switchBase {
      &.Mui-checked {
        transform: translateX(20px);
        &.Mui-disabled {
          color: #FFF;
        }
      }
    }
    & .MuiSwitch-thumb {
      width: 16px;
      height: 16px;
      border-radius: 8px;
    }
    & .MuiSwitch-track {
      border-radius: 11px;
      opacity: 1;
      &:before {
        background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="14" viewBox="0 0 15 24"><path fill="${encodeURIComponent(
    '#FFFFFF',
  )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>');
        left: 2px;
      }
    }
  }
  &.MuiSwitch-sizeMedium {
    width: 48px;
    height: 24px;
    & .MuiSwitch-switchBase {
      &.Mui-checked {
        transform: translateX(24px);
        &.Mui-disabled {
          color: #FFF;
        }
      }
    }
    & .MuiSwitch-thumb {
      width: 18px;
      height: 18px;
    }
    & .MuiSwitch-track {
      border-radius: 13px;
      opacity: 1;
      &:before {
        background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="18" width="18" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
    '#FFFFFF',
  )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>');
        left: 4px;
      },
    },
  },
  & .MuiSwitch-switchBase {
    padding: 2.5px;
    &.Mui-checked {
      color: #fff;
      & + .MuiSwitch-track {
        background-color: ${(props) => (props.error ? props.theme.palette.error.main : props.theme.palette.primary.main)};
        opacity: 1;
      }
    }
    &.Mui-disabled {
      & + .MuiSwitch-track {
        opacity: 1;
      }
    }
  }
  & .MuiSwitch-thumb {
    transition: ${(props) => (props.theme.transitions.create(['width'], {
    duration: 200,
  }))};
  }
  & .MuiSwitch-track {
    background-color: ${(props) => (props.error ? props.theme.palette.error.main : props.theme.palette.text.secondary)};
    box-sizing: border-box;
    &:before, &:after {
      content: "";
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 16px;
      height: 16px;
    }
  }
`;

export default function IQSwitch({
  id,
  name,
  label,
  disabled,
  size,
  onChange,
  unboxed,
  tooltipText,
  tooltipIcon,
}: IQSwitchProps) {
  const [checked, setChecked] = useState(false);
  const theme = useTheme();
  enum Sizes {
    sm = 'small',
    md = 'medium',
  }
  let sizeChosen: Sizes;
  if (size === 'sm') {
    sizeChosen = Sizes.sm;
  } else if (size === 'md') {
    sizeChosen = Sizes.md;
  }

  const handleChange = (event) => {
    const isChecked = event.target.checked;
    setChecked(isChecked);
    if (onChange) {
      onChange(isChecked);
    }
  };

  const controlledChange = (e, builtInChange) => {
    if (onChange) {
      onChange(e.target.checked);
    }

    return builtInChange(e.target.checked);
  };

  let labelInfo = label;
  if (tooltipText) {
    labelInfo = (
      <IQLabelTooltip
        tooltipText={tooltipText}
        labelText={label}
        tooltipPlacement="right"
        required={false}
        arrow={false}
        Icon={tooltipIcon}
        hasError={false}
        theme={theme}
      />
    );
  }

  if (name) {
    const {
      control,
      formState: { errors },
      watch,
    } = useFormContext();
    const isChecked = watch(name);
    const error = useMemo(
      () => getError(errors, name),
      [errors],
    );

    if (tooltipText) {
      labelInfo = (
        <IQLabelTooltip
          tooltipText={tooltipText}
          labelText={label}
          tooltipPlacement="right"
          required={false}
          arrow={false}
          Icon={tooltipIcon}
          hasError={!!error}
          theme={theme}
        />
      );
    }

    return (
      <FormGroup>
        <IQFormControlLabel
          error={error ? 'true' : undefined}
          isChecked={isChecked}
          disabled={disabled}
          unboxed={unboxed}
          label={labelInfo}
          control={(
            <Controller
              name={name}
              control={control}
              render={({ field }) => (
                <IQSwitchStyled
                  id={id}
                  error={!!error}
                  disabled={disabled}
                  inputProps={{ 'aria-label': 'controlled' }}
                  size={sizeChosen}
                  onChange={(e) => controlledChange(e, field.onChange)}
                  checked={field.value}
                />
              )}
            />
          )}
        />
      </FormGroup>
    );
  }

  return (
    <FormGroup>
      <IQFormControlLabel
        isChecked={checked}
        label={labelInfo}
        disabled={disabled}
        unboxed={unboxed}
        control={(
          <IQSwitchStyled
            checked={checked}
            onChange={handleChange}
            disabled={disabled}
            inputProps={{ 'aria-label': 'controlled' }}
            size={sizeChosen}
          />
        )}
      />
    </FormGroup>
  );
}
