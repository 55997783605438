import React from 'react';
import styled from '@emotion/styled';

export interface IQLeftNavLogoProps {
  IQLeftNavIcon: string
}

const LeftNavLogo = styled('div')`
  text-align: center;
  margin-bottom: 40px;
`;

export const IQLeftNavLogo = ({ IQLeftNavIcon } : IQLeftNavLogoProps) => (
  <LeftNavLogo>
    <img src={IQLeftNavIcon} alt="Main LeftNav Icon" />
  </LeftNavLogo>
);

export default IQLeftNavLogo;
