import PropTypes, { InferProps } from 'prop-types';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { MenuItem, Select } from '@mui/material';

export const IQGenericSelector = ({
  itemList,
  defaultValue,
  name,
  margin,
  onChange,
}: InferProps<typeof IQGenericSelector.propTypes>) => {
  const { control } = useFormContext();

  const controlledChange = (e, builtInChange) => {
    if (onChange) {
      onChange(e.target.value);
    }

    return builtInChange(e);
  };

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ field }) => (
        <Select
          margin={margin || 'none'}
          {...field}
          onChange={(e) => controlledChange(e, field.onChange)}
        >
          {itemList.map((item: {name: string, code: string}) => (
            <MenuItem key={item.code} value={item.code}>{ item.name }</MenuItem>
          ))}
        </Select>
      )}
    />
  )

}

IQGenericSelector.propTypes = {

  /**
   * The array of items that will be render as options for the Select
   * The current object is itemList = [{ name: 'Name', code: 'Code'}]
   */
  itemList: PropTypes.arrayOf(PropTypes.object),
  /**
   * The name used for the react hook form controller
   */
  name: PropTypes.string.isRequired,
  /**
   * The default selected value for the Select component
   */
  defaultValue: PropTypes.string,
  /**
   * The margin param for the Select component.
   * Possibly two values: 'dense' or 'none'
   */
  margin: PropTypes.string,

};

export default IQGenericSelector;