import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { isMobile } from 'react-device-detect';
import { useController, useFormContext } from 'react-hook-form';
import { ErrorCode, FileRejection, useDropzone } from 'react-dropzone';
import { Grid, Typography, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ErrorIcon from '@mui/icons-material/Error';
import IQButton from '../../shared/IQButton';
import { IQLabelTooltip } from '../../tooltip/IQLabelTooltip/IQLabelTooltip';

import { useValidationContext } from '../../providers/ValidationProvider';
import schemaValidate from '../../services/schemaValidate';

type VideoExtensions = '.avi' | '.mp4' | '.mpeg' | '.ogv' | '.ts' | '.webm' | '.3gp' | '.3g2';
enum UploadEventType {
  DRAGANDDROP = 'Drag & Drop',
  FILEUPLOAD = 'File Upload',
}

export interface IQFileUploaderProps extends IQFileUploaderOptionalProps {
  /**
   * Name of the input
   */
  name: string;
}

export interface IQFileUploaderOptionalProps {

  /**
   * Label for the uploader
   */
  label?: string
  /**
   * Colors the label text red on error
   */
  withLabelError?: boolean
  /**
   * Tooltip text for the label
   */
  toolTiptext?: string
  /**
   * Supporting text for the uploader
   */
  supportingText?: string
  /**
   * Colors the supportive text red on error
   */
  withSupportingTextError?: boolean
  /**
   * Label for the button inside the uploader
   */
  buttonLabel?: string
  /**
   * Assistive text for the recommended image size
   */
  recommendedImgText?: string
  /**
   * Maximum number of files that can be uploaded at the same time (Default: 4)
   */
  maxFiles?: number
  /**
   * Maximum number of files that can be uploaded in total (Default: 40)
   */
  totalAllowedFiles?: number
  /**
   * Current number of files that are already uploaded (Default: 0)
   */
  currentUploadedFiles?: number
  /**
   * Minimum file size required (Default: 1kb)
   */
  minFileSize?: number
  /**
   * Maximum file size that can be uploaded (Default: 255Mb)
   */
  maxFileSize?: number
  /**
   * Input required
   */
  required?: boolean
  /**
   * File Uploader size (medium-md, large-lg)
   */
  size?: string
  /**
   * Disabled state of the component
   */
  disabled?: boolean
  /**
   * Image extensions that are allowed
   */
  imageTypes?: string[]
  /**
   * Document extensions that are allowed
   */
  documentTypes?: string[]
  /**
   * Video extensions that are allowed
   */
  videoTypes?: VideoExtensions[];
  /**
   * shows a text with the allowed file types
   */
  showAcceptedFileTypesText?: boolean
  /**
   * shows a text with the recommended size
   */
  showRecommendedImageText?: boolean
  /**
   * shows a text with the maximum file size
   */
  showMaxFileSizeText?: boolean
  /**
   * shows a text with the maximum file number
   */
  showMaxNumberFilesText?: boolean
  /**
   * On success function callback
   */
  onSuccess?: any,
  /**
   * On failure function callback
   */
  onFailure?: any,

  /**
  * Callback that returns the event type
  */
  onDropCallback?: (UploadEventType) => any;
}

type StateProps = {
  hasError?: boolean
};

type CombinedProps = IQFileUploaderOptionalProps & StateProps;

const Container = styled('div') <CombinedProps>`
  font-family: ${(props) => props.theme.typography.fontFamily};
  background-color: ${(props) => props.theme.palette.common.white};
  font-size: 14px;
  flex: 1;
  display: flex;
  justify-content: space-around;
  flex-direction: ${(props) => (props.size === 'md' ? 'row' : 'column')};
  align-items: center;
  padding: ${(props) => (props.size === 'md' ? '3px' : '20px')};
  border-width: 2px;
  border-radius: 2px;
  border-style: dashed;
  border-color: ${(props) => (props.disabled ? props.theme.palette.action.disabledBackground : (props.hasError ? props.theme.palette.error.main : props.theme.palette.primary.main))};
  color: ${(props) => (props.disabled ? props.theme.palette.action.disabledBackground : (props.hasError ? props.theme.palette.error.main : props.theme.palette.info.dark))};
  outline: none;
  transition: border .24s ease-in-out;
  padding-top: 35px;
  padding-bottom: 23px;

  &:hover {
    border-style: solid;
    background-color: ${(props) => props.theme.palette.primary.light};
  }

  &:focus-within {
    border-style: solid;
  }
`;

const CloudUploadIconStyled = styled(CloudUploadIcon) <CombinedProps>`
  color: ${(props) => (props.disabled ? props.theme.palette.action.disabledBackground : (props.hasError ? props.theme.palette.error.main : props.theme.palette.primary.main))};
`;

const ErrorIconStyled = styled(ErrorIcon) <CombinedProps>`
  color: ${(props) => props.theme.palette.error.main};
  font-size: 18px;
  padding-right: 5px;
  display: inline-block;
`;

const SupportiveText = styled(Typography) <CombinedProps>`
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: 16px;
  line-height: 20px;
  padding-top: 8px;
  padding-bottom: 16px;
  color: ${({ theme, hasError }) => (hasError ? theme.palette.error.main : theme.palette.text.primary)};
`;

const AssistiveText = styled(Typography) <CombinedProps>`
  margin-top: 14px;
  margin-bottom: 15px;
  font-style: normal;
  font-weight: 400;
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: 12px;
  line-height: 3px;
  color: ${(props) => props.theme.palette.text.secondary};
`;

const ErrorInfoText = styled(Typography) <CombinedProps>`
  color: ${(props) => props.theme.palette.error.main};
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: 12px;
  display: inline-block;
`;

export default function IQFileUploader({
  name,
  label = 'File Uploader',
  withLabelError = true,
  supportingText = 'Some supporting text',
  withSupportingTextError = false,
  buttonLabel = 'Browse',
  toolTiptext,
  recommendedImgText = '000px x 000px',
  size = 'lg',
  disabled = false,
  required = false,
  maxFiles = 4,
  totalAllowedFiles = 40,
  currentUploadedFiles = 0,
  minFileSize = 100,
  maxFileSize = 255000000,
  showAcceptedFileTypesText = true,
  showRecommendedImageText = true,
  showMaxFileSizeText = true,
  showMaxNumberFilesText = false,
  imageTypes = ['.png', '.gif', '.jpeg', '.jpg', '.CR2', '.DNG'],
  videoTypes = ['.avi', '.3g2', '.3gp', '.mp4', '.mpeg', '.ogv', '.ts', '.webm'],
  documentTypes = ['.pdf'],
  onSuccess,
  onFailure,
  onDropCallback,
}:IQFileUploaderProps) {
  const uploadedAmount = useRef(currentUploadedFiles);
  const [hasError, setHasError] = useState(false);
  const [uniqueErrorMsgs, setUniqueErrorMsgs] = useState(null);

  const errorMsgs = [];

  const theme = useTheme();
  const { schema } = useValidationContext();
  const { formState: { errors }, control } = useFormContext();
  const {
    field,
  } = useController({
    name,
    control,
    rules: {
      validate: (value) => schemaValidate(value, name, schema, null, null),
    },
    defaultValue: [],
  });

  const acceptedFileExtensions = imageTypes.concat(documentTypes).concat(videoTypes)
    .map((extn) => extn.toUpperCase().replace('.', ''))
    .join(', ')
    .replace(/, ([^,]*)$/, ' and $1');

  const onDrop = useCallback((acceptedFiles) => {
    if (field?.onChange) {
      field.onChange(acceptedFiles);
    }
  }, []);

  const validator = (file) => {
    if (uploadedAmount.current >= totalAllowedFiles) {
      return {
        code: 'upload-limit-reached',
        message: `The file ${file.name} could not be uploaded. The file number has exceeded
         the maximum limit (${totalAllowedFiles} files).`,
      };
    }

    const fileExtension = file.path.slice(file.path.lastIndexOf('.'));

    const validFileType = (types: string[], extension: string) => types.some(
      (type: string) => type.toLowerCase() === extension.toLowerCase(),
    );

    if (!validFileType(imageTypes, fileExtension)
        && !validFileType(videoTypes, fileExtension)
        && !validFileType(documentTypes, fileExtension)) {
      return {
        code: ErrorCode.FileInvalidType,
        message: `The specified file ${file.name} could not be uploaded.
        Only files with the following extensions are allowed: ${acceptedFileExtensions}`,
      };
    }

    if (file && file.path) {
      uploadedAmount.current += 1;
    }

    return null;
  };

  const onDropAccepted = (files, event) => {
    if (event && event.type === 'drop') {
      uploadedAmount.current -= files.length;
      if (onDropCallback) {
        onDropCallback(UploadEventType.DRAGANDDROP);
      }
    } else if (onDropCallback) {
      onDropCallback(UploadEventType.FILEUPLOAD);
    }

    if (onSuccess && typeof onSuccess === 'function') {
      return onSuccess(files);
    }

    return null;
  };

  const onDropRejected = (files, event) => {
    if (event && event.type === 'drop') {
      if (onDropCallback) {
        onDropCallback(UploadEventType.DRAGANDDROP);
      }
    } else if (onDropCallback) {
      onDropCallback(UploadEventType.FILEUPLOAD);
    }

    if (onFailure && typeof onFailure === 'function') {
      return onFailure(files);
    }

    return null;
  };

  const {
    getRootProps, getInputProps, open, fileRejections,
  } = useDropzone({
    // Disable click and keydown behavior on dropzone
    noClick: true,
    noKeyboard: true,
    disabled,
    maxFiles,
    onDropAccepted,
    onDropRejected,
    onDrop,
    validator,
    minSize: minFileSize,
    maxSize: maxFileSize,
    accept: {
      'image/*': imageTypes,
      'application/*': documentTypes,
      'video/*': videoTypes,
    },
  });

  useEffect(() => {
    uploadedAmount.current = currentUploadedFiles;
  }, [currentUploadedFiles]);

  useEffect(() => {
    const isMultipleFiles = fileRejections.length > 1 ? 's' : '';

    fileRejections.forEach((rejectedFile: FileRejection) => {
      switch (rejectedFile.errors[0].code) {
        case ErrorCode.FileInvalidType:
          errorMsgs.push(
            `The specified file${isMultipleFiles} ${rejectedFile.file.name} could not be uploaded.
            Only files with the following extensions are allowed: ${acceptedFileExtensions}`,
          );
          break;
        case ErrorCode.FileTooLarge:
          errorMsgs.push(
            `The specified file${isMultipleFiles} ${rejectedFile.file.name} could not be uploaded. The file size is too
            large. Please reduce the file and try again.`,
          );
          break;
        case ErrorCode.FileTooSmall:
          errorMsgs.push(
            `The specified file${isMultipleFiles} ${rejectedFile.file.name} could not be uploaded. The file size is too
            small. Please reduce the file and try again.`,
          );
          break;
        case ErrorCode.TooManyFiles:
          errorMsgs.push(
            `The requested upload exceeds ${maxFiles} files. Please upload files in batches of ${maxFiles} or
            less. Multiple batches are allowed until the ${totalAllowedFiles} file maximum is reached.`,
          );
          break;
        case 'upload-limit-reached':
          errorMsgs.push(rejectedFile.errors[0].message);
          break;
        default:
          errorMsgs.push(rejectedFile.errors[0].message);
          break;
      }
    });

    const displayUniqueErrorMsgs = Array.from(new Set(errorMsgs)).map((msg, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <ErrorInfoText key={`${name}_${index}`}>
        <Grid container>
          <Grid item width="20px">
            <ErrorIconStyled />
          </Grid>
          <Grid item xs>
            {msg}
          </Grid>
        </Grid>
      </ErrorInfoText>
    ));

    setUniqueErrorMsgs(displayUniqueErrorMsgs);

    setHasError(displayUniqueErrorMsgs.length > 0 || !!errors[name]);
  }, [fileRejections, errors[name]]);

  if (errors[name]) {
    errorMsgs.push(errors[name].message);
  }

  return (
    <div>
      {label
        && (
        <IQLabelTooltip
          labelText={label}
          hasError={withLabelError && hasError}
          tooltipText={toolTiptext}
          theme={theme}
          paddingBottom={8}
          required={required}
        />
        )}
      {supportingText && (
      <SupportiveText
        hasError={withSupportingTextError && hasError}
      >
        {supportingText}
      </SupportiveText>
      )}
      <Container
        ref={field.ref}
        required={required}
        hasError={hasError}
        disabled={disabled}
        size={size}
        {...getRootProps({ className: 'dropzone' })}
      >
        <input {...getInputProps()} />
        <IQButton
          startIcon={(
            <CloudUploadIconStyled
              hasError={hasError}
              disabled={disabled}
              required={false}
            />
            )}
          onClick={open}
          disabled={disabled}
          hasError={hasError}
          label={buttonLabel}
          sx={{ textTransform: 'none' }}
        />
        {
          !isMobile && (
            <p>or drag and drop any {acceptedFileExtensions.replace('and', 'or')} file</p>
          )
        }
      </Container>
      { showAcceptedFileTypesText
            && (
            <AssistiveText>
              {`Allowed File Types: ${acceptedFileExtensions}`}
            </AssistiveText>
            )}
      { showMaxNumberFilesText && (
      <AssistiveText>
        {`Max Number of Files: ${maxFiles}`}
      </AssistiveText>
      )}
      { showRecommendedImageText
      && (
      <AssistiveText>
        {`Recommended Image Size: ${recommendedImgText}`}
      </AssistiveText>
      )}
      { showMaxFileSizeText
      && (
      <AssistiveText>
        {`Max File Size: ${maxFileSize / 1000000} MB`}
      </AssistiveText>
      )}
      {uniqueErrorMsgs}
    </div>
  );
}
